import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
import filpOutline from '@iconify/icons-eva/flip-outline';
import closeCircleFill from '@iconify/icons-eva/close-circle-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Button } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function OrderMoreMenu(props) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        { props.currentStatus == 'pending' && <><Button color="inherit" variant="outlined" sx={{ background:'#057c57', color:'#fff',float:'right' }}>  
          Accept
        </Button>
        <Button color="inherit" variant="outlined" sx={{ background:'#EE1C73', marginLeft:'20px', color:'#fff',float:'right' }}>  
          Reject
        </Button></>}
        
        { props.currentStatus == 'accepted' && <Button color="inherit" variant="outlined" sx={{ background:'#EE1C73', color:'#fff',float:'right' }}>  
        
        
        </Button>}
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        { props.currentStatus == 'pending' && <>
          <MenuItem sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={doneAllFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Accept" onClick={()=> { props.handleStatus(props.id,'accepted'); setIsOpen(false) }} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>

          <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={closeCircleFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Reject" onClick={()=> { props.handleStatus(props.id,'rejected'); setIsOpen(false) }} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        </>}
        { props.currentStatus == 'accepted' && <>
        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={filpOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary= 'Preparing' onClick={()=> { props.handleStatus(props.id,'preparing'); setIsOpen(false) }} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        </>}
      </Menu>
    </>
  );
}
