// material
import { Box, Stack, Grid, Container, Typography } from '@material-ui/core';
// components
import Page from '../components/Page';
import useSession from 'react-session-hook';
import { useState, useEffect } from 'react';
import {
  AppDrivers,
  AppSubadmins,
  AppUsers,
  AppRestaurantReports,
  AppOrders,
  AppBookings,
  AppCompanies,
  AppEarningBookings,
  AppCommisionBookings,
  AppOrdersEarnings,
  AppOrdersCommision,
  AppOnlineDrivers,
  AppReceivedOrders,
  AppAcceptedOrders,
  AppRejectedOrders,
  AppCancelledOrders
} from '../components/_dashboard/app';
import helper from '../constants/helper';
import apiPath from '../constants/apiPath';
import { FilterDate } from '../components/forms/index';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const session = useSession();
  const [driverCount, setDriverCount] = useState([]);
  const [bookingCount, setBookingCount] = useState([]);
  const [subadminCount, setSubadminCount] = useState([]);
  const [orderCount, setOrderCount] = useState([]);
  const [userCount, setUserCount] = useState([]);
  const [restaurantCount, setRestaurantCount] = useState([]);
  const [dateFilter, setDateFilters] = useState([null, null]);
  const [filterBody, setFilterBody] = useState({});
  const [counts, setCounts] = useState({});

  const setDateFilter = (value) => {
    setDateFilters(value);
    console.log('value', value);
    setFilterBody({ startDate: value[0], endDate: value[1] });
    console.log('filterBodysa', filterBody);
  };
  useEffect(() => {
    getCount(filterBody);
  }, [filterBody]);

  const getCount = async (body = filterBody) => {
    console.log('filterBody', filterBody);
    try {
      console.log(apiPath.dashboardCount);
      const res = await helper.post(body, apiPath.dashboardCount, session);
      if (res.status === 200) {
        setCounts(res.data.results);
        //setBookingCount(res.data.results.totalBooking)
        //setDriverCount(res.data.results.totalDriver)
        //setSubadminCount(res.data.results.totalSubadmin)
        //setOrderCount(res.data.results.totalOrder)
        //setUserCount(res.data.results.totalUser)
        //setRestaurantCount(res.data.results.totalRestaurant)
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Page title="Dashboard | Piver">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          {/* <Typography variant="h4">Hi, Welcome back</Typography>
          <Stack sx={{ pt: 5 }} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FilterDate style={{float:"right"}} setDateFilter={setDateFilter} dateFilter={dateFilter} />
          </Stack> */}
        </Box>

        <Grid container spacing={3} className="edit-form-allinput">
          <Grid item xs={12} sm={12} md={12}>
            <FilterDate
              style={{ float: 'right' }}
              setDateFilter={setDateFilter}
              dateFilter={dateFilter}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppUsers total={counts.totalUser} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppDrivers total={counts.totalDriver} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppCompanies total={counts.totalCompany} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppRestaurantReports total={counts.totalRestaurant} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppBookings total={counts.totalBooking} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppEarningBookings total={counts.totalEarnings} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppCommisionBookings total={counts.totalEarningsCommsion} />
          </Grid>

          {/* <Grid item xs={12} sm={6} md={3}></Grid> */}

          <Grid item xs={12} sm={6} md={3}>
            <AppOrders total={counts.totalOrder} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppOrdersEarnings total={counts.totalOrderEarnings} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppOrdersCommision total={counts.totalFoodOrderCommision} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppOnlineDrivers total={orderCount} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppReceivedOrders total={counts.totalFoodOrders} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppAcceptedOrders total={counts.totalFoodOrdersAccepted} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppRejectedOrders total={counts.totalFoodOrdersRejected} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppCancelledOrders total={counts.totalFoodOrdersCancelled} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
