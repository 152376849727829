import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Card, Stack, Link, Container, Typography } from '@material-ui/core';

// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { UpdatePasswordForm } from '../components/authentication/forgotpassword';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '70%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '0px',
  borderRadius: '0px'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function UpdatePassword() {
  return (
    <RootStyle title="Update Password | Piver Restaurant">
      <MHidden width="mdDown">
        <SectionStyle>
          {/*<Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>*/}
          <img src="/static/left-login1.svg" alt="login" style={{ height: '100%' }} />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm" style={{ width: '30%' }}>
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography sx={{ color: 'text.black' }} variant="h3">
              Enter your new password.
            </Typography>
          </Stack>

          <UpdatePasswordForm />
          <Stack sx={{ mb: 5 }}>
            {/* <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="/register">
                Get started
              </Link>
            </Typography>{' '} */}
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
