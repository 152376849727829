import * as React from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useSession from 'react-session-hook';
const querystring = require('querystring');
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Box,
  Checkbox,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, OrderMoreMenu } from '../components/_dashboard/user';

import { fDateTime } from '../utils/formatTime';
import helper from '../constants/helper';
import apiPath from '../constants/apiPath';
import CancelOrder from './CancelOrder';
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'orderId', label: 'Order ID', alignRight: false },
  { id: 'restaurant', label: 'Restaurant', alignRight: false },
  { id: 'user', label: 'User', alignRight: false },
  { id: 'items', label: 'Items', alignRight: false },
  { id: 'net_amount ', label: 'Net Amount', alignRight: false },
  { id: 'transaction_id ', label: 'Transaction ID', alignRight: false },
  { id: 'payment_mode ', label: 'Payment Mode', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created', label: 'Created', alignRight: false },
  { id: 'deliverd', label: 'Delivered', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false }
];

// ----------------------------------------------------------------------

export default function ViewOrder(props) {
  const session = useSession();
  const [orderData, setOrderData] = useState({});
  const [cancelOrderDialog, setCancelOrderDialog] = useState(false);
  const _id = props.orderId;
  // const getOrderData = async()=>{
  //   let body={};
  //   try{
  //       const res = await helper.get(apiPath.adminOrderData+ '/' + _id,body, session);
  //       if(res.status===200) {
  //         console.log("res.data.results",res.data.results)
  //         setOrderData(res.data.results)
  //       }
  //   } catch (e) {
  //       console.log(e);
  //   }
  // }

  //console.log('orderData', orderData);

  const Statusbutton = (data) => {
    let { status } = data;

    let colors = {
      preparing: '#ebdc61',
      pending: '#0efa08',
      accepted: '#057c57',
      rejected: '#ff0000',
      delivered: '#083dfa',
      cancelled: '#ff0000',
      picked: '#08c8fa'
    };

    let styless = {
      height: '35px',
      backgroundColor: colors[status],
      borderRadius: '6px',
      boxShadow: 'none',
      fontWeight: 850,
      fontSize: '16px',
      fontFamily: 'Avenir',
      padding: '17px'
    };

    return (
      <Button variant="contained" sx={styless}>
        {status}
      </Button>
    );
  };

  useEffect(() => {
    setOrderData(props.orderData);
  }, [props.orderData]);

  let isOrderCancelBtn = false;
  let statusArr = ['pending', 'waiting', 'accepted', 'preparing', 'prepared'];

  if (statusArr.includes(orderData.status)) {
    isOrderCancelBtn = true;
  }

  return (
    <>
      {orderData && (
        <Page title="Order | Piver">
          <Container maxWidth="xl">
            <Scrollbar>
              <Card sx={{ my: 2, mx: 2 }}>
                <List
                  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', px: 4, py: 2 }}
                >
                  <ListItem key="Status" disableGutters>
                    <ListItemText
                      className={'MuiListItemText-multiline'}
                      alignItems="flex-start"
                      sx={{ display: 'flex' }}
                      disableTypography={true}
                      primary={
                        <Typography
                          className={'MuiTypography-subtitle2'}
                          sx={{ minWidth: '150px' }}
                          variant="subtitle2"
                          color="text.primary"
                        >
                          Current Status
                        </Typography>
                      }
                      secondary={
                        <Box>
                          <Statusbutton status={orderData.status} />
                        </Box>
                      }
                    />
                  </ListItem>

                  {isOrderCancelBtn && (
                    <Box>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setCancelOrderDialog(true)}
                      >
                        Cancel Order
                      </Button>
                    </Box>
                  )}

                  {orderData.status === 'cancelled' && (
                    <ListItem key="Status" disableGutters>
                      <ListItemText
                        className={'MuiListItemText-multiline'}
                        alignItems="flex-start"
                        sx={{ display: 'flex' }}
                        disableTypography={true}
                        primary={
                          <Typography
                            className={'MuiTypography-subtitle2'}
                            sx={{ minWidth: '150px' }}
                            variant="subtitle2"
                            color="text.primary"
                          >
                            Cancellation Reason
                          </Typography>
                        }
                        secondary={<Box>{orderData.cancellationReason}</Box>}
                      />
                    </ListItem>
                  )}
                </List>
              </Card>

              <Card sx={{ my: 2, mx: 2 }}>
                <List
                  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', px: 4, py: 2 }}
                >
                  <ListItem key="ID" disableGutters>
                    <ListItemText
                      className={'MuiListItemText-multiline'}
                      alignItems="flex-start"
                      sx={{ display: 'flex' }}
                      disableTypography={true}
                      primary={
                        <Typography
                          className={'MuiTypography-subtitle2'}
                          sx={{ minWidth: '150px' }}
                          variant="subtitle2"
                          color="text.primary"
                        >
                          Request ID
                        </Typography>
                      }
                      secondary={
                        <Box>
                          <Typography variant="body2" color="text.primary">
                            {orderData.orderId ? orderData.orderId : ''}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>

                  <ListItem key="Price" disableGutters>
                    <ListItemText
                      className={'MuiListItemText-multiline'}
                      alignItems="flex-start"
                      sx={{ display: 'flex' }}
                      disableTypography={true}
                      primary={
                        <Typography
                          className={'MuiTypography-subtitle2'}
                          sx={{ minWidth: '150px' }}
                          variant="subtitle2"
                          color="text.primary"
                        >
                          Order Amount
                        </Typography>
                      }
                      secondary={
                        <Box>
                          <Typography sx={{}} variant="body2" color="text.primary">
                            {orderData.subTotalAmount ? `$ ${orderData.subTotalAmount}` : ''}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <ListItem key="Payment Mode" disableGutters>
                    <ListItemText
                      className={'MuiListItemText-multiline'}
                      alignItems="flex-start"
                      sx={{ display: 'flex' }}
                      disableTypography={true}
                      primary={
                        <Typography
                          className={'MuiTypography-subtitle2'}
                          sx={{ minWidth: '150px' }}
                          variant="subtitle2"
                          color="text.primary"
                        >
                          Payment Mode
                        </Typography>
                      }
                      secondary={
                        <Box>
                          <Typography
                            sx={{ textTransform: 'capitalize' }}
                            variant="body2"
                            color="text.primary"
                          >
                            {orderData.paymentMode ? orderData.paymentMode : ''}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>

                  <ListItem key="Delivery/PickUp" disableGutters>
                    <ListItemText
                      className={'MuiListItemText-multiline'}
                      alignItems="flex-start"
                      sx={{ display: 'flex' }}
                      disableTypography={true}
                      primary={
                        <Typography
                          className={'MuiTypography-subtitle2'}
                          sx={{ minWidth: '150px' }}
                          variant="subtitle2"
                          color="text.primary"
                        >
                          Delivery/PickUp
                        </Typography>
                      }
                      secondary={
                        <Box>
                          <Typography
                            sx={{ textTransform: 'capitalize' }}
                            variant="body2"
                            color="text.primary"
                          >
                            {orderData.deliveryType ? orderData.deliveryType : ''}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>

                  {orderData.deliveryType && orderData.deliveryType === 'delivery' && (
                    <ListItem key="Delivery fee" disableGutters>
                      <ListItemText
                        className={'MuiListItemText-multiline'}
                        alignItems="flex-start"
                        sx={{ display: 'flex' }}
                        disableTypography={true}
                        primary={
                          <Typography
                            className={'MuiTypography-subtitle2'}
                            sx={{ minWidth: '150px' }}
                            variant="subtitle2"
                            color="text.primary"
                          >
                            Delivery fee
                          </Typography>
                        }
                        secondary={
                          <Box>
                            <Typography sx={{}} variant="body2" color="text.primary">
                              {orderData.deliveryFee ? orderData.deliveryFee : ''}
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  )}

                  {orderData.deliveryType && orderData.deliveryType === 'delivery' && (
                    <ListItem key="Delivery at" disableGutters>
                      <ListItemText
                        className={'MuiListItemText-multiline'}
                        alignItems="flex-start"
                        sx={{ display: 'flex' }}
                        disableTypography={true}
                        primary={
                          <Typography
                            className={'MuiTypography-subtitle2'}
                            sx={{ minWidth: '150px' }}
                            variant="subtitle2"
                            color="text.primary"
                          >
                            Delivery at
                          </Typography>
                        }
                        secondary={
                          <Box>
                            <Typography sx={{}} variant="body2" color="text.primary">
                              {orderData.deliveryAddress ? orderData.deliveryAddress : ''}
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  )}

                  <ListItem key="Transaction ID" disableGutters>
                    <ListItemText
                      className={'MuiListItemText-multiline'}
                      alignItems="flex-start"
                      sx={{ display: 'flex' }}
                      disableTypography={true}
                      primary={
                        <Typography
                          className={'MuiTypography-subtitle2'}
                          sx={{ minWidth: '150px' }}
                          variant="subtitle2"
                          color="text.primary"
                        >
                          Transaction ID
                        </Typography>
                      }
                      secondary={
                        <Box>
                          <Typography sx={{}} variant="body2" color="text.primary">
                            {orderData.transactionId ? orderData.transactionId : '-'}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>

                  <ListItem key="Notes" disableGutters>
                    <ListItemText
                      className={'MuiListItemText-multiline'}
                      alignItems="flex-start"
                      sx={{ display: 'flex' }}
                      disableTypography={true}
                      primary={
                        <Typography
                          className={'MuiTypography-subtitle2'}
                          sx={{ minWidth: '150px' }}
                          variant="subtitle2"
                          color="text.primary"
                        >
                          Notes
                        </Typography>
                      }
                      secondary={
                        <Box>
                          <Typography sx={{}} variant="body2" color="text.primary">
                            {orderData.notes ? orderData.notes : ''}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                </List>
              </Card>
              {/* <Divider/> */}
              <Card sx={{ my: 2, mx: 2 }}>
                <Table inputProps={{}}>
                  <TableHead sx={{ background: '#f1f1f1' }}>
                    <TableCell align="left">
                      <Typography variant="body1" noWrap>
                        Name
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1" noWrap>
                        Quantity
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="left">
                      <Typography variant="body1" noWrap>
                        Price
                      </Typography>
                    </TableCell>

                    <TableCell align="left">
                      <Typography variant="body1" noWrap>
                        Total
                      </Typography>
                    </TableCell>
                  </TableHead>
                  <TableBody sx={{ padding: '10px' }}>
                    {orderData.items
                      ? orderData.items.map((eachItem) => {
                          const { id, name, image, quantity, price, itemTotalPrice } = eachItem;
                          return (
                            <TableRow sx={{ border: 1 }}>
                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  sx={{ margin: '2px' }}
                                  spacing={2}
                                >
                                  {/* <Avatar style={{border: 1,width: "100px",height: "70px", objectFit: 'cover', borderRadius:"0"}}  alt={name} src={image} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  sx={{ margin: '2px' }}
                                  spacing={2}
                                >
                                  <Typography variant="subtitle2" noWrap>
                                    {quantity}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>X</TableCell>
                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  sx={{ margin: '2px' }}
                                  spacing={2}
                                >
                                  <Typography variant="subtitle2" noWrap>
                                    {price}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  sx={{ margin: '2px' }}
                                  spacing={2}
                                >
                                  <Typography variant="subtitle2" noWrap>
                                    {itemTotalPrice}
                                  </Typography>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : ''}
                  </TableBody>
                </Table>
              </Card>

              {/*  Customer Card */}

              <Card sx={{ my: 2, mx: 2 }}>
                <List
                  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', px: 4, py: 2 }}
                >
                  <ListItem key="Customer" disableGutters>
                    <ListItemText
                      className={'MuiListItemText-multiline'}
                      alignItems="flex-start"
                      sx={{ display: 'flex' }}
                      disableTypography={true}
                      primary={
                        <Typography
                          className={'MuiTypography-subtitle2'}
                          sx={{ minWidth: '150px' }}
                          variant="subtitle2"
                          color="text.primary"
                        >
                          Customer Name
                        </Typography>
                      }
                      secondary={
                        <Box>
                          <Typography sx={{}} variant="body2" color="text.primary">
                            {orderData.user ? orderData.user.name : ''}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>

                  <ListItem key="Number" disableGutters>
                    <ListItemText
                      className={'MuiListItemText-multiline'}
                      alignItems="flex-start"
                      sx={{ display: 'flex' }}
                      disableTypography={true}
                      primary={
                        <Typography
                          className={'MuiTypography-subtitle2'}
                          sx={{ minWidth: '150px' }}
                          variant="subtitle2"
                          color="text.primary"
                        >
                          Phone number
                        </Typography>
                      }
                      secondary={
                        <Box>
                          <Typography sx={{}} variant="body2" color="text.primary">
                            {orderData.user ? orderData.user.mobile : ''}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                </List>
              </Card>
              {/* <Divider/> */}

              {orderData.deliveryType && orderData.deliveryType === 'delivery' && (
                <Card sx={{ my: 2, mx: 2 }}>
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                      bgcolor: 'background.paper',
                      px: 4,
                      py: 2
                    }}
                  >
                    <ListItem key="Driver" disableGutters>
                      <ListItemText
                        className={'MuiListItemText-multiline'}
                        alignItems="flex-start"
                        sx={{ display: 'flex' }}
                        disableTypography={true}
                        primary={
                          <Typography
                            className={'MuiTypography-subtitle2'}
                            sx={{ minWidth: '150px' }}
                            variant="subtitle2"
                            color="text.primary"
                          >
                            Driver Name
                          </Typography>
                        }
                        secondary={
                          <Box>
                            <Typography sx={{}} variant="body2" color="text.primary">
                              {orderData.driver ? orderData.driver.name : ''}
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>

                    <ListItem key="Number" disableGutters>
                      <ListItemText
                        className={'MuiListItemText-multiline'}
                        alignItems="flex-start"
                        sx={{ display: 'flex' }}
                        disableTypography={true}
                        primary={
                          <Typography
                            className={'MuiTypography-subtitle2'}
                            sx={{ minWidth: '150px' }}
                            variant="subtitle2"
                            color="text.primary"
                          >
                            Driver Phone number
                          </Typography>
                        }
                        secondary={
                          <Box>
                            <Typography sx={{}} variant="body2" color="text.primary">
                              {orderData.driver ? orderData.driver.mobile : ''}
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  </List>
                </Card>
              )}

              <Card sx={{ my: 2, mx: 2 }}>
                <List
                  sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', px: 4, py: 2 }}
                >
                  <ListItem key="Promotion Code" disableGutters>
                    <ListItemText primary={`Promotion Code`} />
                    <ListItemText
                      primary={orderData.promoCode ? orderData.promoCode.name : 'Not Applied'}
                    />
                  </ListItem>

                  {orderData.promoCode && (
                    <ListItem key="Discount" disableGutters>
                      <ListItemText primary={`Discount`} />
                      <ListItemText primary={orderData.discount ? orderData.discount : ''} />
                    </ListItem>
                  )}
                </List>
              </Card>

              <Card sx={{ my: 2, mx: 2 }}>
                <Timeline position="left">
                  <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                      {fDateTime(props.orderData.created_at)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Order Received</TimelineContent>
                  </TimelineItem>

                  {props.orderData.restaurantActions &&
                    props.orderData.restaurantActions.map((item) => {
                      return (
                        <TimelineItem>
                          <TimelineOppositeContent color="text.secondary">
                            {fDateTime(item.created_at)}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>{item.status}</TimelineContent>
                        </TimelineItem>
                      );
                    })}

                  {/* <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                      {fDateTime(orderData.created_at)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Accepted</TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                      {fDateTime(orderData.created_at)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>Prepared</TimelineContent>
                  </TimelineItem>


                 

                  <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                    {fDateTime(orderData.created_at)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                     
                    </TimelineSeparator>
                    <TimelineContent>Delivered</TimelineContent>
                  </TimelineItem> */}
                </Timeline>
              </Card>

              <Card sx={{ my: 2, mx: 2 }}>
                {props.orderData.reviews &&
                  props.orderData.reviews.map((item) => {
                    return (
                      <List
                        sx={{
                          width: '100%',
                          maxWidth: 360,
                          bgcolor: 'background.paper',
                          px: 4,
                          py: 2
                        }}
                      >
                        <ListItem key="Rating" disableGutters>
                          <ListItemText primary={`Rating`} />
                          <ListItemText primary={item.rating ? item.rating : 'Not Available'} />
                        </ListItem>

                        <ListItem key="Review" disableGutters>
                          <ListItemText primary={`Review`} />
                          <ListItemText primary={item.review ? item.review : 'Not Available'} />
                        </ListItem>
                      </List>
                    );
                  })}
              </Card>
            </Scrollbar>
          </Container>
        </Page>
      )}
      <CancelOrder
        openCancelOrderDialog={cancelOrderDialog}
        orderId={orderData._id}
        restaurantId={orderData.restaurant}
        orderCancelSuccess={(status) => props.cancelledOrder(status)}
        closeCancelOrderDialog={(dialogStatus) => {
          setCancelOrderDialog(dialogStatus);
        }}
      />
    </>
  );
}
