import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ClockPick from './ClockPick';

import { Stack,Typography } from '@material-ui/core';

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(2),
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      border: '1px solid',
      borderColor: '#692B7C',
      borderRadius: '50%'
    },
    '&:first-child': {
      border: '1px solid',
      borderColor: '#692B7C',
      borderRadius: '50%'
    }
  }
}))(ToggleButtonGroup);

const StyledToggle = withStyles({
  root: {
    color: '#692B7C',
    '&$selected': {
      color: 'white',
      background: '#692B7C'
    },
    '&:hover': {
      borderColor: '#BA9BC3',
      background: '#BA9BC3'
    },
    '&:hover$selected': {
      borderColor: '#BA9BC3',
      background: '#BA9BC3'
    },
    minWidth: 32,
    maxWidth: 32,
    height: 32,
    textTransform: 'unset',
    fontSize: '0.75rem',
    borderRadius: '50%'
  },
  selected: {}
})(ToggleButton);

export default function WorkingHours(props) {
  const [selectedDay, setDay] = useState('sunday');
  const [days, setDayData] = useState('sunday');

  console.log("WorkingHoursComp")

  return (
    <>
      <ToggleButtonGroup
        value={selectedDay}
        exclusive
        sx={{ mb: 2, display: 'block' }}
        arial-label="Days of the week"
      >
        {props.DAYS.map((day) => (
          <ToggleButton key={day.day} value={day.day} onClick={() => setDay(day.day)}>
            {day.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {props.DAYS.filter((dayItem) => dayItem.day === selectedDay).map((day) => (
        <Stack key={day.day} direction={{ xs: 'column', sm: 'row' }} sx={{width:'100%'}} spacing={3}>

          <Typography sx={{ 'marginTop': '10px' }} id="transition-modal-title" variant="h6" component="h2">
            {selectedDay.toUpperCase()}
          </Typography>

          <ClockPick
            name="open"
            value={day.openingTime}
            day={selectedDay}
            hours={'openingTime'}
            handleDateChange={props.handleDateChange}
            label="Open Time"
          />
          <ClockPick
            name="close"
            value={day.closingTime}
            day={selectedDay}
            hours={'closingTime'}
            handleDateChange={props.handleDateChange}
            label="Close Time"
          />
        </Stack>
      ))}

      {/*<StyledToggleButtonGroup
        size="medium"
        arial-label="Days of the week"
        value={days}
        onChange={(event, value) => setDays(value)}
      >
        {DAYS.map((day, index) => (
          <StyledToggle key={day.key} value={index} aria-label={day.key}>
            {day.label}
          </StyledToggle>
        ))}
        </StyledToggleButtonGroup>*/}
    </>
  );
}
