import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { ConstructionRounded } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import Map from '../map/Map';
// ----------------------------------------------------------------------

export default function AddForm() {
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [address, setAddress] = useState('');
  const [modal, setModal] = useState(false);
  //const [selectedPermission, setSelectedPermission] = useState([]);
  //const [permissions, setPermissions] = useState([{ "_id": "customer", "name": "Customer Management" }, { "_id": "order", "name": "Order Management" }, { "_id": "restaurant", "name": "Restaurant Management" }, { "_id": "category", "name": "Category" }, { "_id": "company", "name": "Company Management" }, { "_id": "cuisine", "name": "Cuisines" }, { "_id": "menu", "name": "menu Items" }, { "_id": "orders", "name": "Orders" }, { "_id": "booking", "name": "Bookings" }, { "_id": "driver", "name": "Driver Management" }, { "_id": "promocode", "name": "Promocodes" }, { "_id": "reviews", "name": "Reviews" }, { "_id": "cms", "name": "CMS Pages" }, { "_id": "contacts", "name": "Contacts" }]);
  
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [groups, setGroups]= useState([]);
  
  
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [activeInactive, setActiveInactive] = useState(false);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setSelectedPermission(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setFieldValue('permission', typeof value === 'string' ? value.split(',') : value)
  };

  const SubadminSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Please enter at least 2 characters!')
      .max(50, 'Should not be more than 50 characters!')
      .required('Please enter subadmin name')
      .matches(
        /^[a-zA-Z\s]+$/,
        "Must be alphabets"
      ),
    phone: Yup.number().min(1000000000, 'Please enter correct number!').max(9999999999, 'Please enter correct number!').required('Please enter phone number'),
    email: Yup.string().email('Email must be a valid email address').required('Please enter email address'),
    address: Yup.string().min(6, 'Please enter at least 3 characters!').max(250, 'Should not be more than 250 characters!').required('Please enter address'),
    username: Yup.string().min(3, 'Please enter at least 3 characters!').max(20, 'Should not be more than 20 characters!').required('Please enter username'),
    password: Yup.string().min(6, 'Please enter at least 6 characters!').max(20, 'Should not be more than 20 characters!').required('Please enter password'),
    group: Yup.string().required('Please select roles and permissions'),
  });
 
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      isActive: '',
      address: '',
      zip: '',
      username: '',
      password: '',
      group:''
    },
    validationSchema: SubadminSchema,
    onSubmit: async (values, { setSubmitting }) => {
      //values['isActive'] = activeInactive
      values['image'] = image
      //values['permission'] = selectedPermission
      //values['address'] = address
      values['lat'] = lat
      values['lng'] = lng
      handleToggle()
      try {
        const res = await helper.post(values, apiPath.addSubadmin, session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg, { variant });

          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg, { variant });
            navigate('/dashboard/subadmins', { replace: true });

            console.log("res.data.results", res.data.results)
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });

  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }

  const getGroups = async () => {
    try {
      const res = await helper.post({}, apiPath.adminGroupList, session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/subadmins" />)
        }
        else {

          setGroups(res.data.results)

          console.log("res.data.results", res.data.results)
        }

        console.log("working")
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }

  useEffect(() => {
    getGroups()
  }, []);


  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {(errorResponse) && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{ width: '100%', my: 2, py: 2 }}>

              <FileUploader handleFile={handleFile} folder="admin" />
            </FormControl>
          </Stack>
        </Stack>

        <Stack spacing={3} className="edit-form-allinput">
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              label="User Name"
              {...getFieldProps('username')}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />

          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              label="Phone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />

          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <Stack sx={{ width: '100%' }} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                sx={{ width: '100%' }}
                label="Address"
                onClick={() => { setModal(true) }}
                value={address}
                {...getFieldProps('address')}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />

              <input
                type="hidden"

                name="lat"
                value={lat}
              />
              <input
                type="hidden"

                name="lng"
                value={lng}
              />

              <Map
                lat={lat}
                setLat={setLat}
                lng={lng}
                setLng={setLng}
                address={address}
                setFieldValue={setFieldValue}
                setAddress={setAddress}
                modal={modal}
                setModal={setModal}
              />
            </Stack>
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

            <Stack sx={{ width: '50%' }} direction={{ xs: 'column', sm: 'row' }} spacing={2}>

              <Autocomplete
                id="combo-box-demo"
                className="profile-input-form"
                options={groups}
                {...getFieldProps('group')}
                getOptionLabel={option => option.name}
                onChange={(event, newValue) => {
                  console.log("newValue", newValue)
                  //handleChange(newValue)

                  if(newValue)
                  {
                    setSelectedGroup(newValue._id);
                    setFieldValue('group',newValue._id)
                  }
                  else
                  {
                    setSelectedGroup("");
                    setFieldValue('group',"")
                  }

                }}
                value={groups.find(
                  (group) => (selectedGroup === group._id)
                )}
                //value={selectedGroup}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...getFieldProps('group')}
                  error={Boolean(touched.group && errors.group)}
                  helperText={touched.group && errors.group} {...params} label="Roles and Permissions" />}
              /> </Stack>



{/* <Autocomplete
                  id="combo-box-demo"
                  className="profile-input-form" 
                  sx={{width:'70%'}}
                  options={possibleTypes}
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    console.log("newValue",newValue)
                    if(newValue)
                    {
                      setSelectedType(newValue);
                      setFieldValue('type',newValue._id)
                    }
                    else
                    {
                      setSelectedType({});
                      setFieldValue('type',"")
                    }
                  }}
                  value={possibleTypes.find(
                    (typeItem) => typeItem._id === selectedType._id
                  )}
                  renderInput={(params) => <TextField {...getFieldProps('type')}
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type} {...params} label="Type" />}
                /> */}

          </Stack>


          <Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
            <LoadingButton

              size="large"
              type="submit"
              variant="contained"
              sx={{
                height: '50px',
                width: '131px',
                borderRadius: '20px',
                boxShadow: 'none',
                fontWeight: 850,
                fontSize: '16px',
                fontFamily: 'Avenir',
                padding: '17px',
                marginRight: '9px'
              }}
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>

            <LoadingButton

              size="large"
              type="button"
              variant="contained"
              sx={{
                height: '50px',
                borderRadius: '20px',
                boxShadow: 'none',
                fontWeight: 850,
                fontSize: '16px',
                fontFamily: 'Avenir',
                padding: '17px',
                background: '#E4E4E4',
                color: '#000',
                width: '131px'
              }}
              loading={isSubmitting}
              href="/dashboard/subadmins"
            >
              Cancel
            </LoadingButton></Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
