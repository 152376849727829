// import { useState } from 'react';
// import helper from "../../constants/helper";

// const [page, setPage] = useState(0);
// const [rowsPerPage, setRowsPerPage] = useState(10);
// const [filteredRecords, setRecords] = useState([]);
// const [totalRecord, setTotalRecord] = useState(10);

// const getData = async (apiPath, page = 0, query = { 'orderBy': '_id', "order": 'desc' }, body = filterBody) => {
//     setPage(page)
//     console.log(page)
//     query['page'] = page
//     query['limit'] = rowsPerPage
//     try {
//       const res = await helper.post(body, apiPath + '?' + querystring.stringify(query), session);
//       if (res.status === 200) {
//         setRecords(res.data.results.docs)
//         setTotalRecord(res.data.results.totalDocs)
//       }
//     } catch (e) {
//       console.log(e);
//     }
//   }

//   export {
//     helper,
//     getData,
//     page,
//     setPage,
//     rowsPerPage,
//     setRowsPerPage,
//     filteredRecords,
//     setRecords,
//     totalRecord,
//     setTotalRecord
//   }

let activeStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#0C0CFA',
    color: '#fff'
  };

  let deactiveStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#EE1C73',
    color: '#fff'
  };

export {
    activeStyle1,
    deactiveStyle1
}

