import * as React from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState,useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useSession from 'react-session-hook';
const querystring = require('querystring');

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Box,
  Checkbox,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, OrderMoreMenu } from '../components/_dashboard/user';

import { fDateTime } from '../utils/formatTime';
import helper from "../constants/helper";
import apiPath from "../constants/apiPath";
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'bookingId', label: 'Order ID', alignRight: false },
  { id: 'restaurant', label: 'Restaurant', alignRight: false },
  { id: 'user', label: 'User', alignRight: false },
  { id: 'items', label: 'Items', alignRight: false },
  { id: 'net_amount ', label: 'Net Amount', alignRight: false },
  { id: 'transaction_id ', label: 'Transaction ID', alignRight: false },
  { id: 'payment_mode ', label: 'Payment Mode', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created_at', label: 'Created', alignRight: false },
  { id: 'deliverd', label: 'Delivered', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false  }
];

// ----------------------------------------------------------------------

export default function ViewOrder(props) {
  const session = useSession();
  const [bookingData, setBookingData] = useState({});
  const _id = props.bookingId;

  useEffect(() => {
    setBookingData(props.bookingData)
  },[props.bookingData]);

  return (
    <Page title="Order | Piver">
      <Container maxWidth="xl">

        <Scrollbar>
          <Card sx={{ my:2 , mx:2}}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', px:4, py:2 }}>

                <ListItem
                  key="Booking ID"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Booking ID
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {bookingData.bookingId ? bookingData.bookingId : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                
                <ListItem
                  key="Receiver Name"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Receiver Name
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        variant="body2"
                        color="text.primary"
                      >
                        {bookingData.receiverName}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Receiver Contact"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Receiver Contact
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                      {bookingData.receiverContact}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Customer"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Customer name
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {bookingData.user ? bookingData.user.name : ''}
                      
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>

                <ListItem
                  key="Customer mobile"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Customer mobile
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        variant="body2"
                        color="text.primary"
                      >
                       
                        {bookingData.user ? bookingData.user.mobile : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>

                <ListItem
                  key="Driver"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Driver
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {bookingData.driver ? `${bookingData.driver.name} (${bookingData.driver.mobile})` : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                {(bookingData.company) && <ListItem
                  key="Company"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Company
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {bookingData.company ? `${bookingData.company.name} (${bookingData.company.contactNo})` : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>}
                <ListItem
                  key="Date"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                    sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Date
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {bookingData.created_at ?  fDateTime(bookingData.created_at)  : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="deliveryCharges"
                  disableGutters
                >
                <ListItemText
                className={"MuiListItemText-multiline"}
                alignItems="flex-start"
                sx={{ display:'flex' }}
                disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Delivery Charges
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {bookingData.deliveryCharges ? `GHS ${bookingData.deliveryCharges}` : ''}
                      </Typography>
                      </Box>
                  }
                />
                </ListItem>

                <ListItem
                  key="adminCommision"
                  disableGutters
                >
                <ListItemText
                className={"MuiListItemText-multiline"}
                alignItems="flex-start"
                sx={{ display:'flex' }}
                disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        variant="subtitle2"
                        color="text.primary"
                      >
                       Admin Commision
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        variant="body2"
                        color="text.primary"
                      >
                        {bookingData.adminCommision ? `GHS ${bookingData.adminCommision}` : ''}
                      </Typography>
                      </Box>
                  }
                />
                </ListItem>

                <ListItem
                  key="providerFee"
                  disableGutters
                >
                <ListItemText
                className={"MuiListItemText-multiline"}
                alignItems="flex-start"
                sx={{ display:'flex' }}
                disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Provider Fee
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {bookingData.providerFee ? `GHS ${bookingData.providerFee}` : ''}
                      </Typography>
                      </Box>
                  }
                />
                </ListItem>

                <ListItem
                  key="Payment Mode"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Payment Mode
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {bookingData.paymentMode ? bookingData.paymentMode : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Transaction ID"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Transaction ID
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {bookingData.transactionId ? bookingData.transactionId : '-'}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Notes"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Notes
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {bookingData.notes ? bookingData.notes : '-'}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Status"
                  disableGutters
                >
                  <ListItemText
                    className={"MuiListItemText-multiline"}
                    alignItems="flex-start"
                    sx={{ display:'flex' }}
                    disableTypography={true}
                    primary={
                        <Typography
                        className={"MuiTypography-subtitle2"}
                          sx={{ minWidth:'150px' }}
                          
                          variant="subtitle2"
                          color="text.primary"
                        >
                          Delivery Status
                        </Typography>
                    }
                    secondary={
                        <Box> 
                        <Typography
                          sx={{  }}
                          
                          variant="body2"
                          color="text.primary"
                        >
                          {bookingData.deliveryStatus ? bookingData.deliveryStatus : ''}
                        </Typography>
                        </Box>
                        
                    }
                  />
                </ListItem>
                </List>
                
                </Card>
                {/* <Divider/> */}
            

                     {/* <Divider/> */}
                <Card sx={{ my:2, mx:2 }}>
                  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', px:4, py:2 }}>
  
                    <ListItem
                    key="Status"
                    disableGutters
                  >
                  <ListItemText
                    className={"MuiListItemText-multiline"}
                    alignItems="flex-start"
                    sx={{ display:'flex' }}
                    disableTypography={true}
                    primary={
                        <Typography
                        className={"MuiTypography-subtitle2"}
                          sx={{ minWidth:'150px' }}
                          
                          variant="subtitle2"
                          color="text.primary"
                        >
                          Delivery Items
                        </Typography>
                    }
                    secondary={
                        <Box> 
                        <Typography
                          sx={{  }}
                          
                          variant="body2"
                          color="text.primary"
                        >
                          {bookingData.items ? bookingData.items : ''}
                        </Typography>
                        </Box>
                        
                    }
                  />
                </ListItem>
                </List>     
                </Card>

          </Scrollbar>
      </Container>
    </Page>
  );
}
