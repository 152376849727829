import * as Yup from 'yup';
import { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Editor } from '@tinymce/tinymce-react';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function EditForm() {

  const _id = useParams().id;
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [faqData, setFaqData] = useState({});
  const [contentValue, setContentValue] = useState('');
  const [selectedType, setSelectedType] = useState({ name: 'percent' });

  const editorRef = useRef(null);

  const getfaqData = async () => {
    //handleToggle()
    let body = {};
    try {
      const res = await helper.get(apiPath.adminFaqData + '/' + _id, body, session);
      if (res.status === 200) {
        setFaqData(res.data.results)
        if (res.data.results.answer)
          setContentValue(res.data.results.answer)
        // setActiveInactive(res.data.results.isActive)
        //handleToggle()
      }

    } catch (e) {
      console.log(e);
    }

  }


  //const [selectedDate, handleDateChange] = useState(new Date('2018-01-01T00:00:00.000Z')); 

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const RegisterSchema = Yup.object().shape({
    question: Yup.string()
      .min(20, 'Please enter at least 20 characters!')
      .max(100, 'Should not be more than 100 characters!')
      .required('Please enter question'),
    answer: Yup.string().min(30, 'Please enter at least 30 characters!').max(250, 'Should not be more than 250 characters!').required('Please enter answer'),
  });

  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }

  useEffect(() => {
    getfaqData()
  }, []);


  const formik = useFormik({

    initialValues: {
      question: faqData.question ?? '',
      answer: faqData.answer ?? '',
    },
    enableReinitialize: true,

    validationSchema: RegisterSchema,
    onSubmit: async (values, { setSubmitting }) => {

      handleToggle()
      try {
        const res = await helper.put(values, apiPath.updateFaq + '/' + _id, session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg, { variant });

          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg, { variant });
            navigate('/dashboard/faqs', { replace: true });

            console.log("res.data.results", res.data.results)
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });


  const h1tag = {
    color: "red"
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {(faqData) &&
        <FormikProvider value={formik}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {(errorResponse) && <ErrorResponse msg={errorResponse} />}

          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

            <Stack spacing={3} className="edit-form-allinput">
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                <Stack sx={{ width: '50%' }} spacing={2}>

                  <TextField
                    fullWidth
                    label="Question"
                    {...getFieldProps('question')}
                    error={Boolean(touched.question && errors.question)}
                    helperText={touched.question && errors.question}
                  />
                </Stack>
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                <Stack sx={{ width: '50%' }} spacing={2}>

                  <TextField
                    fullWidth
                    label="Answer"
                    multiline
                    rows={4}
                    maxRows={10}
                    {...getFieldProps('answer')}
                    error={Boolean(touched.answer && errors.answer)}
                    helperText={touched.answer && errors.answer}
                  />
                </Stack>
              </Stack>

              <Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
                <LoadingButton
                  sx={{ width: '200px' }}
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    height: '50px',
                    width: '131px',
                    borderRadius: '20px',
                    boxShadow: 'none',
                    fontWeight: 850,
                    fontSize: '16px',
                    fontFamily: 'Avenir',
                    padding: '17px',
                    marginRight: '9px'
                  }}
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>

                <LoadingButton
                  sx={{ width: '200px' }}
                  size="large"
                  type="button"
                  variant="contained"
                  sx={{
                    height: '50px',
                    borderRadius: '20px',
                    boxShadow: 'none',
                    fontWeight: 850,
                    fontSize: '16px',
                    fontFamily: 'Avenir',
                    padding: '17px',
                    background: '#E4E4E4',
                    color: '#000',
                    width: '131px'
                  }}
                  loading={isSubmitting}
                  href="/dashboard/faqs"
                >
                  Cancel
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      }
    </>
  );
}
