import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import Switch from '@mui/material/Switch';
import DateTimePicker from '@material-ui/lab/DateTimePicker';

// material
import { Autocomplete, Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { useSnackbar } from 'notistack';
import { Map} from '../map';
// import Geofence from '../geofence/Geofence';

// ----------------------------------------------------------------------

export default function AddForm() {
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [lat, setLat] = useState(26.56565655);
  const [lng, setLng] = useState(75.4343443);
  const [address, setAddress] = useState('');
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [cuisines, setCuisines] = useState([
  ]);
  const [selectedType, setSelectedType] = useState({_id: 'Restaurant'})
  const [possibleTypes, setPossibleTypes] = useState([
    { _id: 'Restaurant',name: 'Restaurant' },
    { _id: 'Bakery',name: 'Bakery' },
    { _id: 'Joint',name: 'Joint' }
  ]);



  const [days, setDays] = useState([
    {
      day: "sunday",
      label: "Sun",
      openingTime: new Date(),
      closingTime: new Date()
    },
    {
      day: "monday",
      label: "Mon",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "tuesday",
      label: "Tue",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "wednesday",
      label: "Wed",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "thursday",
      label: "Thu",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "friday",
      label: "Fri",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "saturday",
      label: "Sat",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    }
  ])

  //const [selectedDate, handleDateChange] = useState(new Date('2018-01-01T00:00:00.000Z')); 

  const [selectedCuisines, setSelectedCuisines] = useState([]);

  const handleDateChange = (time, day, hours) => {

    console.log("time", time, day)


    console.log("eeeee", days.map((item) => {
      if (day === item['day'])
        item[hours] = time;
      return item;
    }))


    setDays(days.map((item) => {
      if (day === item['day'])
        item[hours] = time;
      return item
    }))

  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCuisines(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    setFieldValue('cuisines',typeof value === 'string' ? value.split(',') : value)
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Please enter at least 2 characters!')
      .max(50, 'Should not be more than 50 characters!')
      .required('Please enter restaurant name'),
    cuisines :Yup.array().min(1, 'Please select cuisine').required('Please select cuisine'),
    phone: Yup.number().min(1000000000, 'Too short!').max(9999999999, 'Too long!').required('Please enter contact number'),
    contactName: Yup.string().required('Please enter contact person name'),
    email: Yup.string().email('Email must be a valid email address').required('Please enter email address'),
    address: Yup.string().min(3, 'Please enter at least 3 characters!').max(250, 'Should not be more than 250 characters!').required('Please enter address'),
    type: Yup.string().required('Please select restaurant type'),
    zip: Yup.string()
    .min(5, 'Please enter at least 5 digit!')
    .max(6, 'Should not be more than 6 digit!')
    .required('Please enter district code'),
    minOrderAmount: Yup.number().required('Please minimum order amount'),
    password: Yup.string().min(6, 'Please enter at least 6 characters!').max(20, 'Should not be more than 20 characters!').required('Please enter password'),
    //preparationTime:Yup.number().min(1, 'Should be at least 1 min!').max(300, 'Should not be longer than 5 hours!').required('Please enter Preparation Time')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      cuisines:[],
      contactName: '',
      email: '',
      address: '',
      //cuisines: '',
      minOrderAmount: '',
      type:'',
      zip:'',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setSubmitting }) => {

      console.log("values", values)

      //values['cuisines'] = selectedCuisines
      values['openingHours'] = days
      values['image'] = image
      //values['address'] = address
      //values['type'] = selectedType['name']
      values['lat'] = lat
      values['lng'] = lng

      console.log("values", values)

      handleToggle()
      try {
        const res = await helper.post(values, apiPath.addRestaurant, session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg,{variant});
            
          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg,{variant});
            navigate('/dashboard/restaurants', { replace: true });
            
            console.log("res.data.results", res.data.results)            
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });

  const getCuisines = async() => {
    try {
      const res = await helper.get(apiPath.cuisinesList,{},session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/restaurants" />)
        }
        else {

          setCuisines(res.data.results)

          console.log("res.data.results", res.data.results)            
        }

        console.log("working")
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }


  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }

  useEffect(() => {
    getCuisines()
  },[]);



  const { errors, touched, handleSubmit, isSubmitting, getFieldProps,setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {(errorResponse) && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{ width: '100%', my: 2, py: 2 }}>
              <FileUploader handleFile={handleFile}  folder={'restaurants'}/>
            </FormControl>
          </Stack>
        </Stack>

        <Stack spacing={3} className="edit-form-allinput">
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Restaurant name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              label="Phone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="Contact Name"
              type="text"
              label="Contact Name"
              {...getFieldProps('contactName')}
              error={Boolean(touched.contactName && errors.contactName)}
              helperText={touched.contactName && errors.contactName}
            />

          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            {/* <TextField
              fullWidth
              label="Address"
              {...getFieldProps('address')}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            /> */}
            <Stack sx={{width:'100%'}} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <TextField
                        sx={{ width: '75%' }}
                        label="Address"
                        onClick={() => { setModal(true) }}
                        value = {address}
                        {...getFieldProps('address')}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />

                      <TextField
                          sx={{ width: '30%' }}
                          label="District Code"
                          {...getFieldProps('zip')}
                          error={Boolean(touched.zip && errors.zip)}
                          helperText={touched.zip && errors.zip}
                        />
          </Stack>
            <input
            type="hidden"
              
              name="lat"
              value={lat}
            />
            <input
            type="hidden"
              
              name="lng"
              value={lng}
            />
            {/* <Map
              onPlaceSelected={(place) => console.log(place)}
              setLat={setLat}
              setLng={setLng}
              address={address}
              setAddress={setAddress}
              modal={modal}
              setModal={setModal}
            /> */}

              <Map
                lat={lat}
                setLat={setLat}
                lng={lng}
                setLng={setLng}
                address={address}
                setFieldValue={setFieldValue}
                setAddress={setAddress}
                modal={modal}
                setModal={setModal}
              />

            <FormControl sx={{ width: '100%' }}>
              <MultiSelect name='cuisines' label={'Cuisines'} {...getFieldProps('cuisines')} selected={selectedCuisines} options={cuisines} getFieldProps={getFieldProps} touched={touched} errors={errors} handleChange={handleChange} />
            </FormControl>
              
            </Stack>
          {/* <Stack spacing={2}>
            <a href={`servearea/restaurant/asas`}>Restaurant Delivery Setup</a>
          </Stack> */}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl sx={{ width: '100%' }} fullWidth className="form-multioption">
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Autocomplete
                  id="combo-box-demo"
                  className="profile-input-form" 
                  sx={{width:'70%'}}
                  options={possibleTypes}
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    console.log("newValue",newValue)
                    if(newValue)
                    {
                      setSelectedType(newValue);
                      setFieldValue('type',newValue._id)
                    }
                    else
                    {
                      setSelectedType({});
                      setFieldValue('type',"")
                    }
                  }}
                  value={possibleTypes.find(
                    (typeItem) => typeItem._id === selectedType._id
                  )}
                  renderInput={(params) => <TextField {...getFieldProps('type')}
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type} {...params} label="Type" />}
                />

              <TextField
                  sx={{ width: '30%',height:'59px' }}
                  label="Minimum Order Amount"
                  {...getFieldProps('minOrderAmount')}
                  error={Boolean(touched.minOrderAmount && errors.minOrderAmount)}
                  helperText={touched.minOrderAmount && errors.minOrderAmount}
                />

              
</Stack></FormControl>
              
              {/* <TextField
                  fullWidth
                  label="Preparation Time"
                  {...getFieldProps('preparationTime')}
                  error={Boolean(touched.preparationTime && errors.preparationTime)}
                  helperText={touched.preparationTime && errors.preparationTime}
                /> */}
                {/* <TimePick
                  fullWidth
                  label="Preparation Time"
                  {...getFieldProps('preparationTime')}
                  error={Boolean(touched.preparationTime && errors.preparationTime)}
                  helperText={touched.preparationTime && errors.preparationTime}
                /> */}
              <TextField
                sx={{ width: '100%' }}
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />


           

          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Stack sx={{ width: '50%' }} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl sx={{ width: '100%' }}>
              <WorkingHours DAYS={days} handleDateChange={handleDateChange} />
            </FormControl>
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
              <LoadingButton
                sx={{ width: '200px' }}
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  height: '50px',
                  width: '131px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 850,
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  padding: '17px',
                  marginRight: '9px'
                }}
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>

              <LoadingButton
                sx={{ width: '200px' }}
                size="large"
                type="button"
                variant="contained"
                sx={{
                  height: '50px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 850,
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  padding: '17px',
                  background: '#E4E4E4',
                  color: '#000',
                  width: '131px'
                }}
                loading={isSubmitting}
                href="/dashboard/restaurants"
              >
                Cancel
              </LoadingButton></Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
