import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import useSession from 'react-session-hook';
const querystring = require('querystring');
import { useSnackbar } from 'notistack';
import { styled } from '@material-ui/core/styles';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { fDateTime } from '../utils/formatTime';
import helper from "../constants/helper";
import apiPath from "../constants/apiPath";
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created_at', label: 'Created', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_category) => _category.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Category(props) {

  console.log("propsCategory",props)

  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [filteredCategories, setCategories] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('created_at');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(10);

  const [filterBody, setFilterBody] = useState({});
  const [dateFilter, setDateFilters] = useState([null, null]);
  const [status, setStatus] = useState('');

  const [permissions, setPermissions] = useState(session['permissions']);
  const [selectedPermissions, setSelectedPermissions] = useState({});

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
    setFilterBody({ ...filterBody, status: event.target.value })
    getData(page, { 'orderBy': orderBy, "order": order }, { ...filterBody, status: event.target.value })

  };

  const setDateFilter = (value) => {
    setDateFilters(value)
    setFilterBody({ ...filterBody, startDate: value[0], endDate: value[1] })
    getData(page, { 'orderBy': orderBy, "order": order }, { ...filterBody, startDate: value[0], endDate: value[1] })

  };

  const clearFilter = () => {
    setFilterBody({})
    getData(page, { 'orderBy': orderBy, "order": order }, {})
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      console.log("newSelecteds", newSelecteds);
      const newSelecteds = filteredCategories.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleStatus = (id, isActive) => {
    updateStatus(id, isActive);
  };

  const handleArchive = (id) => {
    archive(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      setFilterBody({ ...filterBody, keyword: event.target.value })
      getData(0, { 'orderBy': orderBy, "order": order }, { ...filterBody, keyword: event.target.value })
    }
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredCategories.length) : 0;
  const emptyRows = 0;

  // const filteredCategories = applySortFilter(filteredCategories, getComparator(order, orderBy), filterName);

  const isCategoryNotFound = filteredCategories.length === 0;

  const getData = async (page = 0, query = { 'orderBy': '_id', "order": 'desc' }, body = filterBody) => {
    setPage(page)
    console.log(page)
    query['page'] = page
    query['limit'] = rowsPerPage
    try {
      const res = await helper.post(body, apiPath.adminCategoriesPage + '?' + querystring.stringify(query), session);
      if (res.status === 200) {
        setCategories(res.data.results.docs)
        setTotalRecord(res.data.results.totalDocs)
      }
    } catch (e) {
      console.log(e);
    }
  }


  const updateStatus = async (id, isActive, body = {}) => {

    body.status = (!isActive)
    try {
      const res = await helper.put(body, apiPath.adminCategoryStatusUpdate + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error'
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success'
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { 'orderBy': orderBy, "order": order })
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const archive = async (id, body = {}) => {

    body.status = 1
    try {
      const res = await helper.put(body, apiPath.adminCategoryArchive + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error'
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success'
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { 'orderBy': orderBy, "order": order })
        }
      }
    } catch (e) {
      console.log(e);
    }
  }



  useEffect(() => {
    getData(page, { 'orderBy': orderBy, "order": order })
    console.log("session['permissions']",session['permissions'])
  }, [orderBy, order, page, rowsPerPage]);

  const CardStyle = styled(Card)(({ theme }) => ({
    borderRadius: '3px',
    boxShadow: 'none',
    border: '1px solid #E1E1E1'
  }));

  let activeStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#0C0CFA',
    color: '#fff'
  };

  let deactiveStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#EE1C73',
    color: '#fff'
  };

  return (
    <Page title="Category | Piver">
      <Container maxWidth="xl">
        <Stack sx={{ p: 0 }}>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            handleStatusChange={handleStatusChange}
            status={status}
            clearFilter={clearFilter}
            addButton={'categories/add'}
          />
        </Stack>
        <Card sx={{ borderRadius: '3px', boxShadow: '0', border: '1px solid #E1E1E1' }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredCategories.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredCategories
                    .map((row) => {
                      const { id, name, isActive, image, created_at } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/*<Checkbox
                                                          checked={isItemSelected}
                                                          onChange={(event) => handleClick(event, name)}
                                                        />*/}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={image} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(isActive === false && 'error') || 'success'}
                              sx={(isActive === true && activeStyle1) || deactiveStyle1}
                            >
                              {(isActive === true && 'Enabled') || 'Disabled'}
                            </Label>
                          </TableCell>

                          <TableCell align="left">{fDateTime(created_at)}</TableCell>

                          <TableCell align="right">
                            <UserMoreMenu key={id} isActive={isActive} id={id} handleStatus={handleStatus} pageId={'category'} session={session} handleArchive={handleArchive} editPath={`../categories/edit/${id}`} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
                </TableBody>
                {isCategoryNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalRecord}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
