import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

export default function EditForm() {
  // let _id = '614d6598a5be0d3958fb70bc';
  const _id = useParams().id;
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [categories, setCategories] = useState([

  ]);
  const [categoryData, setCategoryData] = useState({});


  const getcategoryData = async () => {
    let body = {};
    try {
      const res = await helper.get(apiPath.adminCategoryData + '/' + _id, body, session);
      if (res.status === 200) {
        setCategoryData(res.data.results)
        setDays(res.data.results.openingHours);
        setSelectedCategories(res.data.results.categories);
      }

    } catch (e) {
      console.log(e);
    }
  }


  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const CategorySchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too short!')
      .max(50, 'Too long!')
      .required('Please enter category name'),
  });


  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }

  useEffect(() => {
    getcategoryData()
  }, []);


  const formik = useFormik({

    initialValues: {
      name: categoryData.name ?? '',
    },
    enableReinitialize: true,

    validationSchema: CategorySchema,
    onSubmit: async (values, { setSubmitting }) => {

      console.log("values", values)
      values['image'] = image

      console.log("values", values)

      handleToggle()
      try {
        const res = await helper.put(values, apiPath.updateCategory + '/' + _id, session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg, { variant });

          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg, { variant });
            navigate('/dashboard/categories', { replace: true });

            console.log("res.data.results", res.data.results)
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });


  const h1tag = {
    color: "red"
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {(categoryData) &&
        <FormikProvider value={formik}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {(errorResponse) && <ErrorResponse msg={errorResponse} />}

          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <FormControl sx={{ width: '100%', my: 2, py: 2 }}>

                  {/*<Avatar
              src={imagePreview}
              sx={{
                height: 100,
                width: 100
              }}
            />*/}

                  <FileUploader imageName={categoryData.image} handleFile={handleFile} folder={'categories'} />


                </FormControl>
              </Stack>
            </Stack>

            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Category name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />

              </Stack>

              <Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
                <LoadingButton
                  sx={{ width: '200px' }}
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    height: '50px',
                    width: '131px',
                    borderRadius: '20px',
                    boxShadow: 'none',
                    fontWeight: 850,
                    fontSize: '16px',
                    fontFamily: 'Avenir',
                    padding: '17px',
                    marginRight: '9px'
                  }}
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>

                <LoadingButton
                  sx={{ width: '200px' }}
                  size="large"
                  type="button"
                  variant="contained"
                  sx={{
                    height: '50px',
                    borderRadius: '20px',
                    boxShadow: 'none',
                    fontWeight: 850,
                    fontSize: '16px',
                    fontFamily: 'Avenir',
                    padding: '17px',
                    background: '#E4E4E4',
                    color: '#000',
                    width: '131px'
                  }}
                  loading={isSubmitting}
                  href="/dashboard/categories"
                >
                  Cancel
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      }
    </>
  );
}
