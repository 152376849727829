import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function EditForm() {

  const _id = useParams().id;
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [promocodeData, setPromocodeData] = useState({});
  const [activeInactive, setActiveInactive] = useState(false);
  const [possibleTypes, setPossibleTypes] = useState([
    { name: 'percent'},
    { name: 'fixed' } 
  ]);
  const [selectedType, setSelectedType] = useState({ name: 'percent'});

  const getpromocodeData = async()=>{
    //handleToggle()
    let body={};
    try{
        const res = await helper.get(apiPath.adminPromocodeData+ '/' + _id,body, session);
        if(res.status===200) {
          setPromocodeData(res.data.results)
          setSelectedType({name:res.data.results.type})
          setActiveInactive(res.data.results.isActive)
          //handleToggle()
        }
        
    } catch (e) {
        console.log(e);
    }
    
  }


  //const [selectedDate, handleDateChange] = useState(new Date('2018-01-01T00:00:00.000Z')); 

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Please enter at least 2 characters!')
      .max(20, 'Should not be more than 50 characters!')
      .required('Please enter promocode name'),
    amount: Yup.number().required('Please enter discount value'),
    description: Yup.string().min(3, 'Please enter at least 3 characters!').max(250, 'Should not be more than 250 characters!').required('Please enter description'),
  });

  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }

  useEffect(() => {
    getpromocodeData()
  },[]);


  const formik = useFormik({
  
    initialValues: {
      name: promocodeData.name??'',
      type: promocodeData.type??'',
      amount: promocodeData.amount??'',
      description: promocodeData.description??'',
      applicableTo: promocodeData.applicableTo??'',
      applicableFrom: promocodeData.applicableFrom??'',
    },
    enableReinitialize: true,

    validationSchema: RegisterSchema,
    onSubmit: async (values, { setSubmitting }) => {
      values['image'] = image

      console.log("values", values)

      handleToggle()
      try {
        const res = await helper.put(values, apiPath.updatePromocode+ '/' + _id,session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg,{variant});
            
          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg,{variant});
            navigate('/dashboard/promocodes', { replace: true });
            
            console.log("res.data.results", res.data.results)            
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });


  const h1tag = {
    color:"red"
  } 

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
    {(promocodeData) && 
    <FormikProvider value={formik}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {(errorResponse) && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{ width: '100%', my: 2, py: 2 }}>

              {/*<Avatar
              src={imagePreview}
              sx={{
                height: 100,
                width: 100
              }}
            />*/}

              <FileUploader imageName={promocodeData.image} handleFile={handleFile} folder={'promocodes'}/>


            </FormControl>
          </Stack>
        </Stack>

        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Promocode name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            
          <Autocomplete
                id="combo-box-demo"
                options={possibleTypes}
                getOptionLabel={option => option.name}
                onChange={(event, newValue) => {
                  console.log("newValue",newValue)
                  setSelectedType(newValue);
                }}
                value={selectedType}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} label="Type" />}
              />

            <TextField
              fullWidth
              label="Discount Value"
              {...getFieldProps('amount')}
              error={Boolean(touched.amount && errors.amount)}
              helperText={touched.amount && errors.amount}
            />

          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
             <TextField
              label="applicable To"
              type="date"
              {...getFieldProps('applicableTo')}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(touched.applicableTo && errors.applicableTo)}
              helperText={touched.applicableTo && errors.applicableTo}
            />
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label="applicable From"
              type="date"
              {...getFieldProps('applicableFrom')}
              fullWidth
              error={Boolean(touched.applicableFrom && errors.applicableFrom)}
              helperText={touched.applicableFrom && errors.applicableFrom}
            />
            </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> <TextField
              fullWidth
              rows={4}
              label="Description"
              {...getFieldProps('description')}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
            <FormControlLabel
            fullWidth
              control={
                <Checkbox checked={activeInactive} onChange={(event, newValue) => {
                  setActiveInactive(newValue);
                }}/>
              }
              label="Active"
            />
            </Stack>

          <LoadingButton
            sx={{ width: '200px' }}
            size="large"
            type="submit"
            variant="contained"
            sx = {{background:'#0C0CFA',width:"20%"}}
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
    }
    </>
  );
}
