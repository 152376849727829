import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// material
import {
  Stack,
  TextField,
  Fab,
  IconButton,
  Box,
  Button,
  InputAdornment,
  Avatar
} from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from '../../components/ErrorResponse';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

export default function EditForm() {
  // let _id = '614d6598a5be0d3958fb70bc';
  const _id = useParams().id;
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [errorResponse, setErrorResponse] = useState('');
  const [categoryData, setCategoryData] = useState({});

  const getcategoryData = async () => {
    let body = {};
    try {
      const res = await helper.get(apiPath.detailsSelectionData + '/' + _id, body, session);
      if (res.status === 200) {
        setCategoryData(res.data.results);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const CategorySchema = Yup.object().shape({
    parcelType: Yup.string()
      .min(2, 'Too short!')
      .max(50, 'Too long!')
      .required('Please enter parcel type'),
    vehicleType: Yup.string().required('Please enter vehicle type'),
    maxWeight: Yup.string().required('Please enter max weight'),
    baseFair: Yup.string().required('Please enter base fair'),
    perKMCharges: Yup.string().required('Please enter per km charges')
  });

  useEffect(() => {
    getcategoryData();
  }, []);

  const formik = useFormik({
    initialValues: {
      parcelType: categoryData.parcelType ?? '',
      vehicleType: categoryData.vehicleType ?? 'bike',
      maxWeight: categoryData.maxWeight ?? '',
      baseFair: categoryData.baseFair ?? '',
      perKMCharges: categoryData.perKMCharges ?? ''
    },
    enableReinitialize: true,

    validationSchema: CategorySchema,
    onSubmit: async (values, { setSubmitting }) => {
      handleToggle();
      try {
        const res = await helper.put(values, apiPath.updateSelectionData + '/' + _id, session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error';
            enqueueSnackbar(res.data.msg, { variant });
          } else {
            var variant = 'success';
            enqueueSnackbar(res.data.msg, { variant });
            navigate('/dashboard/manage-selection-data', { replace: true });

            console.log('res.data.results', res.data.results);
          }

          console.log('working');
        }
      } catch (e) {
        setErrorResponse('');
        setTimeout(function () {
          setErrorResponse('');
        }, 5000);
        console.log(e);
      }
      handleClose();
      console.log('values', values);
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });

  const h1tag = {
    color: 'red'
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {categoryData && (
        <FormikProvider value={formik}>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>

          {errorResponse && <ErrorResponse msg={errorResponse} />}

          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} className="edit-form-allinput">
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Parcel Type"
              {...getFieldProps('parcelType')}
              error={Boolean(touched.parcelType && errors.parcelType)}
              helperText={touched.parcelType && errors.parcelType}
            />

        <FormControl sx={{width:'100%'}}>
            <InputLabel id="demo-simple-select-autowidth-label">Vehicle Type</InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              label="Status"
              {...getFieldProps('vehicleType')}
              sx={{ minWidth: '100px' }}
              error={Boolean(touched.vehicleType && errors.vehicleType)}
              helperText={touched.vehicleType && errors.vehicleType}
            >
              <MenuItem value={'bike'}>Bike</MenuItem>
              <MenuItem value={'Car'}>Car</MenuItem>
              <MenuItem value={'truck'}>Truck</MenuItem>
            </Select>
          </FormControl>

          </Stack>

          

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Max Weight"
              id="outlined-start-adornment"
              {...getFieldProps('maxWeight')}
              InputProps={{
                startAdornment: <InputAdornment position="start">kg</InputAdornment>
              }}
              error={Boolean(touched.maxWeight && errors.maxWeight)}
              helperText={touched.maxWeight && errors.maxWeight}
            />

<FormControl sx={{width:'100%'}}>
            <TextField
              id="outlined-adornment-amount"
              {...getFieldProps('baseFair')}
              InputProps={{
                startAdornment: <InputAdornment position="start">GHS</InputAdornment>,
              }}
              label="Base Fair"
              error={Boolean(touched.baseFair && errors.baseFair)}
              helperText={touched.baseFair && errors.baseFair}
            />
          </FormControl>


          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Stack sx={{width:'50%'}}>
            <TextField
              id="outlined-adornment-amount"
              {...getFieldProps('perKMCharges')}
              InputProps={{
                startAdornment: <InputAdornment position="start">GHS</InputAdornment>,
              }}
              label="Per Km Charges"
              error={Boolean(touched.perKMCharges && errors.perKMCharges)}
              helperText={touched.perKMCharges && errors.perKMCharges}
            /> </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
</Stack>
        </Stack>

          <Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              sx={{
                height: '50px',
                width: '131px',
                borderRadius: '20px',
                boxShadow: 'none',
                fontWeight: 850,
                fontSize: '16px',
                fontFamily: 'Avenir',
                padding: '17px',
                marginRight: '9px'
              }}
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>

            <LoadingButton
              size="large"
              type="button"
              variant="contained"
              sx={{
                height: '50px',
                borderRadius: '20px',
                boxShadow: 'none',
                fontWeight: 850,
                fontSize: '16px',
                fontFamily: 'Avenir',
                padding: '17px',
                background: '#E4E4E4',
                color: '#000',
                width: '131px'
              }}
              loading={isSubmitting}
              href="/dashboard/manage-selection-data"
            >
              Cancel
            </LoadingButton>
          </Stack>
        </Stack>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
