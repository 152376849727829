import React, { Fragment, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, OutlinedInput, InputLabel, MenuItem, FormHelperText,TextField, FormControl, Select, Chip } from '@mui/material';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, selected, theme) {
    return {
        fontWeight:
            selected.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


export default function MultipleSelectChip(props) {
    const theme = useTheme();
    //console.log("props.name",props.name,props.errors['selcuisines'],props.errors['selcuisines'])


    //  useEffect(() => {
        
    //  },props.selectedCuisines)

    return (<>
        <InputLabel id="demo-multiple-chip-label">{props.label}</InputLabel>
        <Select
            labelId={props.label}
            id={props.name}
            name={props.name}
            multiple
            value={props.selected}
            onChange={props.handleChange}
            input={<OutlinedInput id={props.name} label={props.label} error={Boolean(props.touched[props.name] && props.errors[props.name])} />}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {props.options.filter(item=> selected.includes(item._id)).map((data) => (
                        <Chip key={data._id} label={data.name} />
                    ))}
                </Box>
            )}
            MenuProps={MenuProps}
        >
            {props.options.map((item) => (
                <MenuItem
                    key={item._id}
                    value={item._id}
                    style={getStyles(item.name, props.selected, theme)}
                >
                    {item.name}
                </MenuItem>
            ))}
        </Select>
         <FormHelperText sx={{'color':'#FF4842'}}>{(props.errors[props.name] && props.touched[props.name]) && props.errors[props.name]}</FormHelperText> 
        </>)
}