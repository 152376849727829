import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import useSession from 'react-session-hook';
const querystring = require('querystring');
import { useSnackbar } from 'notistack';
import { styled } from '@material-ui/core/styles';
// material
import {
  Card,
  Table,
  Stack,
  Box,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
//
import { fDateTime, fDate } from '../utils/formatTime';
import helper from '../constants/helper';
import apiPath from '../constants/apiPath';
import TrackDriver from '../pages/TrackDriver';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'company', label: 'Company', alignRight: false },
  { id: 'idandlicence', label: 'ID and License', alignRight: false },

  { id: 'mobile', label: 'Phone', alignRight: false },
  { id: 'gender', label: 'Gender', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'dob', label: 'DOB', alignRight: false },
  { id: '', label: '', alignRight: true },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'online', label: 'Availability', alignRight: false },
  // { id: 'created_at', label: 'Created', alignRight: false },
  { id: 'track', label: 'Track', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false }
];
// ----------------------------------------------------------------------

let drawerWidth = 0;

export default function Driver() {
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [orderBy, setOrderBy] = useState('_id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(10);

  const [filterBody, setFilterBody] = useState({});
  const [dateFilter, setDateFilters] = useState([null, null]);
  const [status, setStatus] = useState('');
  const [openDriverTrackView, setOpenDriverTrackView] = useState(false);
  const [driverTrackData, setDriverTrackData] = useState({});
  const [orderCountStatus, setOrderCountStatus] = useState('');
  const [orderCount, setOrderCountFilter] = useState('');

  const handleOrderCountChange = (event) => {
    setOrderCountStatus(event.target.value);
    setFilterBody({ ...filterBody, orderCountStatus: event.target.value });
    setFilterBody({ ...filterBody, orderCount: orderCount });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, orderCount: orderCount, orderCountStatus: event.target.value }
    );
  };

  const userPostedOrderCount = (event) => {
    let value = event.target.value ? event.target.value : 0;
    setOrderCountFilter(value);
    setFilterBody({ ...filterBody, orderCountStatus: orderCountStatus });
    setFilterBody({ ...filterBody, orderCount: value });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, orderCount: value, orderCountStatus: orderCountStatus }
    );
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setFilterBody({ ...filterBody, status: event.target.value });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, status: event.target.value }
    );
  };

  const setDateFilter = (value) => {
    setDateFilters(value);
    setFilterBody({ ...filterBody, startDate: value[0], endDate: value[1] });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, startDate: value[0], endDate: value[1] }
    );
  };

  const clearFilter = () => {
    setFilterBody({});
    setOrderCountStatus('');
    setOrderCountFilter('');
    getData(page, { orderBy: orderBy, order: order }, {});
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = drivers.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const ApproveDriver = (id, isActive) => {
    approveStatus(id, isActive);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleStatus = (id, isActive) => {
    updateStatus(id, isActive);
  };

  const handleArchive = (id) => {
    archive(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      setFilterBody({ ...filterBody, keyword: event.target.value });
      getData(
        0,
        { orderBy: orderBy, order: order },
        { ...filterBody, keyword: event.target.value }
      );
    }
  };

  const handleDrawerOpen = (driverTrackData) => {
    drawerWidth = 650;
    setOpenDriverTrackView(true);
    setDriverTrackData(driverTrackData);
  };

  const handleDrawerClose = () => {
    drawerWidth = 0;
    setDriverTrackData({});
    setOpenDriverTrackView(false);
  };

  const handleRatingChange = (event) => {
    console.log('handleRatingChange', event.target.value);
    //setFilterName(event.target.value);
    setFilterBody({ ...filterBody, rating: event.target.value });
    getData(0, { orderBy: orderBy, order: order }, { ...filterBody, rating: event.target.value });
  };

  const handleVehicleChange = (value) => {
    console.log('handleRatingChange', value);
    //setFilterName(value);
    setFilterBody({ ...filterBody, vehicle: value });
    getData(0, { orderBy: orderBy, order: order }, { ...filterBody, vehicle: value });
  };

  //const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;
  const emptyRows = 0;

  const isUserNotFound = drivers.length === 0;

  const getData = async (
    page = 0,
    query = { orderBy: '_id', order: 'desc' },
    body = filterBody
  ) => {
    setPage(page);
    console.log(page);
    query['page'] = page;
    query['limit'] = rowsPerPage;
    try {
      const res = await helper.post(
        body,
        apiPath.adminDrivers + '?' + querystring.stringify(query),
        session
      );
      if (res.status === 200) {
        setDrivers(res.data.results.docs);
        setTotalRecord(res.data.results.totalDocs);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const updateStatus = async (id, isActive, body = {}) => {
    body.status = !isActive;
    try {
      const res = await helper.put(body, apiPath.adminDriverStatusUpdate + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error';
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success';
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { orderBy: orderBy, order: order });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const archive = async (id, body = {}) => {
    body.status = 1;
    try {
      const res = await helper.put(body, apiPath.DriverArchive + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error';
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success';
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { orderBy: orderBy, order: order });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const approveStatus = async (id, isActive, body = {}) => {
    body.status = !isActive;
    try {
      const res = await helper.put(body, apiPath.adminDriverApprove + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error';
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success';
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { orderBy: orderBy, order: order });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    console.log('test');
    getData(page, { orderBy: orderBy, order: order });
  }, [orderBy, order, page, rowsPerPage]);

  const CardStyle = styled(Card)(({ theme }) => ({
    borderRadius: '3px',
    boxShadow: 'none',
    border: '1px solid #E1E1E1'
  }));

  let activeStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#0C0CFA',
    color: '#fff'
  };

  let deactiveStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#EE1C73',
    color: '#fff'
  };

  return (
    <Page title="Riders | Piver">
      <Container maxWidth="xl">
        <Stack sx={{ p: 0 }}>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleRatingChange={handleRatingChange}
            handleVehicleChange={handleVehicleChange}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            //handleStatusChange={handleStatusChange}
            userPostedOrderCount={userPostedOrderCount}
            userOrderCount={true}
            company={true}
            orderCount={orderCount}
            orderCountStatus={orderCountStatus}
            handleOrderCountChange={handleOrderCountChange}
            status={status}
            clearFilter={clearFilter}
            addButton={'drivers/add'}
          />
        </Stack>

        <CardStyle
          className={driverTrackData._id ? 'smallTable table-style2' : 'longTable table-style2'}
        >
          <Box sx={{ display: 'flex' }} className={'table-content'}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={drivers.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {drivers.map((row) => {
                      const {
                        _id,
                        name,
                        address,
                        dob,
                        gender,
                        isActive,
                        isAvailable,
                        company,
                        mobile,
                        licence,
                        driverId,
                        image,
                        isApproved,
                        created_at
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      console.log('name', name);
                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={image} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            {company ? company.name : 'Individual'}
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={driverId} />
                              <Avatar alt={name} src={licence} />
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{mobile}</TableCell>
                          <TableCell align="left">{gender}</TableCell>

                          <TableCell align="left">{address}</TableCell>
                          <TableCell align="left">{dob}</TableCell>

                          {/* <TableCell align="left">{dob}</TableCell> */}
                          {/* <TableCell align="left">{gender}</TableCell> */}
                          {/* <TableCell align="left">{address}</TableCell>
                            <TableCell align="left">{mobile}</TableCell> */}
                          <TableCell align="left">
                            <Button
                              variant="ghost"
                              color={(isApproved === true && 'success') || 'error'}
                              onClick={() => {
                                if (isApproved === true) return false;
                                ApproveDriver(_id);
                              }}
                              sx={{ color: 'text.secondary' }}
                            >
                              {(isApproved === true && 'Approved') || 'Approve'}
                            </Button>
                          </TableCell>

                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(isActive === false && 'error') || 'success'}
                              sx={(isActive === true && activeStyle1) || deactiveStyle1}
                            >
                              {(isActive === true && 'Enabled') || 'Disabled'}
                            </Label>
                          </TableCell>

                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(isAvailable === false && 'error') || 'success'}
                              sx={(isAvailable === true && activeStyle1) || deactiveStyle1}
                            >
                              {(isAvailable === true && 'Online') || 'Offline'}
                            </Label>
                          </TableCell>

                          {/* <TableCell align="left">{fDateTime(created_at)}</TableCell> */}

                          <TableCell align="left">
                            {isAvailable === true && (
                              <Button
                                color="inherit"
                                variant="outlined"
                                sx={{ background: '#083dfa', color: '#fff' }}
                                onClick={() =>
                                  driverTrackData && driverTrackData._id == _id
                                    ? handleDrawerClose()
                                    : handleDrawerOpen(row)
                                }
                              >
                                {driverTrackData._id && driverTrackData._id == _id
                                  ? 'Close'
                                  : 'Track'}
                              </Button>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <UserMoreMenu
                              key={_id}
                              isActive={isActive}
                              pageId={'driver'}
                              id={_id}
                              handleStatus={handleStatus}
                              handleArchive={handleArchive}
                              editPath={`../drivers/edit/${_id}`}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={12} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Box>
          <div className={driverTrackData._id ? 'ViewDrawer drawerHead' : 'HideDrawer drawerHead'}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="draw-title"
              sx={{ padding: '10px 15px', height: '58px', background: '#6a6a6a!important' }}
            >
              <Typography variant="h5" gutterBottom sx={{ height: '20px' }}>
                Track
              </Typography>
            </Stack>
            {driverTrackData._id && (
              <TrackDriver
                key={driverTrackData._id}
                driverTrackData={driverTrackData}
                sx={{}}
              ></TrackDriver>
            )}
          </div>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalRecord}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardStyle>
      </Container>
    </Page>
  );
}
