import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { useSnackbar } from 'notistack';
import Map from '../map/Map';

// ----------------------------------------------------------------------

export default function EditForm() {
  const _id = useParams().id;
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [activeInactive, setActiveInactive] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [address, setAddress] = useState('');
  const [modal, setModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [groups, setGroups] = useState([]);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [subadminData, setSubadminData] = useState({});

  const getsubadminData = async () => {
    handleToggle()
    let body = {};
    try {
      const res = await helper.get(apiPath.adminSubadminData + '/' + _id, body, session);
      if (res.status === 200) {
        setSubadminData(res.data.results)
        if (res.data.results.location) {
          setLng(res.data.results.location[0])
          setLat(res.data.results.location[1])
        }
        setAddress(res.data.results.address)
        if (res.data.results.group) {
          setSelectedGroup(res.data.results.group);
        }
      
      }

    } catch (e) {
      console.log(e);
    }
    handleClose()
  }

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const SubadminSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Please enter at least 2 characters!')
      .max(50, 'Should not be more than 50 characters!')
      .required('Please enter subadmin name')
      .matches(
        /^[a-zA-Z\s]+$/,
        "Must be alphabets"
      ),
    phone: Yup.number().min(1000000000, 'Please enter at least 2 characters!').max(9999999999, 'Should not be more than 50 characters!').required('Please enter contact number'),
    email: Yup.string().email('Email must be a valid email address').required('Please enter email address'),
    address: Yup.string().min(3, 'Please enter at least 3 characters!').max(250, 'Should not be more than 250 characters!').required('Please enter address'),
    password: Yup.string().min(6, 'Please enter at least 6 characters!').max(20, 'Should not be more than 20 characters!'),
    group: Yup.string().required('Please select group'),
  });



  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }
  const getGroups = async () => {
    try {
      const res = await helper.post({}, apiPath.adminGroupList, session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/subadmins" />)
        }
        else {

          setGroups(res.data.results)

          console.log("res.data.results", res.data.results)
        }

        console.log("working")
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }

  useEffect(() => {
    getsubadminData()
    getGroups()
  }, []);


  const formik = useFormik({

    initialValues: {
      name: subadminData.name ?? '',
      phone: subadminData.phone ?? '',
      email: subadminData.email ?? '',
      username: subadminData.username ?? '',
      isActive: subadminData.isActive ?? '',
      group:subadminData.group??'',
      address: subadminData.address ?? '',
      password: ''
    },
    enableReinitialize: true,

    validationSchema: SubadminSchema,
    onSubmit: async (values, { setSubmitting }) => {

      values['image'] = image
      values['lat'] = lat
      values['lng'] = lng

      console.log("values", values)

      handleToggle()
      try {
        const res = await helper.put(values, apiPath.updateSubadmin + '/' + _id, session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg, { variant });

          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg, { variant });
            navigate('/dashboard/subadmins', { replace: true });

            console.log("res.data.results", res.data.results)
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });

  //console.log("selectedPermission",selectedPermission)
  const h1tag = {
    color: "red"
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <>
      {(subadminData) &&
        <FormikProvider value={formik}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {(errorResponse) && <ErrorResponse msg={errorResponse} />}

          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <FormControl sx={{ width: '100%', my: 2, py: 2 }}>
                  <FileUploader imageName={subadminData.image} handleFile={handleFile} folder="admin" />
                </FormControl>
              </Stack>
            </Stack>

            <Stack spacing={3} className="edit-form-allinput">
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  label="User Name"
                  {...getFieldProps('username')}
                  disabled
                  error={Boolean(touched.username && errors.username)}
                  helperText={touched.username && errors.username}
                />

              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Phone"
                  {...getFieldProps('phone')}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email address"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />

                <Stack sx={{ width: '100%' }} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    sx={{ width: '100%' }}
                    label="Address"
                    onClick={() => { setModal(true) }}
                    {...getFieldProps('address')}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                  />

                  <input
                    type="hidden"

                    name="lat"
                    value={lat}
                  />
                  <input
                    type="hidden"

                    name="lng"
                    value={lng}
                  />

                  <Map
                    lat={lat}
                    setLat={setLat}
                    lng={lng}
                    setLng={setLng}
                    address={address}
                    setFieldValue={setFieldValue}
                    setAddress={setAddress}
                    modal={modal}
                    setModal={setModal}
                  />
                </Stack>
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

              <Stack sx={{ width: '50%' }}>
                <Autocomplete
                  id="combo-box-demo"
                  className="profile-input-form"
                  options={groups}
                  {...getFieldProps('group')}
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSelectedGroup(newValue._id);
                      setFieldValue('group', newValue._id)
                    }
                    else {
                      setSelectedGroup("");
                      setFieldValue('group', "")
                    }

                  }}
                  value={(groups.length > 0) && groups.find(
                    (group) => (selectedGroup === group._id)
                  )}
                  //value={selectedGroup}
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextField {...getFieldProps('group')}
                    error={Boolean(touched.group && errors.group)}
                    helperText={touched.group && errors.group} {...params} label="Group" />}
                />
</Stack>
              </Stack>



              <Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    height: '50px',
                    width: '131px',
                    borderRadius: '20px',
                    boxShadow: 'none',
                    fontWeight: 850,
                    fontSize: '16px',
                    fontFamily: 'Avenir',
                    padding: '17px',
                    marginRight: '9px'
                  }}
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>

                <LoadingButton

                  size="large"
                  type="button"
                  variant="contained"
                  sx={{
                    height: '50px',
                    borderRadius: '20px',
                    boxShadow: 'none',
                    fontWeight: 850,
                    fontSize: '16px',
                    fontFamily: 'Avenir',
                    padding: '17px',
                    background: '#E4E4E4',
                    color: '#000',
                    width: '131px'
                  }}
                  loading={isSubmitting}
                  href="/dashboard/subadmins"
                >
                  Cancel
                </LoadingButton></Stack>

            </Stack>
          </Form>
        </FormikProvider>
      }
    </>
  );
}
