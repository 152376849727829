import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, forwardRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import useSession from 'react-session-hook';
const querystring = require('querystring');

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  MenuItem,
  ListItemText
} from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, BookingListToolbar, OrderMoreMenu } from '../components/_dashboard/user';

import { fDateTime } from '../utils/formatTime';
import helper from '../constants/helper';
import apiPath from '../constants/apiPath';
import ViewBooking from '../pages/ViewBooking';

import '../pages/custom.css';
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'bookingId', label: 'Booking ID', alignRight: false },
  { id: 'driver', label: 'Provider', alignRight: false },
  { id: 'customer', label: 'Customer', alignRight: false },
  { id: 'deliveryType', label: 'Parcel Type', alignRight: false },
  { id: 'deliveryCharges ', label: 'Delivery Charges', alignRight: false },
  { id: 'adminCommision ', label: 'Commision', alignRight: false },
  { id: 'deliveryStatus', label: 'Status', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_order) => _order.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let drawerWidth = 0;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginRight: 0
    })
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

export default function Booking() {
  const session = useSession();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [filteredBookings, setBookings] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('_id');
  const [companyName, setCompanyName] = useState('');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(10);

  const [filterBody, setFilterBody] = useState({});
  const [dateFilter, setDateFilters] = useState([null, null]);
  const [deliveryStatus, setDeliveryStatus] = useState('');

  const handleStatusChange = (event) => {
    if (event.target.value) {
      setDeliveryStatus(event.target.value);
      setFilterBody({ ...filterBody, status: event.target.value });
      getData(
        page,
        { orderBy: orderBy, order: order },
        { ...filterBody, status: event.target.value }
      );
    }
  };

  const setDateFilter = (value) => {
    setDateFilters(value);
    setFilterBody({ ...filterBody, startDate: value[0], endDate: value[1] });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, startDate: value[0], endDate: value[1] }
    );
  };

  const clearFilter = () => {
    setFilterBody({});
    getData(page, { orderBy: orderBy, order: order }, {});
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      console.log('newSelecteds', newSelecteds);
      const newSelecteds = filteredBookings.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const setDate = (id, date) => {
    updateDate(id, date);
  };

  const handleStatus = (id, deliveryStatus) => {
    updateStatus(id, deliveryStatus);
  };

  const handleCompanyChange = (id, deliveryStatus) => {
    if (id) {
      setCompanyName(id, deliveryStatus);
      getData(0, { orderBy: orderBy, order: order }, { ...filterBody, company: id._id });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      setFilterBody({ ...filterBody, keyword: event.target.value });
      getData(
        0,
        { orderBy: orderBy, order: order },
        { ...filterBody, keyword: event.target.value }
      );
    }
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredBookings.length) : 0;
  const emptyRows = 0;

  // const filteredBookings = applySortFilter(filteredBookings, getComparator(order, orderBy), filterName);

  const isBookingNotFound = filteredBookings.length === 0;

  const getData = async (
    page = 0,
    query = { orderBy: '_id', order: 'desc' },
    body = filterBody
  ) => {
    setPage(page);
    console.log(page);
    query['page'] = page;
    query['limit'] = rowsPerPage;
    try {
      const res = await helper.post(
        body,
        apiPath.adminBookingsPage + '?' + querystring.stringify(query),
        session
      );
      if (res.status === 200) {
        setBookings(res.data.results.docs);
        setTotalRecord(res.data.results.totalDocs);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateStatus = async (id, deliveryStatus, body = {}) => {
    body.deliveryStatus = deliveryStatus;
    try {
      const res = await helper.put(body, apiPath.adminBookingStatusUpdate + '/' + id, session);
      if (res.status === 200) {
        getData(page, { orderBy: orderBy, order: order });
        handleDrawerClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateDate = async (id, date, body = {}) => {
    body.date = date;
    try {
      const res = await helper.put(body, apiPath.adminBookingDateUpdate + '/' + id, session);
      if (res.status === 200) {
        getData(page, { orderBy: orderBy, order: order });
        handleDrawerClose();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const [openBookingView, setOpenBookingView] = useState(false);
  const [bookingData, setBookingData] = useState({});
  const theme = useTheme();

  const handleClickOpenBookingView = (bookingData) => {
    console.log('bookingData', bookingData);
    setOpenBookingView(true);
    setBookingData(bookingData);
  };

  const handleCloseBookingView = () => {
    setOpenBookingView(false);
  };

  const handleDrawerOpen = (bookingData) => {
    drawerWidth = 650;
    setOpenBookingView(true);
    setBookingData(bookingData);
  };

  const handleDrawerClose = () => {
    drawerWidth = 0;
    setBookingData({});
    setOpenBookingView(false);
  };
  useEffect(() => {
    getData(page, { orderBy: orderBy, order: order });
  }, [orderBy, order, page, rowsPerPage]);

  const CardStyle = styled(Card)(({ theme }) => ({
    borderRadius: '3px',
    boxShadow: 'none',
    border: '1px solid #E1E1E1'
  }));

  let activeStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#0C0CFA',
    color: '#fff'
  };

  let deactiveStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#EE1C73',
    color: '#fff'
  };

  return (
    <Page title="Booking | Piver">
      <Container maxWidth="xl">
        <Stack sx={{ p: 0 }}>
          <BookingListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            handleStatusChange={handleStatusChange}
            handleCompanyChange={handleCompanyChange}
            status={status}
            clearFilter={clearFilter}
          />
        </Stack>

        <Card
          sx={{ borderRadius: '3px', boxShadow: '0', border: '1px solid #E1E1E1' }}
          className={bookingData._id ? 'smallTable table-style2' : 'longTable table-style2'}
        >
          <Box sx={{ display: 'flex' }} className={'table-content'}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: '100%' }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredBookings.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />

                  <TableBody>
                    {filteredBookings.map((row) => {
                      const {
                        id,
                        bookingId,
                        company,
                        driver,
                        user,
                        status,
                        deliveryType,
                        deliveryCharges,
                        adminCommision,
                        deliveryStatus,
                        created_at,
                        deliveredAt
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <>
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                            // component={RouterLink} to={`../view_order/${id}`}
                          >
                            <TableCell padding="checkbox"></TableCell>

                            <TableCell align="left">
                              <b>{bookingId}</b>
                            </TableCell>

                            <TableCell align="left">
                              {company ? <>{company.name}</> : <>{driver.name}</>}
                            </TableCell>
                            <TableCell align="left">
                              {user ? (
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Avatar alt={user.name} src={user.image} />
                                  <Typography variant="subtitle2" noWrap>
                                    {user.name}
                                  </Typography>
                                </Stack>
                              ) : (
                                ''
                              )}
                            </TableCell>

                            <TableCell align="left">{deliveryType}</TableCell>

                            <TableCell align="left">{deliveryCharges}</TableCell>

                            <TableCell align="left">{adminCommision}</TableCell>

                            <TableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  deliveryStatus && deliveryStatus === 'pending'
                                    ? 'warning'
                                    : deliveryStatus === 'delivered' ||
                                      deliveryStatus === 'accepted'
                                    ? 'success'
                                    : deliveryStatus === 'picked'
                                    ? 'info'
                                    : deliveryStatus == 'preparing'
                                    ? 'primary'
                                    : 'error'
                                }
                              >
                                {deliveryStatus ? deliveryStatus : ''}
                              </Label>
                            </TableCell>

                            <TableCell align="left">
                              <Button
                                className="view-more-btn"
                                color="inherit"
                                variant="outlined"
                                sx={{ background: '#083dfa', color: '#fff' }}
                                onClick={() =>
                                  bookingData && bookingData.id == id
                                    ? handleDrawerClose()
                                    : handleDrawerOpen(row)
                                }
                              >
                                {bookingData._id && bookingData._id == id
                                  ? 'View Less'
                                  : 'View More'}
                              </Button>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={12} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isBookingNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Box>
          <div className={bookingData._id ? 'ViewDrawer drawerHead' : 'HideDrawer drawerHead'}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="draw-title"
              sx={{ padding: '10px 15px', height: '58px', background: '#6a6a6a!important' }}
            >
              <Typography variant="h5" gutterBottom sx={{ height: '20px' }}>
                Delivery Details
              </Typography>
              {bookingData.deliveryStatus == 'pending' ? (
                <OrderMoreMenu
                  sx={{ padding: '2px' }}
                  key={bookingData.id}
                  currentStatus={bookingData.deliveryStatus}
                  id={bookingData.id}
                  handleStatus={handleStatus}
                  setDate={setDate}
                  date={bookingData.deliveredAt}
                />
              ) : (
                ''
              )}
            </Stack>
            {bookingData._id && <ViewBooking bookingData={bookingData} sx={{}}></ViewBooking>}
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalRecord}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
