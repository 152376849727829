const apiPath = {
  uploadUrl: '/uploadurl/',
  adminLogin: '/login',
  getPermissions: '/getPermissions',
  forgotPassword: '/forgot_password',
  emailAuth: '/email_auth',
  updatePassword: '/update_password',
  dashboardCount: '/dashboard',
  earningsPage: '/orders/earnings',
  viewEarnings: '/orders/view_earnings',
  adminSubadminsPage: '/sub-admins',
  adminSubadminData: '/get-sub-admin',
  adminSubadminStatusUpdate: '/subadmin/status',
  adminSubadminArchive: '/subadmin/archive',
  addSubadmin: '/add-sub-admin',
  updateSubadmin: '/update-sub-admin',
  adminServeAreaPage: '/serve_area',
  adminServeAreaSave: '/save_serve_area',
  adminGroupList: '/users/get_group_list',
  adminVehicleList: '/get_vehicle_list',
  adminRestaurantList: '/restaurants/get_relation_data',
  adminRestaurantData: '/restaurants',
  adminRestaurantStatusUpdate: '/restaurants/status',
  adminRestaurantApprove: '/restaurants/approve',
  adminRestaurantArchive: '/restaurants/archive',
  addRestaurant: '/restaurants/add',
  updateRestaurant: '/restaurants/update',
  Branches: '/restaurants/branches',
  adminRestaurantBranchList: '/restaurants/restaurant_branches',
  addBranch: '/restaurants/branch/add',
  updateBranch: '/restaurants/branch/update',
  adminBranchData: '/restaurants/branch',
  adminBranchStatusUpdate: '/restaurants/branch/status',
  adminBranchArchive: '/restaurants/branch/archive',
  adminCompanyList: '/get_company_suggestions',
  adminCompaniesPage: '/companies',
  adminCompanyData: '/companies',
  adminCompanyStatusUpdate: '/companies/status',
  adminCompanyApprove: '/companies/approve',
  adminCompanyArchive: '/companies/archive',
  addCompany: '/companies/add',
  updateCompany: '/companies/update',
  Vehicles: '/companies/vehicles',
  adminPromocodeList: '/reviews/get_relation_data',
  adminPromocodesPage: '/promocodes',
  adminPromocodeData: '/promocodes',
  adminPromocodeStatusUpdate: '/promocodes/status',
  adminPromocodeArchive: '/promocodes/archive',
  addPromocode: '/promocodes/add',
  updatePromocode: '/promocodes/update',
  addFaq: '/cmspages/add_faq',
  updateFaq: '/cmspages/update_faq',
  adminFaqData: '/cmspages/get_faq',
  adminFaqsPage: '/cmspages/faqs',

  adminFaqArchive: '/cmspages/archive_faq',
  adminFaqStatusUpdate: '/cmspages/update_faq_status',
  adminReviewList: '/reviews/get_relation_data',
  adminReviewsPage: '/reviews',
  adminReviewData: '/reviews',
  adminReviewStatusUpdate: '/reviews/status',
  adminReviewArchive: '/reviews/archive',
  addReview: '/reviews/add',
  updateReview: '/reviews/update',
  adminCMSPageList: '/cmspages/get_relation_data',
  adminCMSPagesPage: '/cmspages',
  adminCMSPageData: '/cmspages',
  adminCMSPageStatusUpdate: '/cmspages/status',
  adminCMSPageArchive: '/cmspages/archive',
  addCMSPage: '/cmspages/add',
  updateCMSPage: '/cmspages/update',
  cuisinesList: '/restaurants/cuisines/list',
  adminCuisineData: '/restaurants/cuisine',
  updateCuisine: '/restaurants/cuisine/update',
  addCuisine: '/restaurants/cuisine/add',

  categoriesList: '/restaurants/categories/list',
  admincategoryList: '/restaurants/get_categories_data',
  adminCategoryData: '/restaurants/category',
  updateCategory: '/restaurants/category/update',
  adminCategoryArchive: '/restaurants/category/archive',
  addCategory: '/restaurants/category/add',
  adminCategoryStatusUpdate: '/restaurants/category/status',

  selectionDataList: '/selectiondata',
  addSelectionData: '/selectiondata/create',
  updateStatusSelectionData: '/selectiondata/status',
  archiveSelectionData: '/selectiondata/archive',
  updateSelectionData: '/selectiondata/update',
  detailsSelectionData: '/selectiondata/details',

  groupsList: '/users/groups/list',
  admingroupList: '/users/get_groups_data',
  adminGroupData: '/users/group',
  updateGroup: '/users/group/update',
  adminGroupArchive: '/users/group/archive',
  addGroup: '/users/group/add',
  adminGroupStatusUpdate: '/users/group/status',

  locationsList: '/restaurants/locations/list',
  adminlocationList: '/restaurants/get_locations_data',
  adminLocationsPage: '/locations',
  adminLocationData: '/location',
  updateLocation: '/update_location',
  adminLocationArchive: '/location/archive',
  addLocation: '/add_location',
  adminLocationStatusUpdate: '/location/status',

  adminRestaurantsPage: '/restaurants',
  editRestaurant: '/restaurants/update/',
  adminUsersPage: '/users',
  adminUserData: '/users',
  adminUserStatusUpdate: '/users/status',
  adminUserBlockUpdate: '/users/block',
  adminUserArchive: '/users/archive',
  adminUserUpdate: '/users/edit',
  adminContactsPage: '/contacts',
  adminCuisinesPage: '/restaurants/cuisines',
  adminGroupsPage: '/users/groups',
  adminCategoriesPage: '/restaurants/categories',
  adminCuisineStatusUpdate: '/restaurants/cuisine/status',
  adminCuisineArchive: '/restaurants/cuisine/archive',
  adminMenusPage: '/restaurants/menu_items',
  adminMenuData: '/restaurants/menu_item',
  adminMenuStatusUpdate: '/restaurants/menu_item/status',
  adminMenuAdd: '/restaurants/menu_item/add',
  adminMenuUpdate: '/restaurants/menu_item/update',
  adminMenuArchive: '/restaurants/menu_item/archive',

  adminEarningsPage: '/orders/earnings',
  adminParcelEarnings: '/orders/earnings/parcel',
  getRestaurantsSettlement: '/orders/get_restaurants_settlement',
  getDriverSettlement: '/orders/get_driver_settlement',
  addSettlement: '/orders/add_settlement',
  adminLogs: '/logs',

  adminOrdersPage: '/orders',
  adminOrderData: '/orders',
  adminOrderStatusUpdate: '/orders/status',
  adminBookingsPage: '/bookings',
  adminBookingData: '/bookings',
  adminBookingStatusUpdate: '/bookings/status',
  adminBookingDateUpdate: '/bookings/update_date',
  updateProfile: '/update_admin_profile',
  changePassword: '/change_password',
  adminDrivers: '/drivers',
  adminDriverStatusUpdate: '/driver/status',
  adminDriverArchive: '/driver/archive',
  adminAddDriver: '/add_driver',
  adminUpdateDriver: '/update_driver',
  adminDriverData: '/driver',
  adminDriverApprove: '/driver/approve'
};
export default apiPath;
