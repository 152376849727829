import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { useSnackbar } from 'notistack';
import Map from '../map/Map';

// ----------------------------------------------------------------------

export default function AddForm() {
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [address, setAddress] = useState('');
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [locations, setLocations] = useState([
  ]);


 


  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const LocationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too short!')
      .max(50, 'Too long!')
      .required('Please enter location name'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: LocationSchema,
    onSubmit: async (values, { setSubmitting }) => {

      console.log("values", values)
      values['image'] = image
      values['address'] = address
      values['lat'] = lat
      values['lng'] = lng

      handleToggle()
      try {
        const res = await helper.post(values, apiPath.addLocation,session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg,{variant});
            
          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg,{variant});
            navigate('/dashboard/locations', { replace: true });
            
            console.log("res.data.results", res.data.results)            
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });

  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }



  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {(errorResponse) && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{ width: '100%', my: 2, py: 2 }}>

              {/*<Avatar
              src={imagePreview}
              sx={{
                height: 100,
                width: 100
              }}
            />*/}

              <FileUploader handleFile={handleFile} folder={'locations'}/>


            </FormControl>
          </Stack>
        </Stack>

        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Location name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Stack>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="Address"
                onClick={() => { setModal(true) }}
                value = {address}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />
              <input
              type="hidden"
                name="lat"
                value={lat}
              />
              <input
              type="hidden"
                name="lng"
                value={lng}
              />
              <Map
                setLat={setLat}
                // lng={lng}
                setLng={setLng}
                address={address}
                setAddress={setAddress}
                modal={modal}
                setModal={setModal}
              />

            </Stack>
          </Stack>

          <LoadingButton
            sx={{ width: '200px' }}
            size="large"
            type="submit"
            variant="contained"
            sx = {{background:'#0C0CFA',width:"20%"}}
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
