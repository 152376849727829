import React, { Fragment, useState } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { TimePicker,MuiPickersUtilsProvider  } from "@material-ui/lab";
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { TextField } from '@material-ui/core';

export default function ClockPick(props) {
     
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
          value={props.value}
          label={props.label}
          minutesStep={15}
          renderInput={(params) => <TextField {...params} />}
          onChange={(newValue) => props.handleDateChange(newValue,props.day,props.hours)}
          //onChange={props.handleDateChange}
      />
      </LocalizationProvider>
    );
}