import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useSession from 'react-session-hook';
const querystring = require('querystring');
import { useSnackbar } from 'notistack';
import { styled } from '@material-ui/core/styles';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
//
import { fDateTime } from '../utils/formatTime';
import helper from '../constants/helper';
import apiPath from '../constants/apiPath';
import Link from '@mui/material/Link';
//import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import CarRentalOutlinedIcon from '@mui/icons-material/CarRentalOutlined';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'contactNo', label: 'Contact No', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  { id: '', label: 'Status', alignRight: false },
  { id: '', label: 'Approved', alignRight: false },
  { id: 'driver', label: 'Driver', alignRight: false },
  { id: 'vehicle', label: 'Vehicle', alignRight: false },
  { id: 'created_at', label: 'Created', alignRight: false },
  { id: '', label: 'Actions', alignRight: true }
];
// ----------------------------------------------------------------------

export default function Company() {
  const session = useSession();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [orderBy, setOrderBy] = useState('_id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(10);

  const [filterBody, setFilterBody] = useState({});
  const [dateFilter, setDateFilters] = useState([null, null]);
  const [status, setStatus] = useState('');
  const [isApprovedStatus, setIsApproved] = useState('');
  const [orderCountStatus, setOrderCountStatus] = useState('');
  const [orderCount, setOrderCountFilter] = useState('');

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setFilterBody({ ...filterBody, status: event.target.value });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, status: event.target.value }
    );
  };

  const handleOrderCountChange = (event) => {
    setOrderCountStatus(event.target.value);
    setFilterBody({ ...filterBody, orderCountStatus: event.target.value });
    setFilterBody({ ...filterBody, orderCount: orderCount });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, orderCount: orderCount, orderCountStatus: event.target.value }
    );
  };

  const userPostedOrderCount = (event) => {
    let value = event.target.value ? event.target.value : 0;
    setOrderCountFilter(value);
    setFilterBody({ ...filterBody, orderCountStatus: orderCountStatus });
    setFilterBody({ ...filterBody, orderCount: value });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, orderCount: value, orderCountStatus: orderCountStatus }
    );
  };

  const handleStatusChangeApprove = (event) => {
    setIsApproved(event.target.value);
    setFilterBody({ ...filterBody, isApproved: event.target.value });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, isApproved: event.target.value }
    );
  };

  const setDateFilter = (value) => {
    setDateFilters(value);
    setFilterBody({ ...filterBody, startDate: value[0], endDate: value[1] });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, startDate: value[0], endDate: value[1] }
    );
  };

  const clearFilter = () => {
    setFilterBody({});
    setIsApproved('');
    getData(page, { orderBy: orderBy, order: order }, {});
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = companies.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleStatus = (id, isActive) => {
    updateStatus(id, isActive);
  };

  const ApproveCompany = (id, isActive) => {
    approveStatus(id, isActive);
  };

  const handleArchive = (id) => {
    archive(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);

    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      setFilterBody({ ...filterBody, keyword: event.target.value });
      getData(
        0,
        { orderBy: orderBy, order: order },
        { ...filterBody, keyword: event.target.value }
      );
    } else {
    }
  };

  //const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;
  const emptyRows = 0;

  const isUserNotFound = companies.length === 0;

  const getData = async (
    page = 0,
    query = { orderBy: '_id', order: 'desc' },
    body = filterBody
  ) => {
    setPage(page);
    console.log('filterBody', filterBody);
    query['page'] = page;
    query['limit'] = rowsPerPage;
    try {
      const res = await helper.post(
        body,
        apiPath.adminCompaniesPage + '?' + querystring.stringify(query),
        session
      );
      if (res.status === 200) {
        setCompanies(res.data.results.docs);
        setTotalRecord(res.data.results.totalDocs);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateStatus = async (id, isActive, body = {}) => {
    body.status = !isActive;
    try {
      const res = await helper.put(body, apiPath.adminCompanyStatusUpdate + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error';
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success';
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { orderBy: orderBy, order: order });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const approveStatus = async (id, isActive, body = {}) => {
    body.status = !isActive;
    try {
      const res = await helper.put(body, apiPath.adminCompanyApprove + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error';
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success';
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { orderBy: orderBy, order: order });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const archive = async (id, body = {}) => {
    body.status = 1;
    try {
      const res = await helper.put(body, apiPath.adminCompanyArchive + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error';
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success';
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { orderBy: orderBy, order: order });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log('test');
    getData(page, { orderBy: orderBy, order: order });
  }, [orderBy, order, page, rowsPerPage]);

  const CardStyle = styled(Card)(({ theme }) => ({
    borderRadius: '3px',
    boxShadow: 'none',
    border: '1px solid #E1E1E1'
  }));

  let activeStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#0C0CFA',
    color: '#fff'
  };

  let deactiveStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#EE1C73',
    color: '#fff'
  };

  return (
    <Page title="Companies | Piver">
      <Container maxWidth="xl">
        <Stack sx={{ p: 0 }}>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            handleStatusChange={handleStatusChange}
            status={status}
            userPostedOrderCount={userPostedOrderCount}
            userOrderCount={true}
            company={true}
            orderCount={orderCount}
            orderCountStatus={orderCountStatus}
            handleOrderCountChange={handleOrderCountChange}
            handleStatusChangeApprove={handleStatusChangeApprove}
            isApprovedStatus={isApprovedStatus}
            clearFilter={clearFilter}
            //addButton={'add_company'}
          />
        </Stack>

        <Card sx={{ borderRadius: '3px', boxShadow: '0', border: '1px solid #E1E1E1' }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={companies.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {companies.map((row) => {
                    const {
                      _id,
                      name,
                      email,
                      address,
                      isActive,
                      isApproved,
                      contactNo,
                      image,
                      created_at
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;
                    console.log('name', name);
                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                                                         checked={isItemSelected}
                                                         onChange={(event) => handleClick(event, name)}
                                                       />*/}
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={image} />
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{contactNo}</TableCell>
                        <TableCell align="left">{address}</TableCell>

                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={(isActive === false && 'error') || 'success'}
                            sx={(isActive === true && activeStyle1) || deactiveStyle1}
                          >
                            {(isActive === true && 'Enabled') || 'Disabled'}
                          </Label>
                        </TableCell>

                        <TableCell align="left">
                          <Button
                            variant="ghost"
                            color={(isApproved === true && 'success') || 'error'}
                            onClick={() => {
                              if (isApproved === true) return false;
                              ApproveCompany(_id);
                            }}
                            sx={(isApproved === true && activeStyle1) || deactiveStyle1}
                            //sx={{ color: 'text.secondary' }}
                          >
                            {(isApproved === true && 'Approved') || 'Disapprove'}
                          </Button>
                        </TableCell>

                        <TableCell align="left">
                          <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                              navigate(`/dashboard/company/drivers/${_id}`);
                            }}
                          >
                            <GroupOutlinedIcon /> Driver
                          </Link>
                        </TableCell>

                        <TableCell align="left">
                          <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                              navigate(`/dashboard/company/vehicle/${_id}`);
                            }}
                          >
                            <CarRentalOutlinedIcon /> Vehicle
                          </Link>
                        </TableCell>

                        <TableCell align="left">{fDateTime(created_at)}</TableCell>

                        <TableCell align="right">
                          <UserMoreMenu
                            key={_id}
                            isActive={isActive}
                            pageId={'company'}
                            id={_id}
                            handleStatus={handleStatus}
                            handleArchive={handleArchive}
                            //editPath={`../edit_company/${_id}`}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalRecord}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
