import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ image,sx }) {
  console.log("image",image)
  return <Box component="img" src={`/static/${image}`} sx={{ ...sx }} />;
}
