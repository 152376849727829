import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Link, Stack, IconButton, InputAdornment, FormControlLabel } from '@material-ui/core';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import useSession from 'react-session-hook';

import TextField from '../../StyledTextField';
import Checkbox from '../../StyledCheckBox';
import LoadingButton from '../../StyledLoadingButton';

// ----------------------------------------------------------------------
import ErrorResponse from '../../../components/ErrorResponse';
import helper from '../../../constants/helper';
import apiPath from '../../../constants/apiPath';

export default function LoginForm() {
  //const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errorResponse, setErrorResponse] = useState('');
  const session = useSession();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Please enter username'),
    password: Yup.string().required('Please enter password')
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      handleToggle();
      try {
        const res = await helper.post(values, apiPath.adminLogin);
        if (res.status === 200) {
          if (!res.data.success) {
            setErrorResponse(res.data.msg);
            setTimeout(function () {
              setErrorResponse('');
            }, 5000);
          } else {
            console.log('res.data.results', res.data.results);
            session.setSession({ token: res.data.results });
          }

          console.log('working');
        }
      } catch (e) {
        setErrorResponse('');
        setTimeout(function () {
          setErrorResponse('');
        }, 5000);
        console.log(e);
      }
      handleClose();
      console.log('values', values);
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  if (session.isAuthenticated) {
    console.log('session.isAuthenticated', session.isAuthenticated);
    //return navigate('/dashboard', { replace: true })
    return <Navigate to="/dashboard" />;
  }

  return (
    <FormikProvider value={formik}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {errorResponse && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="Username"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>
        <LoadingButton
          fullWidth
          sx={{ background: '#0C0CFA', borderRadius: '20px' }}
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />
          <Link
            component={RouterLink}
            variant="subtitle2"
            to="/forgot_password"
            className="text-blue-color"
          >
            Forgot password?
          </Link>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
