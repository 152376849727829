import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Fab, IconButton, Avatar,Box } from '@material-ui/core';
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";

// Style the Button component
const Button = styled.button`
  /* Insert your favorite CSS code to style a button */
`;
export default function FileUploaderAll(props) {

  const hiddenFileInput = React.useRef(null);
  const [filePreview, setFilePreview] = useState('/static/icons/licence.png');
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const uploadImageToBucket = async(fileObj)=>{

    try{
        const res = await helper.get(apiPath.uploadUrl + props.folder);
        if(res.status===200) {
          const s3res = await helper.putS3(fileObj, res.data.url);
          if(s3res.status===200) {
              props.handleFile(res.data.filename);
              console.log("s3res",s3res)
          }
        }
    } catch (e) {
        console.log(e);
    }
  }




  useEffect(() => {
    setFilePreview(props.fileName ? props.fileName : '/static/icons/licence.png')
    console.log("filePreview",filePreview)
  },[props.fileName]);


  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    uploadImageToBucket(fileUploaded)
    setFilePreview(URL.createObjectURL(fileUploaded))
    props.handleFile(fileUploaded);
  };

  {/*<Fab component="span" onClick={handleClick}>
            <AddPhotoAlternateIcon />
        </Fab>*/}

  return (
    <>
    <label htmlFor="raised-button-file">
      {props.label ? props.label : 'Licence'}
      <IconButton onClick={handleClick} sx={{float:'right'}}>
        <img src={filePreview} alt="Licence"  style={{
                  width: 100,
                  border: '1px solid grey'
        }}/>
      </IconButton>
    </label> 
      {/* <Avatar onClick={handleClick} sx={{
                height: 100,
                width: 100,
                border: '1px solid grey'
       }}/> */}
      
      <input
        type="file"
        accept="image/*" 
        id="raised-button-file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}}
      />
    </>
  )
}