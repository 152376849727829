import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import awardOutline from '@iconify/icons-eva/award-outline';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import menuFill from '@iconify/icons-eva/menu-fill';
import smilingFaceFill from '@iconify/icons-eva/smiling-face-fill';
import homeFill from '@iconify/icons-eva/home-fill';
import peopleOutline from '@iconify/icons-eva/people-outline';
import starFill from '@iconify/icons-eva/star-fill';
import filmFill from '@iconify/icons-eva/film-fill';
import fileOutline from '@iconify/icons-eva/file-outline';
import inboxOutline from '@iconify/icons-eva/inbox-outline';
import PhoneCallOutline from '@iconify/icons-eva/phone-call-outline';
import QuestionMarkOutline from '@iconify/icons-eva/question-mark-circle-outline';
import PersonFill from '@iconify/icons-eva/person-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import CarOutline from '@iconify/icons-eva/car-outline';
import BookFill from '@iconify/icons-eva/book-fill';
import smartPhoneOutline from '@iconify/icons-eva/smartphone-outline';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    permission: 'dashboard',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Users',
    path: '/dashboard/customers',
    permission: 'customer',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Restaurants',
    path: '/dashboard/#',
    permission: 'restaurant_category_cuisine_menu_order',
    icon: <img src={'/static/icons/login-user.svg'} alt="demo" />,
    children: [
      {
        title: 'Restaurants',
        path: '/dashboard/restaurants',
        permission: 'restaurant',
        icon: <img src={'/static/icons/login-user.svg'} alt="demo" />
      },
      {
        title: 'Categories',
        path: '/dashboard/categories',
        permission: 'category',
        icon: getIcon(fileOutline)
      },
      {
        title: 'Cuisines',
        path: '/dashboard/cuisines',
        permission: 'cuisine',
        icon: getIcon(smilingFaceFill)
      },
      {
        title: 'Menu Items',
        path: '/dashboard/menus',
        permission: 'menu',
        icon: <img src={'/static/icons/setting1.png'} alt="demo" />
      },
      {
        title: 'Orders',
        path: '/dashboard/orders',
        permission: 'order',
        icon: <img src={'/static/icons/view-order.svg'} alt="demo" />
      }
    ]
  },
  {
    title: 'Delivery',
    path: '/dashboard/#delivery',
    permission: 'company_booking_driver',
    icon: getIcon(inboxOutline),
    children: [
      // {
      //   title: 'Companies',
      //   path: '/dashboard/companies',
      //   permission: 'company',
      //   icon: <img src={'/static/icons/delivery1.png'} alt="demo" />
      // },
      {
        title: 'Bookings',
        path: '/dashboard/bookings',
        permission: 'booking',
        icon: <img src={'/static/icons/view-order.svg'} alt="demo" />
      },
      {
        title: 'Riders',
        path: '/dashboard/drivers',
        permission: 'driver',
        icon: <img src={'/static/icons/delivery1.png'} alt="demo" />
      }
    ]
  },
  {
    title: 'Companies',
    path: '/dashboard/companies',
    permission: 'company',
    icon: getIcon(fileOutline)
  },
  {
    title: 'Selection data',
    path: '/dashboard/manage-selection-data',
    permission: 'Selection data',
    icon: getIcon(fileOutline)
  },
  {
    title: 'Roles and Permissions',
    path: '/dashboard/groups',
    permission: 'groups',
    icon: getIcon(inboxOutline)
  },
  {
    title: 'Sub Admins',
    path: '/dashboard/subadmins',
    permission: 'subadmins',
    icon: <img src={'/static/icons/subadmin.png'} alt="demo" />
  },

  // {
  //   title: 'Locations',
  //   path: '/dashboard/locations',
  //   permission: 'location',
  //   icon: getIcon(fileOutline)
  // },

  // {
  //   title: 'earnings',
  //   path: '/dashboard/earnings',
  //   permission: 'earnings',
  //   icon: <img src={'/static/icons/login-user.svg'} alt="demo" />
  // },

  {
    title: 'Earnings Manager',
    path: '/dashboard/#earnings',
    permission: 'earnings',
    icon: <img src={'/static/icons/piggy-bank.png'} alt="demo" />,
    children: [
      {
        title: 'Earning from Food orders',
        path: '/dashboard/earnings',
        permission: 'earnings',
        icon: <img src={'/static/icons/login-user.svg'} alt="demo" />
      },
      {
        title: 'View Earnings',
        path: '/dashboard/view-earnings',
        permission: 'earnings',
        icon: <img src={'/static/icons/login-user.svg'} alt="demo" />
      }
    ]
  },

  // {
  //   title: 'activities',
  //   path: '/dashboard/logs',
  //   permission: '',
  //   icon: getIcon(fileOutline)
  // },
  {
    title: 'Reviews',
    path: '/dashboard/reviews',
    permission: 'reviews',
    icon: getIcon(starFill)
  },
  {
    title: 'Promo Codes',
    path: '/dashboard/promocodes',
    permission: 'promocode',
    icon: <img src={'/static/icons/setting1.png'} alt="demo" />
  },
  {
    title: 'Static Contents',
    path: '/dashboard/cmspages',
    permission: 'cms',
    icon: getIcon(fileOutline)
  },
  {
    title: 'Contact Us',
    path: '/dashboard/contact_us',
    permission: 'contacts',
    icon: getIcon(PhoneCallOutline)
  },
  // {
  //   title: 'About Us',
  //   path: '/dashboard/about_us',
  //   icon: getIcon(PersonFill)
  // },
  {
    title: 'FAQs',
    path: '/dashboard/faqs',
    permission: 'faqs',
    icon: getIcon(QuestionMarkOutline)
  }
  /*{
    title: 'product',
    path: '/dashboard/products',
    permission: '',
    icon: getIcon(shoppingBagFill)
  },
  {
    title: 'cuisine',
    path: '/dashboard/cuisine',
    permission: '',
    icon: getIcon(shoppingBagFill)
  },
  {
    title: 'menu',
    path: '/dashboard/menu',
    permission: '',
    icon: getIcon(shoppingBagFill)
  },
  {
    title: 'order',
    path: '/dashboard/order',
    permission: '',
    icon: getIcon(shoppingBagFill)
  },
  {
    title: 'blog',
    path: '/dashboard/blog',
    permission: '',
    icon: getIcon(fileTextFill)
  },
  {
    title: 'login',
    path: '/login',
    permission: '',
    icon: getIcon(lockFill)
  },
  {
    title: 'register',
    path: '/register',
    permission: '',
    icon: getIcon(personAddFill)
  },
  {
    title: 'Not found',
    path: '/404',
    permission: '',
    icon: getIcon(alertTriangleFill)
  }*/
];

export default sidebarConfig;
