import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';

import useSession from 'react-session-hook';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Badge,
  Box,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Dialog from '@mui/material/Dialog';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Slide from '@mui/material/Slide';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
import { fDateTime } from '../utils/formatTime';
import helper from '../constants/helper';
import apiPath from '../constants/apiPath';
import ViewEarning from '../pages/ViewEarning';

import '../pages/custom.css';

const querystring = require('querystring');

//
// import USERLIST from '../_mocks_/user';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'orderId', label: 'Order ID', alignRight: false },
  { id: 'net_amount ', label: 'Price', alignRight: false },
  // { id: 'deductions ', label: 'Deductions', alignRight: false },
  { id: 'type ', label: 'Type', alignRight: false },
  { id: 'commission ', label: 'Admin Commission', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_order) => _order.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

let drawerWidth = 0;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginRight: 0
    })
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

export default function ParcelEarning() {
  const session = useSession();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [filteredOrders, setOrders] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('_id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(10);
  const [totalEarn, setTotalEarn] = useState(0);

  const [filterBody, setFilterBody] = useState({});
  const [dateFilter, setDateFilters] = useState([null, null]);
  const [status, setStatus] = useState('');

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setFilterBody({ ...filterBody, status: event.target.value });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, status: event.target.value }
    );
  };

  const setDateFilter = (value) => {
    setDateFilters(value);
    setFilterBody({ ...filterBody, startDate: value[0], endDate: value[1] });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, startDate: value[0], endDate: value[1] }
    );
  };

  const clearFilter = () => {
    setFilterBody({});
    getData(page, { orderBy: orderBy, order: order }, {});
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      console.log('newSelecteds', newSelecteds);
      const newSelecteds = filteredOrders.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleStatus = (id, status) => {
    updateStatus(id, status);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      setFilterBody({ ...filterBody, keyword: event.target.value });
      getData(
        0,
        { orderBy: orderBy, order: order },
        { ...filterBody, keyword: event.target.value }
      );
    }
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredOrders.length) : 0;
  const emptyRows = 0;

  // const filteredOrders = applySortFilter(filteredOrders, getComparator(order, orderBy), filterName);

  const isOrderNotFound = filteredOrders.length === 0;

  const getData = async (
    page = 0,
    query = { orderBy: '_id', order: 'desc' },
    body = filterBody
  ) => {
    setPage(page);
    console.log(page);
    query['page'] = page;
    query['limit'] = rowsPerPage;
    try {
      const res = await helper.post(
        body,
        apiPath.adminParcelEarnings + '?' + querystring.stringify(query),
        session
      );
      if (res.status === 200) {
        setOrders(res.data.results.docs);
        setTotalEarn(res.data.results.totalEarning[0].orderAmount);
        setTotalRecord(res.data.results.totalDocs);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateStatus = async (id, status, body = {}) => {
    body.status = status;
    try {
      const res = await helper.put(body, apiPath.orderStatusUpdate + '/' + id, session);
      if (res.status === 200) {
        getData(page, { orderBy: orderBy, order: order });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const [openEarningView, setOpenEarningView] = useState(false);
  const [earningData, setEarningData] = useState({});
  const theme = useTheme();

  const handleClickOpenEarningView = (earningData) => {
    console.log('earningData', earningData);
    setOpenEarningView(true);
    setEarningData(earningData);
  };

  const handleCloseEarningView = () => {
    setOpenEarningView(false);
  };

  const handleDrawerOpen = (earningData) => {
    console.log('earningData', earningData);
    drawerWidth = 650;
    setOpenEarningView(true);
    setEarningData(earningData);
  };

  const handleDrawerClose = () => {
    drawerWidth = 0;
    setEarningData({});
    setOpenEarningView(false);
  };
  useEffect(() => {
    getData(page, { orderBy: orderBy, order: order });
  }, [orderBy, order, page, rowsPerPage]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Page title="Earning | Piver">
      <Container maxWidth="xl">
        <Stack sx={{ p: 0 }}>
          <UserListToolbar
            totalEarn={totalEarn}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            clearFilter={clearFilter}
          />
        </Stack>

        <Button variant="outlined">Total Earning - {totalEarn}</Button>

        <Card
          sx={{ borderRadius: '3px', boxShadow: '0', border: '1px solid #E1E1E1' }}
          className={earningData._id ? 'smallTable table-style2' : 'longTable table-style2'}
        >
          <Box sx={{ display: 'flex' }} className={'table-content'}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: '100%' }}>
                <Table>
                  {/* <AppBar position="inherit" sx={{ background:'#fff'}} open={openEarningView}>
                    <Main open={openEarningView}> */}
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredOrders.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredOrders.map((row) => {
                      const {
                        id,
                        deliveryType,
                        orderId,
                        orderAmount,
                        deliveryFee,
                        adminCommision
                      } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/*<Checkbox
                                                              checked={isItemSelected}
                                                              onChange={(event) => handleClick(event, orderId)}
                                                            />*/}
                          </TableCell>
                          <TableCell padding="checkbox">
                            <b>{orderId}</b>
                          </TableCell>
                          <TableCell align="left">{orderAmount.toFixed(2)}</TableCell>
                          {/* <TableCell align="left">{deliveryFee+adminFee}</TableCell> */}
                          <TableCell align="left">{capitalizeFirstLetter(deliveryType)}</TableCell>
                          <TableCell align="left">{adminCommision.toFixed(2)}</TableCell>
                          {/*<TableCell align="left">{fDateTime(created_at)}</TableCell>
                                                          <TableCell align="left">{fDateTime(deliveredAt)}</TableCell>*/}
                          <TableCell align="left">
                            <Button
                              color="inherit"
                              variant="outlined"
                              sx={{ background: '#083dfa', color: '#fff' }}
                              onClick={() =>
                                earningData && earningData.id == id
                                  ? handleDrawerClose()
                                  : handleDrawerOpen(row)
                              }
                            >
                              {earningData._id && earningData._id == id ? 'View Less' : 'View More'}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={12} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isOrderNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {/* </Main>
                  </AppBar> */}
                  {/* <Drawer
                      sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                          width: drawerWidth,
                          top: '20%',
                          height: '80%',
                        },
                        top: '20%'
                      }}
                      variant="persistent"
                      anchor="right"
                      open={openEarningView}
                    >
                      <DrawerHeader sx={{ background:"#083dfa", color:"#fff" }}>
                      <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                      </IconButton>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={5}>
                          <Typography variant="h4" gutterBottom>
                          {earningData.orderId}
                          </Typography>
                        </Stack>
                      </DrawerHeader>
                      <Divider />
                      <ViewOrder earningData={earningData}></ViewOrder>
                    </Drawer> */}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Box>
          <div className={earningData._id ? 'ViewDrawer drawerHead' : 'HideDrawer drawerHead'}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="draw-title"
            >
              <Typography variant="h4" gutterBottom>
                {earningData.orderId}
              </Typography>
            </Stack>
            {earningData._id && <ViewEarning earningData={earningData} sx={{}}></ViewEarning>}
          </div>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalRecord}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
