import React, { Fragment, useState } from "react";
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { TextField,Box } from '@material-ui/core';
import {DateRangePicker} from '@material-ui/lab';

export default function FilterDate(props) {

    console.log("props.dateFilter",props.dateFilter)
    
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
         <DateRangePicker
          startText="Start"
          value={props.dateFilter}
          onChange={(newValue) => {
            props.setDateFilter(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} />
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    );
}