import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, FormControlLabel, Checkbox } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { ConstructionRounded } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import Map from '../map/Map';

// ----------------------------------------------------------------------

export default function AddForm() {
  const _resid = useParams().resid;
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [address, setAddress] = useState('');
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [activeInactive, setActiveInactive] = useState(false);
  const [cuisines, setCuisines] = useState([
  ]);



  const [days, setDays] = useState([
    {
      day: "sunday",
      label: "Sun",
      openingTime: new Date(),
      closingTime: new Date()
    },
    {
      day: "monday",
      label: "Mon",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "tuesday",
      label: "Tue",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "wednesday",
      label: "Wed",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "thursday",
      label: "Thu",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "friday",
      label: "Fri",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "saturday",
      label: "Sat",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    }
  ])

  //const [selectedDate, handleDateChange] = useState(new Date('2018-01-01T00:00:00.000Z')); 

  const [selectedCuisines, setSelectedCuisines] = useState([]);

  const handleDateChange = (time, day, hours) => {

    console.log("time", time, day)


    console.log("eeeee", days.map((item) => {
      if (day === item['day'])
        item[hours] = time;
      return item;
    }))


    setDays(days.map((item) => {
      if (day === item['day'])
        item[hours] = time;
      return item
    }))

  };

  const handleChange = (event) => {

    console.log("event",event.target)

    const {
      target: { value },
    } = event;
    setSelectedCuisines(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setFieldValue('cuisines',typeof value === 'string' ? value.split(',') : value)
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const BranchSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Please enter at 2 least characters!')
      .max(50, 'Should not be more than 50 characters!')
      .required('Please enter branch name'),
    phone: Yup.number().min(1000000000, 'Please enter correct number!').max(9999999999, 'Please enter correct number!').required('Please enter phone number'),
    email: Yup.string().email('Email must be a valid email address').required('Please enter email address'),
    // address: Yup.string().min(3, 'Please enter at 3 least characters!').max(50, 'Should not be more than 250 characters!').required('Please enter address'),
    // minOrderAmount: Yup.number().required('Please minimum order amount'),
    // password: Yup.string().min(6, 'Please enter at 6 least characters!').max(20, 'Too Long!').required('Please enter password'),
    preparationTime:Yup.number().required('Please enter preparation time'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      isActive: '',
      // address: '',
      cuisines:[],
      // minOrderAmount: '',
      preparationTime:'',
      password: '',
      restaurant: _resid
    },
    validationSchema: BranchSchema,
    onSubmit: async (values, { setSubmitting }) => {
      values['isActive'] = activeInactive
      values['cuisines'] = selectedCuisines
      values['openingHours'] = days
      values['image'] = image,
      values['address'] = address
      values['lat'] = lat
      values['lng'] = lng
      handleToggle()
      try {
        const res = await helper.post(values, apiPath.addBranch, session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg,{variant});
            
          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg,{variant});
            navigate(`/dashboard/branches/${_resid}`, { replace: true });
            
            console.log("res.data.results", res.data.results)            
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });

  const getCuisines = async() => {
    try {
      const res = await helper.get(apiPath.cuisinesList,{},session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/restaurants" />)
        }
        else {

          setCuisines(res.data.results)

          console.log("res.data.results", res.data.results)            
        }

        console.log("working")
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }

  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }

  useEffect(() => {
    getCuisines()
  },[]);


  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {(errorResponse) && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{ width: '100%', my: 2, py: 2 }}>

              {/*<Avatar
              src={imagePreview}
              sx={{
                height: 100,
                width: 100
              }}
            />*/}

              <FileUploader handleFile={handleFile} folder="restaurants" />


            </FormControl>
          </Stack>
        </Stack>

        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Branch name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            {/* <TextField
              fullWidth
              label="Address"
              {...getFieldProps('address')}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            /> */}
            <TextField
              fullWidth
              label="Address"
              onClick={() => { setModal(true) }}
              value = {address}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            />
            <input
            type="hidden"
              name="lat"
              value={lat}
            />
            <input
            type="hidden"
              name="lng"
              value={lng}
            />
            <Map
              setLat={setLat}
              // lng={lng}
              setLng={setLng}
              address={address}
              setAddress={setAddress}
              modal={modal}
              setModal={setModal}
            />

          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{ width: '100%' }}>
              <MultiSelect name='cuisines' label={'Cuisines'} selectedCuisines={selectedCuisines} setFieldValue={setFieldValue} getFieldProps={getFieldProps} touched={touched} errors={errors} cuisines={cuisines} handleChange={handleChange} />
            </FormControl>

            {/*<ClockPick name="open" label="Open Time" />
            <ClockPick name="close" label="Close Time" />
  */}

            <FormControl sx={{ width: '100%' }}>
              <WorkingHours DAYS={days} handleDateChange={handleDateChange} />
            </FormControl>

          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="email"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              label="Phone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />

          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                {/* <TextField
                  fullWidth
                  label="Minimum Order Amount"
                  {...getFieldProps('minOrderAmount')}
                  error={Boolean(touched.minOrderAmount && errors.minOrderAmount)}
                  helperText={touched.minOrderAmount && errors.minOrderAmount}
                /> */}
                <TextField
                  sx={{width:'50%'}}
                  label="Preparation Time"
                  {...getFieldProps('preparationTime')}
                  error={Boolean(touched.preparationTime && errors.preparationTime)}
                  helperText={touched.preparationTime && errors.preparationTime}
                />
              {/* <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              /> */}
              {/* <FormControlLabel
              fullWidth
                control={
                  <Checkbox checked={activeInactive} onChange={(event, newValue) => {
                    setActiveInactive(newValue);
                  }}/>
                }
                label="Active"
              /> */}
          </Stack>



          <LoadingButton
            sx={{ width: '200px' }}
            size="large"
            type="submit"
            variant="contained"
            sx = {{background:'#0C0CFA',width:"20%"}}
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
