import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useSession from 'react-session-hook';
const querystring = require('querystring');
import { useSnackbar } from 'notistack';
import LoadingButton from '../components/StyledLoadingButton';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Box,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
//
import { fDateTime, fDate } from '../utils/formatTime';
import helper from '../constants/helper';
import apiPath from '../constants/apiPath';
import { withScriptjs } from 'react-google-maps';
import { styled } from '@material-ui/core/styles';
import '../pages/custom.css';

const CardStyle = styled(Card)(({ theme }) => ({
  borderRadius: '3px',
  boxShadow: 'none',
  border: '1px solid #E1E1E1'
}));
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'make', label: 'Make', alignRight: false },
  { id: 'model', label: 'Model', alignRight: false },
  { id: 'year', label: 'Year', alignRight: false },
  { id: 'number', label: 'Number', alignRight: false },
  { id: 'regNumber', label: 'Reg. Number', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created_at', label: 'Created At', alignRight: false }
  // { id: 'actions', label: 'Actions', alignRight: false }
];
// ----------------------------------------------------------------------

export default function Vehicle() {
  const { companyId } = useParams();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [drivers, setVehicles] = useState([]);
  const [orderBy, setOrderBy] = useState('_id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecord, setTotalRecord] = useState(5);

  const [filterBody, setFilterBody] = useState({});
  const [dateFilter, setDateFilters] = useState([null, null]);
  const [status, setStatus] = useState('');

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    console.log('dsfdsf', status);
    setFilterBody({ ...filterBody, status: event.target.value });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, status: event.target.value }
    );
  };

  const setDateFilter = (value) => {
    setDateFilters(value);
    setFilterBody({ ...filterBody, startDate: value[0], endDate: value[1] });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, startDate: value[0], endDate: value[1] }
    );
  };

  const clearFilter = () => {
    setFilterBody({});
    getData(page, { orderBy: orderBy, order: order }, {});
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = drivers.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleStatus = (id, isActive) => {
    updateStatus(id, isActive);
  };

  const handleArchive = (id) => {
    archive(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      setFilterBody({ ...filterBody, keyword: event.target.value });
      getData(
        0,
        { orderBy: orderBy, order: order },
        { ...filterBody, keyword: event.target.value }
      );
    }
  };

  //const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;
  const emptyRows = 0;

  const isUserNotFound = drivers.length === 0;

  const getData = async (
    page = 0,
    query = { orderBy: '_id', order: 'desc' },
    body = filterBody
  ) => {
    setPage(page);
    console.log(page);
    query['page'] = page;
    query['limit'] = rowsPerPage;
    try {
      body = { ...body, companyId: companyId };
      const res = await helper.post(
        body,
        apiPath.Vehicles + '?' + querystring.stringify(query),
        session
      );
      if (res.status === 200) {
        setVehicles(res.data.results.docs);
        setTotalRecord(res.data.results.totalDocs);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const updateStatus = async (id, isActive, body = {}) => {
    body.status = !isActive;
    try {
      const res = await helper.put(body, apiPath.VehicleStatusUpdate + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error';
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success';
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { orderBy: orderBy, order: order });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const archive = async (id, body = {}) => {
    body.status = 1;
    try {
      const res = await helper.put(body, apiPath.VehicleArchive + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error';
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success';
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { orderBy: orderBy, order: order });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log('test');
    getData(page, { orderBy: orderBy, order: order });
  }, [orderBy, order, page, rowsPerPage]);

  let activeStyle = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#0C0CFA',
    color: '#fff'
  };

  let deactiveStyle = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#EE1C73',
    color: '#fff'
  };

  return (
    <Page title="Vehicles | Piver">
      <Container maxWidth="xl">
        <Stack sx={{ p: 0 }}>
          {/* <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            handleStatusChange={handleStatusChange}
            status={status}
            clearFilter={clearFilter}
            addButton={'vehicles/add'}
          /> */}
        </Stack>

        <Card sx={{ borderRadius: '3px', boxShadow: '0', border: '1px solid #E1E1E1' }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={drivers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {drivers.map((row) => {
                    const {
                      _id,
                      type,
                      make,
                      model,
                      year,
                      isActive,
                      number,
                      regNumber,
                      created_at
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;
                    // console.log("name",name)
                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox"></TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          {type.name}
                        </TableCell>
                        <TableCell align="left">{make}</TableCell>

                        <TableCell align="left">{model}</TableCell>

                        <TableCell align="left">{year}</TableCell>

                        <TableCell align="left">{number}</TableCell>

                        <TableCell align="left">{regNumber}</TableCell>

                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={(isActive === false && 'error') || 'success'}
                            sx={(isActive === false && deactiveStyle) || activeStyle}
                          >
                            {(isActive === true && 'Enabled') || 'Disabled'}
                          </Label>
                        </TableCell>

                        <TableCell align="left">{fDateTime(created_at)}</TableCell>

                        {/* <TableCell align="left">
                          <UserMoreMenu
                            key={_id}
                            isActive={isActive}
                            id={_id}
                            handleStatus={handleStatus}
                            handleArchive={handleArchive}
                            editPath={`../vehicles/edit/${_id}`}
                          />
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRecord}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
