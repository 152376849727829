import * as React from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState,useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useSession from 'react-session-hook';
const querystring = require('querystring');

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Box,
  Checkbox,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, OrderMoreMenu } from '../components/_dashboard/user';

import { fDateTime } from '../utils/formatTime';
import helper from "../constants/helper";
import apiPath from "../constants/apiPath";
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'earningId', label: 'Order ID', alignRight: false },
  { id: 'restaurant', label: 'Restaurant', alignRight: false },
  { id: 'user', label: 'User', alignRight: false },
  { id: 'items', label: 'Items', alignRight: false },
  { id: 'net_amount ', label: 'Net Amount', alignRight: false },
  { id: 'transaction_id ', label: 'Transaction ID', alignRight: false },
  { id: 'payment_mode ', label: 'Payment Mode', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created_at', label: 'Created', alignRight: false },
  { id: 'deliverd', label: 'Delivered', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false  }
];

// ----------------------------------------------------------------------

export default function ViewOrder(props) {
  const session = useSession();
  const [earningData, setEarningData] = useState({});
  const _id = props.earningId;
  // const getOrderData = async()=>{
  //   let body={};
  //   try{
  //       const res = await helper.get(apiPath.adminOrderData+ '/' + _id,body, session);
  //       if(res.status===200) {
  //         console.log("res.data.results",res.data.results)
  //         setEarningData(res.data.results)
  //       }        
  //   } catch (e) {
  //       console.log(e);
  //   }    
  // }

  useEffect(() => {
    setEarningData(props.earningData)
  },[props.earningData]);

  return (
    <Page title="Earning | Piver">
      <Container maxWidth="xl">

        <Scrollbar>
          <Card sx={{ my:2 , mx:2}}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', px:4, py:2 }}>
              <Typography variant="subtitle1" noWrap sx={{my:2}}>Details
                </Typography>
                <ListItem
                  key="ID"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        ID
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {earningData.id ? earningData.id : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Number"
                  disableGutters
                >
                  <ListItemText
                    className={"MuiListItemText-multiline"}
                    alignItems="flex-start"
                    sx={{ display:'flex' }}
                    disableTypography={true}
                    primary={
                        <Typography
                        className={"MuiTypography-subtitle2"}
                          sx={{ minWidth:'150px' }}
                          
                          variant="subtitle2"
                          color="text.primary"
                        >
                          Number
                        </Typography>
                    }
                    secondary={
                        <Box> 
                        <Typography
                          sx={{  }}
                          
                          variant="body2"
                          color="text.primary"
                        >
                          {earningData.earningId ? earningData.earningId : ''}
                        </Typography>
                        </Box>
                        
                    }
                  />
                  
                </ListItem>
                <ListItem
                  key="Customer"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Customer
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {earningData.user ? earningData.user.name : ''}
                        {earningData.user ? earningData.user.address : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Driver"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Driver
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {earningData.driver ? earningData.driver.name : ''}
                        {earningData.driver ? earningData.driver.address : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Date"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                    sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Date
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {earningData.created_at ?  fDateTime(earningData.created_at)  : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Status"
                  disableGutters
                >
                  <ListItemText
                    className={"MuiListItemText-multiline"}
                    alignItems="flex-start"
                    sx={{ display:'flex' }}
                    disableTypography={true}
                    primary={
                        <Typography
                        className={"MuiTypography-subtitle2"}
                          sx={{ minWidth:'150px' }}
                          
                          variant="subtitle2"
                          color="text.primary"
                        >
                          Status
                        </Typography>
                    }
                    secondary={
                        <Box> 
                        <Typography
                          sx={{  }}
                          
                          variant="body2"
                          color="text.primary"
                        >
                          {earningData.status ? earningData.status : ''}
                        </Typography>
                        </Box>
                        
                    }
                  />
                </ListItem>
                </List>
                
                </Card>
                {/* <Divider/> */}
                <Card sx={{ my:2, mx:2 }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', px:4, py:2 }}>
                <Typography variant="subtitle1" noWrap sx={{my:2}}>Parcel Details
                </Typography>

                        <Table inputProps={{
                                            underline: {
                                                "&&&:before": {
                                                    borderBottom: "none"
                                                },
                                                "&&:after": {
                                                    borderBottom: "none"
                                                }
                                            }
                                        }}>
                          <TableHead sx={{background:"#f1f1f1"}}>
                            <TableCell align="left">
                              <Typography variant="body1" noWrap>Parcel Size
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="body1" noWrap>Image
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="body1" noWrap>Driver
                              </Typography>
                            </TableCell>
                          </TableHead>
                          <TableBody sx ={{padding:"10px"}}>
                                  <TableRow sx={{border:1}} >
                                    <TableCell>
                                      <Stack direction="row" alignItems="center"  sx={{margin:"2px"}} spacing={2}>
                                        {/* <Avatar style={{border: 1,width: "100px",height: "70px", objectFit: 'cover', borderRadius:"0"}}  alt={name} src={image} /> */}
                                        <Typography variant="subtitle2" noWrap>
                                          {earningData.parcelSize}
                                        </Typography>
                                      </Stack>
                                    </TableCell>
                                    <TableCell>
                                      <Stack direction="row" alignItems="center"  sx={{margin:"2px"}} spacing={2}>
                                        <Typography variant="subtitle2" noWrap>
                                          <img src={earningData.image} sx={{width:100}} alt="Parcel"/>
                                        </Typography>
                                      </Stack>
                                    </TableCell> 
                                    <TableCell>
                                      <Stack direction="row" alignItems="center"  sx={{margin:"2px"}} spacing={2}>
                                        <Typography variant="subtitle2" noWrap>
                                          {earningData.driver && earningData.driver.name}
                                        </Typography>
                                      </Stack>
                                    </TableCell>
                                </TableRow>
                          </TableBody>
                        </Table>
                      </List>
                      
                </Card>


            <Card sx={{ my:2, mx:2 }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', px:4, py:2 }}>
                <ListItem
                  key="Promo Code"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Promo Code
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {earningData.promoCode ? earningData.promoCode.name : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Discount"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Discount
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {earningData.discountVal ? earningData.discountVal : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Price"
                  disableGutters
                >
                <ListItemText
                className={"MuiListItemText-multiline"}
                alignItems="flex-start"
                sx={{ display:'flex' }}
                disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Price
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {earningData.subTotalAmount ? `$ ${earningData.subTotalAmount}` : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Net Discount"
                  disableGutters
                >
                <ListItemText
                  className={"MuiListItemText-multiline"}
                  alignItems="flex-start"
                  sx={{ display:'flex' }}
                  disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Net Discount
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {earningData.discount ? earningData.discount : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Delivery Charges"
                  disableGutters
                >
                <ListItemText
                className={"MuiListItemText-multiline"}
                alignItems="flex-start"
                sx={{ display:'flex' }}
                disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Delivery Charges
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {earningData.deliveryFee ? `$ ${earningData.deliveryFee}` : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Admin Fee"
                  disableGutters
                >
                <ListItemText
                className={"MuiListItemText-multiline"}
                alignItems="flex-start"
                sx={{ display:'flex' }}
                disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Admin Fee
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{  }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {earningData.adminFee ? ` $ ${earningData.adminFee}` : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Final Price"
                  disableGutters
                >
                <ListItemText
                className={"MuiListItemText-multiline"}
                alignItems="flex-start"
                sx={{ display:'flex' }}
                disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Final Price
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{ borderTop:'1px solid black' }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {earningData.netAmount ? `$ ${earningData.netAmount}` : ''}
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                <ListItem
                  key="Your Earnings"
                  disableGutters
                >
                <ListItemText
                className={"MuiListItemText-multiline"}
                alignItems="flex-start"
                sx={{ display:'flex' }}
                disableTypography={true}
                  primary={
                      <Typography
                      className={"MuiTypography-subtitle2"}
                        sx={{ minWidth:'150px' }}
                        
                        variant="subtitle2"
                        color="text.primary"
                      >
                        Your Earnings
                      </Typography>
                  }
                  secondary={
                      <Box> 
                      <Typography
                        sx={{ borderTop:'1px solid black' }}
                        
                        variant="body2"
                        color="text.primary"
                      >
                        {earningData.adminFee }
                      </Typography>
                      </Box>
                      
                  }
                />
                </ListItem>
                
            </List>
            
        </Card>
          </Scrollbar>
      </Container>
    </Page>
  );
}
