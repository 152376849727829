import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState,useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import useSession from 'react-session-hook';
const querystring = require('querystring');
import { useSnackbar } from 'notistack';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { fDateTime } from '../utils/formatTime';
import helper from "../constants/helper";
import apiPath from "../constants/apiPath";
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'user', label: 'User', alignRight: false },
  { id: 'message', label: 'Message', alignRight: false  },
  { id: 'created_at', label: 'Created', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_log) => _log.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Log() {
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [filteredLogs, setLogs] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('created_at');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRecord, setTotalRecord] = useState(5);

  const [filterBody, setFilterBody] = useState({});
  const [dateFilter, setDateFilters] = useState([null, null]);
  const [status, setStatus] = useState('');

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
    setFilterBody({...filterBody,status:event.target.value})
    getData(page,{'orderBy': orderBy,"order":order },{...filterBody,status:event.target.value})

};

  const setDateFilter = (value) => {
      setDateFilters(value)
      setFilterBody({...filterBody,startDate:value[0],endDate:value[1]})
      getData(page,{'orderBy': orderBy,"order":order },{...filterBody,startDate:value[0],endDate:value[1]})

  };

  const clearFilter = () => {
    setFilterBody({})
    getData(page,{'orderBy': orderBy,"order":order },{})
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      console.log("newSelecteds",newSelecteds);
      const newSelecteds = filteredLogs.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    if(event.target.value.length >= 3 || event.target.value.length ==0)
    {
      setFilterBody({...filterBody,keyword:event.target.value})
      getData(0,{'orderBy': orderBy,"order":order },{...filterBody,keyword:event.target.value})
    }
  };

  const handleAdminChange = (id,status) => {
    setAdminName(id,status);
    getData(0,{'orderBy': orderBy,"order":order },{...filterBody,admin:id._id})
  };
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredLogs.length) : 0;
  const emptyRows = 0;

  // const filteredLogs = applySortFilter(filteredLogs, getComparator(order, orderBy), filterName);

  const isLogNotFound = filteredLogs.length === 0;

  const getData = async(page=0,query={'orderBy': '_id',"order":'desc' },body=filterBody)=>{
    setPage(page)
    console.log(page)
    query['page'] = page
    query['limit'] = rowsPerPage
    try{
        const res = await helper.post(body, apiPath.adminLogs + '?' + querystring.stringify(query),session);
        if(res.status===200) {
          setLogs(res.data.results.docs) 
          setTotalRecord(res.data.results.totalDocs)
        }
    } catch (e) {
        console.log(e);
    }
  }

  useEffect(() => {
    getData(page,{'orderBy': orderBy,"order":order })
  }, [orderBy,order,page,rowsPerPage]);



  useEffect(() => {
    getData(page,{'orderBy': orderBy,"order":order })
  }, [orderBy,order,page,rowsPerPage]);

  return (
    <Page title="Log | Piver">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Activities
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            // handleStatusChange={handleStatusChange}
            // status={status}
            clearFilter={clearFilter}
            handleAdminChange={handleAdminChange}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredLogs.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredLogs
                    .map((row) => {
                      const { id, message, adminId, restaurantId, companyId, created_at } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/*<Checkbox
                                                          checked={isItemSelected}
                                                          onChange={(event) => handleClick(event, name)}
                                                        />*/}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {
                              adminId!== undefined ?
                              <><Avatar alt={adminId.name} src={adminId.image} />
                              <Typography variant="subtitle2" noWrap>
                                {adminId.name}
                              </Typography></>:
                                restaurantId !== undefined?
                                  <><Avatar alt={restaurantId.name} src={restaurantId.image} />
                                  <Typography variant="subtitle2" noWrap>
                                    {restaurantId.name}
                                  </Typography> </>
                                  : 
                                  companyId!== undefined ?
                                    <><Avatar alt={companyId.name} src={companyId.image} />
                                    <Typography variant="subtitle2" noWrap>
                                      {companyId.name}
                                    </Typography></>
                                    :
                                      ''
                              }
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                             {message}
                          </TableCell>
                          <TableCell align="left">{fDateTime(created_at)}</TableCell>

                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
                </TableBody>
                {isLogNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRecord}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
