import PropTypes from 'prop-types';
import { useEffect,useState,createContext,useContext  } from 'react';
import { Link as RouterLink, Navigate,useLocation,useNavigate } from 'react-router-dom';
import useSession from 'react-session-hook';


// material
import { styled } from '@material-ui/core/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@material-ui/core';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
import helper from "../../constants/helper";
import account from '../../_mocks_/account';
import apiPath from '../../constants/apiPath';

import PermissionContext from '../../contexts/PermissionContext';


// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {

  //console.log("DashboardSidebar",props)

  //const value = useContext(UserContext);  

 // console.log("DashboardSidebar",value)

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const session = useSession();
  let {  userType } = helper.parseJwt(session.token);
  const [permissions, setPermissions] = useState([])
  const handleLogout = () => {

    session.removeSession();
    navigate('/login', { replace: true });
  }

  const getToken = async () => {

    try {
      const res = await helper.get(apiPath.getPermissions,{}, session);
      if (res.status === 200) {
        if (res.data.success) {
        
          if ((res.data.results.isArchive) || (!res.data.results.isActive)) {
            session.removeSession();
            return <Navigate to="/login" />;
          }

          setPermissions([...res.data.results.group.permissions])

          //console.log("res.data.results.group.permissions",res.data.results.group.permissions)

          //session.setSession({ token: res.data.results });
          //session.setSession({ permissions: [...res.data.results.group.permissions] });
          //localStorage.setItem('permissions', res.data.results.group.permissions);
        } 

      }

      if (res.status === 401) {
          session.removeSession();
          return <Navigate to="/login" />;
      }
    } catch (e) {

      let resss = JSON.parse(JSON.stringify(e))

       if (resss.status === 401) {

          session.removeSession();
          return <Navigate to="/login" />;
      }
     // console.log("e.status", e.status,JSON.parse(JSON.stringify(e)));
    }
  }


  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!session.isAuthenticated) {
    //session.removeSession();
      return (<Navigate to="/login" />)
  }

  const renderContent = (



    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#F5F5F5',
          borderBottom: '1px solid #E5E5E5',
          borderRadius: '25px 0px 0px 25px',
          overflow: 'hidden'
        }
      }}
    >

      
      <PermissionContext.Consumer>
      {permissions => (
        
        <>
      <Box sx={{  }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex', width: '100%', background: '#0C0CFA', padding: '22px 0px' }}>
        <Logo sx={{ margin: '0 auto' }} image="dashboard-logo.svg" />
        </Box>
      </Box>


      <NavSection sx={{ mt: 3 }} navConfig={sidebarConfig} permissions = {permissions} userType = {userType} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Button
            fullWidth
            onClick={handleLogout}
            sx={{
              background: '#EE1C73',
              height: '40px',
              boxShadow: 'none',
              borderRadius: '21px',
              maxWidth: '121px!important',
              fontFamily: 'Avenir',
              fontSize: '15px'
            }}
            variant="contained"
          >
            Logout
          </Button>
        </Stack>
      </Box>
     </>
      )}
      </PermissionContext.Consumer>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
