import { useState, useEffect } from 'react';
const querystring = require('querystring');
import useSession from 'react-session-hook';
import { useSnackbar } from 'notistack';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { styled } from '@material-ui/core/styles';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { fDateTime } from '../utils/formatTime';
import helper from '../constants/helper';
import apiPath from '../constants/apiPath';
import { activeStyle1, deactiveStyle1 } from '../components/list';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'mobile', label: 'Phone', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'isBlock', label: 'Block Unblock', alignRight: false },
  { id: 'created_at', label: 'Created', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false }
];

export default function User() {
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [filteredRecords, setRecords] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('created_at');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(10);

  const [filterBody, setFilterBody] = useState({});
  const [dateFilter, setDateFilters] = useState([null, null]);
  const [status, setStatus] = useState('');
  const [orderCountStatus, setOrderCountStatus] = useState('');
  const [orderCount, setOrderCountFilter] = useState('');

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setFilterBody({ ...filterBody, status: event.target.value });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, status: event.target.value }
    );
  };

  const handleOrderCountChange = (event) => {
    setOrderCountStatus(event.target.value);
    if (orderCount && event.target.value) {
      setFilterBody({ ...filterBody, orderCountStatus: event.target.value });
      setFilterBody({ ...filterBody, orderCount: orderCount });
      getData(
        page,
        { orderBy: orderBy, order: order },
        { ...filterBody, orderCount: orderCount, orderCountStatus: event.target.value }
      );
    } else {
      getData(page, { orderBy: orderBy, order: order }, filterBody);
    }
  };

  const userPostedOrderCount = (event) => {
    let value = event.target.value ? event.target.value : 0;
    setOrderCountFilter(value);
    setFilterBody({ ...filterBody, orderCountStatus: orderCountStatus });
    setFilterBody({ ...filterBody, orderCount: value });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, orderCount: value, orderCountStatus: orderCountStatus }
    );
  };

  const setDateFilter = (value) => {
    setDateFilters(value);
    setFilterBody({ ...filterBody, startDate: value[0], endDate: value[1] });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, startDate: value[0], endDate: value[1] }
    );
  };

  const clearFilter = () => {
    setFilterBody({});
    getData(page, { orderBy: orderBy, order: order }, {});
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      //console.log('newSelecteds', newSelecteds);
      const newSelecteds = filteredRecords.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleStatus = (id, isActive) => {
    updateStatus(id, isActive);
  };

  const handleArchive = (id) => {
    archive(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      setFilterBody({ ...filterBody, keyword: event.target.value });
      getData(
        0,
        { orderBy: orderBy, order: order },
        { ...filterBody, keyword: event.target.value }
      );
    }
  };

  const emptyRows = 0;

  const isUserNotFound = filteredRecords.length === 0;

  const getData = async (
    page = 0,
    query = { orderBy: '_id', order: 'desc' },
    body = filterBody
  ) => {
    setPage(page);
    console.log(page);
    query['page'] = page;
    query['limit'] = rowsPerPage;
    try {
      const res = await helper.post(
        body,
        apiPath.adminUsersPage + '?' + querystring.stringify(query),
        session
      );
      if (res.status === 200) {
        setRecords(res.data.results.docs);
        setTotalRecord(res.data.results.totalDocs);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateStatus = async (id, isActive, body = {}) => {
    body.status = !isActive;
    try {
      const res = await helper.put(body, apiPath.adminUserStatusUpdate + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error';
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success';
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { orderBy: orderBy, order: order });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const blockUpdate = async (id, status) => {
    try {
      const res = await helper.put(
        { isBlocked: status },
        apiPath.adminUserBlockUpdate + '/' + id,
        session
      );
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error';
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success';
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { orderBy: orderBy, order: order });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const archive = async (id, body = {}) => {
    body.status = 1;
    try {
      const res = await helper.put(body, apiPath.adminUserArchive + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error';
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success';
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { orderBy: orderBy, order: order });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData(page, { orderBy: orderBy, order: order });
  }, [orderBy, order, page, rowsPerPage]);

  return (
    <Page title="Users | Piver">
      <PerfectScrollbar>
        <Container maxWidth="xl">
          <Stack sx={{ p: 0 }}>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              userOrderCount={true}
              orderCountStatus={orderCountStatus}
              handleOrderCountChange={handleOrderCountChange}
              userPostedOrderCount={userPostedOrderCount}
              onFilterName={handleFilterByName}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
              handleStatusChange={handleStatusChange}
              status={status}
              clearFilter={clearFilter}
            />
          </Stack>

          <Card sx={{ borderRadius: '3px', boxShadow: '0', border: '1px solid #E1E1E1' }}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredRecords.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredRecords.map((row) => {
                      //console.log('row', row);
                      const { _id: id, name, email, isActive, mobile, image, created_at, isBlock } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={image} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{mobile}</TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(isActive === false && 'error') || 'success'}
                              sx={(isActive === true && activeStyle1) || deactiveStyle1}
                            >
                              {(isActive === true && 'Enabled') || 'Disabled'}
                            </Label>
                          </TableCell>

                          <TableCell align="left">
                            {isBlock === true ? (
                              <Button
                                variant="ghost"
                                color={'success'}
                                sx={activeStyle1}
                                onClick={() => {
                                  blockUpdate(id, false);
                                }}
                              >
                                Unblock
                              </Button>
                            ) : (
                              <Button
                                variant="ghost"
                                color={'error'}
                                sx={deactiveStyle1}
                                onClick={() => {
                                  blockUpdate(id, true);
                                }}
                              >
                                Block
                              </Button>
                            )}
                          </TableCell>

                          <TableCell align="left">{fDateTime(created_at)}</TableCell>

                          <TableCell align="right">
                            <UserMoreMenu
                              key={id}
                              isActive={isActive}
                              id={id}
                              handleStatus={handleStatus}
                              pageId={'customer'}
                              handleArchive={handleArchive}
                              editPath={`../customers/edit/${id}`}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={7} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalRecord}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </PerfectScrollbar>
    </Page>
  );
}
