import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import Select from '@mui/material/Select';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@material-ui/core/styles';
import {
  MenuItem,
  Stack,
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import { FilterDate } from '../../forms/index';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import { useState, useEffect } from 'react';
import helper from '../../../constants/helper';
import apiPath from '../../../constants/apiPath';
import { Link as RouterLink } from 'react-router-dom';
import useSession from 'react-session-hook';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 381,
  height: 45,
  borderRadius: 6,
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
    borderRadius: '6px!important'
  },
  '& input': {
    backgroundColor: '#ffff'
  }
}));

// ----------------------------------------------------------------------

OrderListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function OrderListToolbar({
  numSelected,
  filterName,
  onFilterName,
  dateFilter,
  setDateFilter,
  status,
  handleStatusChange,
  handleCompanyChange,
  clearFilter
}) {
  console.log('UserList', dateFilter);

  const [open, setOpen] = React.useState(false);
  const [companies, setCompanies] = useState([]);
  const [errorResponse, setErrorResponse] = useState('');
  const [selectedCompany, setSelectedCompany] = useState({ _id: '', name: '', id: '' });
  const [deliveryStatus, setDeliveryStatus] = useState('');
  const handleOpen = () => setOpen(true);
  const session = useSession();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClear = (e) => {
    console.log('ee', e);
    setDeliveryStatus('');
    handleStatusChange(e);
    setSelectedCompany({ _id: '', name: '', id: '' });
    handleCompanyChange(e);
    setDateFilter([null, null]);
    clearFilter();
    //setOpen(false);
  };

  const getCompanies = async () => {
    try {
      const res = await helper.post({}, apiPath.adminCompanyList, session);
      if (res.status === 200) {
        if (res.data.success) {
          setCompanies(res.data.results);
        }
      }
    } catch (e) {
      setErrorResponse('');
      setTimeout(function () {
        setErrorResponse('');
      }, 5000);
      console.log(e);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
          padding: '0px!important'
        })
      }}
      className="search-list-dash"
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search Results..."
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'md'}
          className="edit-modal-form edit-form-allinput"
      >
        <DialogTitle>
          Filters
          <IconButton onClick={handleClose} sx={{ float: 'right' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <FilterDate setDateFilter={setDateFilter} dateFilter={dateFilter} />
            </Stack>
            <Stack spacing={3}>
              <Autocomplete
                labelId="demo-simple-select-autowidth-label"
                id="combo-box-demo"
                  className="profile-input-form" 
                options={companies}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  //console.log('newValue', newValue);
                  setSelectedCompany(newValue);
                  handleCompanyChange(newValue);
                }}
                value={selectedCompany}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} label="Company" />}
              />
            </Stack>
            <Stack spacing={3}>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={deliveryStatus}
                  onChange={(e) => {
                    setDeliveryStatus(e.target.value);
                    handleStatusChange(e);
                  }}
                  label="Status"
                  sx={{ minWidth: '100px' }}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  {/* <MenuItem value={'pending'}>Pending</MenuItem>
                  <MenuItem value={'accepted'}>Accepted</MenuItem>
                  <MenuItem value={'rejected'}>Rejected</MenuItem> 
                   <MenuItem value={'preparing'}>Preparing</MenuItem> */}
                  <MenuItem value={'picked'}>Picked</MenuItem>
                  <MenuItem value={'delivered'}>Delivered</MenuItem>
                  <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ margin: '0px 20px 20px 0' }}>
          <Button variant="outlined" onClick={handleClear} color="error">
            Clear Filters
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="More Filters" onClick={handleOpen}>
          <IconButton>
            <Icon icon={roundFilterList} />
          </IconButton>
        </Tooltip>
        // <Button onClick={handleOpen}>More Filters</Button>
      )}
    </RootStyle>
  );
}
