import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, forwardRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import useSession from 'react-session-hook';
const querystring = require('querystring');

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  MenuItem,
  ListItemText
} from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, OrderListToolbar, OrderMoreMenu } from '../components/_dashboard/user';

import { fDateTime } from '../utils/formatTime';
import helper from '../constants/helper';
import apiPath from '../constants/apiPath';
import ViewOrder from '../pages/ViewOrder';

import '../pages/custom.css';
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'orderId', label: 'Order ID', alignRight: false },
  { id: 'user', label: 'Customer', alignRight: false },
  { id: 'user', label: 'Restaurant', alignRight: false },
  { id: 'net_amount ', label: 'Net Amount', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created_at', label: 'Placed at', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_order) => _order.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let drawerWidth = 0;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginRight: 0
    })
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}));

export default function Order() {
  const session = useSession();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [filteredOrders, setOrders] = useState([]);
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('_id');
  const [restaurantName, setRestaurantName] = useState('');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(10);

  const [filterBody, setFilterBody] = useState({});
  const [dateFilter, setDateFilters] = useState([null, null]);
  const [status, setStatus] = useState('');

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setFilterBody({ ...filterBody, status: event.target.value });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, status: event.target.value }
    );
  };

  const setDateFilter = (value) => {
    setDateFilters(value);
    setFilterBody({ ...filterBody, startDate: value[0], endDate: value[1] });
    getData(
      page,
      { orderBy: orderBy, order: order },
      { ...filterBody, startDate: value[0], endDate: value[1] }
    );
  };

  const clearFilter = () => {
    setFilterBody({});
    getData(page, { orderBy: orderBy, order: order }, {});
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      console.log('newSelecteds', newSelecteds);
      const newSelecteds = filteredOrders.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleStatus = (id, status) => {
    updateStatus(id, status);
  };
  const handleRestaurantChange = (id, status) => {
    setRestaurantName(id, status);
    getData(0, { orderBy: orderBy, order: order }, { ...filterBody, restaurant: id._id });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      setFilterBody({ ...filterBody, keyword: event.target.value });
      getData(
        0,
        { orderBy: orderBy, order: order },
        { ...filterBody, keyword: event.target.value }
      );
    }
  };

  const handleFilterArea = (event) => {
    //setFilterName(event.target.value);
    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      setFilterBody({ ...filterBody, area: event.target.value });
      getData(0, { orderBy: orderBy, order: order }, { ...filterBody, area: event.target.value });
    }
  };

  const handlePaymentModeChange = (event) => {
    console.log('handlePaymentModeChange', event.target.value);

    //setFilterName(event.target.value);
    setFilterBody({ ...filterBody, paymentMode: event.target.value });
    getData(
      0,
      { orderBy: orderBy, order: order },
      { ...filterBody, paymentMode: event.target.value }
    );
  };

  const handleOrderTypeChange = (event) => {
    //setFilterName(event.target.value);

    setFilterBody({ ...filterBody, deliveryType: event.target.value });
    getData(
      0,
      { orderBy: orderBy, order: order },
      { ...filterBody, deliveryType: event.target.value }
    );
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredOrders.length) : 0;
  const emptyRows = 0;

  // const filteredOrders = applySortFilter(filteredOrders, getComparator(order, orderBy), filterName);

  const isOrderNotFound = filteredOrders.length === 0;

  const getData = async (
    page = 0,
    query = { orderBy: '_id', order: 'desc' },
    body = filterBody
  ) => {
    setPage(page);
    console.log(page);
    query['page'] = page;
    query['limit'] = rowsPerPage;
    try {
      const res = await helper.post(
        body,
        apiPath.adminOrdersPage + '?' + querystring.stringify(query),
        session
      );
      if (res.status === 200) {
        setOrders(res.data.results.docs);
        setTotalRecord(res.data.results.totalDocs);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateStatus = async (id, status, body = {}) => {
    body.status = status;
    try {
      const res = await helper.put(body, apiPath.adminOrderStatusUpdate + '/' + id, session);
      if (res.status === 200) {
        getData(page, { orderBy: orderBy, order: order });
        handleDrawerClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [openOrderView, setOpenOrderView] = useState(false);
  const [orderData, setOrderData] = useState({});
  const theme = useTheme();

  const handleClickOpenOrderView = (orderData) => {
    console.log('orderData', orderData);
    setOpenOrderView(true);
    setOrderData(orderData);
  };

  const handleCloseOrderView = () => {
    setOpenOrderView(false);
  };

  const handleDrawerOpen = (orderData) => {
    drawerWidth = 650;
    setOpenOrderView(true);
    setOrderData(orderData);
  };

  const handleDrawerClose = () => {
    drawerWidth = 0;
    setOrderData({});
    setOpenOrderView(false);
  };
  useEffect(() => {
    getData(page, { orderBy: orderBy, order: order });
  }, [orderBy, order, page, rowsPerPage]);

  const handleCancelOrder = () => {
    handleDrawerClose();
    getData(page, { orderBy: orderBy, order: order });
  };

  const CardStyle = styled(Card)(({ theme }) => ({
    borderRadius: '3px',
    boxShadow: 'none',
    border: '1px solid #E1E1E1'
  }));

  let activeStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#0C0CFA',
    color: '#fff'
  };

  let deactiveStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#EE1C73',
    color: '#fff'
  };

  const Statusbutton = (data) => {
    let { status } = data;

    let colors = {
      preparing: '#ebdc61',
      pending: '#0efa08',
      accepted: '#057c57',
      rejected: '#ff0000',
      delivered: '#083dfa',
      cancelled: '#ff0000',
      picked: '#08c8fa'
    };

    let styless = {
      height: '35px',
      backgroundColor: colors[status],
      borderRadius: '6px',
      boxShadow: 'none',
      fontWeight: 850,
      fontSize: '16px',
      fontFamily: 'Avenir',
      padding: '17px'
    };

    return (
      <Button variant="contained" sx={styless}>
        {status}
      </Button>
    );
  };

  return (
    <Page title="Food Order | Piver">
      <Container maxWidth="xl">
        <Stack sx={{ p: 0 }}>
          <OrderListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onFilterArea={handleFilterArea}
            handlePaymentModeChange={handlePaymentModeChange}
            handleOrderTypeChange={handleOrderTypeChange}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            handleStatusChange={handleStatusChange}
            status={status}
            clearFilter={clearFilter}
          />

          {/* <OrderListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            handleStatusChange={handleStatusChange}
            handleRestaurantChange={handleRestaurantChange}
            status={status}
            clearFilter={clearFilter}
          /> */}
        </Stack>

        <Card
          sx={{ borderRadius: '3px', boxShadow: '0', border: '1px solid #E1E1E1' }}
          className={orderData._id ? 'smallTable table-style2' : 'longTable table-style2'}
        >
          <Box sx={{ display: 'flex' }} className={'table-content'}>
            {/*<CssBaseline />*/}

            <TableContainer sx={{ minWidth: '100%' }}>
              <Table>
                {/*<DrawerHeader />*/}
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredOrders.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredOrders.map((row) => {
                    const {
                      id,
                      orderId,
                      restaurant,
                      user,
                      status,
                      items,
                      netPrice,
                      netAmount,
                      transactionId,
                      paymentMode,
                      created_at,
                      deliveredAt
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <>
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          //onClick={ () => (orderData && orderData.id == id ? handleDrawerClose() : handleDrawerOpen(row))}

                          // component={RouterLink} to={`../view_order/${id}`}
                        >
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell align="left">
                            <b>{orderId}</b>
                          </TableCell>
                          <TableCell align="left">
                            {restaurant ? (
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={restaurant.name} src={restaurant.image} />
                                <Typography variant="subtitle2" noWrap>
                                  {restaurant.name}
                                </Typography>
                              </Stack>
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {user ? (
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Avatar alt={user.name} src={user.image} />
                                <Typography variant="subtitle2" noWrap>
                                  {user.name}
                                </Typography>
                              </Stack>
                            ) : (
                              ''
                            )}
                          </TableCell>
                          {/* <TableCell align="left">
                                {items
                                  .map((eachItem) => {
                                    const { id, name, image } = eachItem;
                                    return (
                                      <p>{name}</p>
                                    );
                                  })
                                }
                                </TableCell> */}
                          <TableCell align="left">{netAmount.toFixed(2)}</TableCell>

                          <TableCell align="left">
                            <Statusbutton status={status} />
                          </TableCell>

                          <TableCell align="left">
                            <TableCell align="left">{fDateTime(created_at)}</TableCell>
                          </TableCell>

                          <TableCell align="left">
                            <Button
                              className="view-more-btn"
                              color="inherit"
                              variant="outlined"
                              sx={{ background: '#083dfa', color: '#fff' }}
                              onClick={() =>
                                orderData && orderData.id == id
                                  ? handleDrawerClose()
                                  : handleDrawerOpen(row)
                              }
                            >
                              {orderData._id && orderData._id == id ? 'View Less' : 'View More'}
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
                </TableBody>

                {isOrderNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {/*<Drawer
                                      sx={{
                                        width: drawerWidth,
                                        flexShrink: 0,
                                        '& .MuiDrawer-paper': {
                                          width: drawerWidth,
                                          top: '20%',
                                          height: '80%',
                                        },
                                        top: '20%'
                                      }}
                                      variant="persistent"
                                      anchor="right"
                                      open={openOrderView}
                                    >
                                      <DrawerHeader sx={{ background:"#083dfa", color:"#fff" }}>
                                      <IconButton onClick={handleDrawerClose}>
                                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                      </IconButton>
                                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} mt={5}>
                                          <Typography variant="h4" gutterBottom>
                                          {orderData.orderId}
                                          </Typography>
                                        </Stack>
                                      </DrawerHeader>
                                      <Divider />
                                      <ViewOrder orderData={orderData}></ViewOrder>
                                    </Drawer>*/}
              </Table>
            </TableContainer>
          </Box>
          <div className={orderData._id ? 'ViewDrawer drawerHead' : 'HideDrawer drawerHead'}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="draw-title"
              sx={{ padding: '10px 15px', height: '58px', background: '#6a6a6a!important' }}
            >
              <Typography variant="h5" gutterBottom sx={{ height: '20px' }}>
                Order Details
              </Typography>
            </Stack>
            {orderData._id && (
              <ViewOrder
                handleStatus={handleStatus}
                cancelledOrder={handleCancelOrder}
                orderData={orderData}
                sx={{}}
              ></ViewOrder>
            )}
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalRecord}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {/* <Dialog
        fullScreen
        open={openOrderView}
        onClose={handleCloseOrderView}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseOrderView}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              View Order
            </Typography>
          </Toolbar>
        </AppBar> */}
      {/* <ViewOrder orderData={orderData}></ViewOrder> */}

      {/* <List>
          <ListItem button>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Default notification ringtone"
              secondary="Tethys"
            />
          </ListItem>
        </List> */}
      {/* </Dialog> */}
    </Page>
  );
}
