import { TextField as TextFieldTemp } from '@material-ui/core';

import { styled } from '@material-ui/core/styles';
import '../developer.css';

const CustomizedTextField = styled(TextFieldTemp)`
  color: #20b2aa;
  :hover {
    color: #2e8b57;
  }
`;

export default function TextField(props) {

  const { label } = props;
  var tempProps = {...props}
  tempProps['placeholder'] = label

  delete tempProps['label']

  return <CustomizedTextField {...tempProps} />;
}
