import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TotalEarning from './TotalEarning';
import ActualEarning from './ActualEarning';
import DriverSettlements from './DriverSettlements';
import Button from '@mui/material/Button';
import useSession from 'react-session-hook';

import helper from '../constants/helper';
import apiPath from '../constants/apiPath';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

export default function FullWidthTabs() {
  const session = useSession();
  const theme = useTheme();
  const [value, setValue] = useState(0);

  //onlineAmount, walletAmount
  const [codAmount, setCodAmount] = useState(0);
  const [onlineAmount, setOnlineAmount] = useState(0);
  const [walletAmount, setWalletAmount] = useState(0);

  const getData = async () => {
    try {
      let body = {};
      if (value === 1) {
        body = {
          earning_type: 'actual'
        };
      }
      const res = await helper.post(body, apiPath.viewEarnings, session);
      if (res.status === 200) {
        if (res.data.success === true) {
          if (res.data.results.codEarning.length > 0) {
            setCodAmount(res.data.results.codEarning[0].netAmount);
          }

          if (res.data.results.onlineEarning.length > 0) {
            setOnlineAmount(res.data.results.onlineEarning[0].netAmount);
          }

          if (res.data.results.walletEarning.length > 0) {
            setWalletAmount(res.data.results.walletEarning[0].netAmount);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (value !== 2) {
      getData();
    }
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="View Total Earning" {...a11yProps(0)} />
          <Tab label="View actual earning after deduct driver payment" {...a11yProps(1)} />
          <Tab
            label="View Report of Received or pending payment from driver in case of cash"
            {...a11yProps(2)}
          />
          <Tab
            label="View Report of Sent or pending payment from driver in case of CC"
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div>
            <Button variant="outlined" style={{ marginRight: '10px' }}>
              COD Earning - {codAmount}
            </Button>
            <Button variant="outlined" style={{ marginRight: '10px' }}>
              Online Earning - {onlineAmount}
            </Button>
            <Button variant="outlined" style={{ marginRight: '10px' }}>
              Wallet Earning - {walletAmount}
            </Button>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div>
            <Button variant="outlined" style={{ marginRight: '10px' }}>
              COD Earning - {codAmount}
            </Button>
            <Button variant="outlined" style={{ marginRight: '10px' }}>
              Online Earning - {onlineAmount}
            </Button>
            <Button variant="outlined" style={{ marginRight: '10px' }}>
              Wallet Earning - {walletAmount}
            </Button>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <DriverSettlements settlementsFor="admin" />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <DriverSettlements settlementsFor="driver" />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
