import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState,useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import useSession from 'react-session-hook';
const querystring = require('querystring');
import { useSnackbar } from 'notistack';
import { Geofence } from '../components/map';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { fDateTime } from '../utils/formatTime';
import helper from "../constants/helper";
import apiPath from "../constants/apiPath";
import { useNavigate, useParams } from 'react-router-dom';
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created_at', label: 'Created', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: true  }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_location) => _location.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AddServeArea() {
  const objectId = useParams().objectId;
  const type = useParams().type;
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [points, setPoints] = useState({});
  const [filteredServeAreas, setServeAreas] = useState([]);
  const [selected, setSelected] = useState([]);

  const getData = async(query = { 'type': type,"objectId":objectId })=>{
    
    query['objectId'] = objectId
    query['type'] = type
    try{
        const res = await helper.post(body, apiPath.adminServeAreaPage + '?' + querystring.stringify(query),session);
        if(res.status===200) {
          setPoints(res.data.results.docs) 
        }
    } catch (e) {
        console.log(e);
    }
  }


  const saveServeArea = async(id,body={})=>{
    try{
        const res = await helper.put(body, apiPath.adminServeAreaSave + '/' + id, session);
        if(res.status===200) {
          if (!res.data.success) {       
            var variant = 'error'
            enqueueSnackbar(res.data.msg,{variant});
          }else{   
            var variant = 'success'
            enqueueSnackbar(res.data.msg,{variant});
            getData(page,{'orderBy': orderBy,"order":order })
          }
        }
    } catch (e) {
        console.log(e);
    }
  }

  useEffect(() => {
    getData({'type': type,"objectId":objectId })
  });

  return (
    <Page title="Serve Area | Piver">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Serve Area
          </Typography>
        </Stack>

        Please select the areas that you cover
      
            <input
            type="hidden"
              
              name="points"
              value={points}
            />
            <Geofence/>
      </Container>
    </Page>
  );
}
