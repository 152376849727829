// routes
import Router from './routes';
import { SnackbarProvider } from 'notistack';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { UseSessionProvider } from 'react-session-hook';
// ----------------------------------------------------------------------

export default function App() {
  
  return (
    <UseSessionProvider>
      <SnackbarProvider autoHideDuration={2000} maxSnack={1} preventDuplicate anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }}>
        <ThemeConfig>
          <ScrollToTop />
          <Router />
        </ThemeConfig>
      </SnackbarProvider>
    </UseSessionProvider>
  );
}
