import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
import smilingFaceFill from '@iconify/icons-eva/smiling-face-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography,Link } from '@material-ui/core';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  color: theme.palette.warning.darker,
  backgroundColor: '#4bacc6',
  display: 'flex',
  alignItems: 'center',
  padding: '20px 15px',
  borderRadius: '22px'
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: '81px',
  height: '81px',
  justifyContent: 'center',
  marginBottom: 0,
  color: theme.palette.warning.dark,
  backgroundColor: '#fff'
}));

const StackStyle = styled('Stack')(({ theme }) => ({
  width: 'calc(100% - 81px)',
  textAlign: 'left',
  paddingLeft: '20px'
}));

// ----------------------------------------------------------------------

const TOTAL = 714000;

export default function AppEarningBookings(props) {
  return (
    <Link component={RouterLink} to="/dashboard/bookings">
    <RootStyle>
      <IconWrapperStyle>
        <Icon>
          <img src="/static/icons/delivery1.png" alt="demo" width={'30px'} />
        </Icon>
      </IconWrapperStyle>
      <StackStyle className="stackstyleheading">
        <Typography variant="h3" className="stackstyleheading">
          {props.total}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: '#fff',
            fontFamily: 'Avenir',
            fontSize: '14px!important',
            fontWeight: '500',
            color: '#fff'
          }}
        >
          Total Earnings from bookings
        </Typography>
      </StackStyle>
    </RootStyle></Link>
  );
}
