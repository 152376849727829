import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DesktopDatePicker from '@material-ui/lab/DesktopDatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, FileUploaderAll, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { ConstructionRounded } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Map from '../map/Map';

// ----------------------------------------------------------------------

export default function AddForm() {
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [address, setAddress] = useState('');
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState("");
  const [licence, setLicence] = useState("");
  const [driverId, setDriverId] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [dob, setDOB] = useState('');
  const [activeInactive, setActiveInactive] = useState(false);
  const [possibleGenders, setPossibleGenders] = useState([
    { _id: 'male',name: 'Male'},
    { _id: 'male',name: 'Female' },
    { _id: 'male',name: 'Other' } 
  ]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedCompany, setSelectedCompany] = useState('');
  const [companies, setCompanies] = useState([
  ]);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const getCompanies = async() => {
    try {
      const res = await helper.post({},apiPath.adminCompanyList,session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/companies" />)
        }
        else {
          setCompanies(res.data.results)
        }
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }

  useEffect(() => {
    getCompanies();
  },[])


  const DriverSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Please enter at least 2 characters!')
      .max(50, 'Should not be more than 50 characters!')
      .required('Please enter driver name')
      .matches(/^[a-zA-Z\s]+$/, 'Must be alphabets'),
    company: Yup.string().required('Please select company'),
    mobile: Yup.number()
      .min(100000000, 'Please enter correct number!')
      .max(9999999999, 'Please enter correct number!')
      .required('Please enter mobile number'),
    address: Yup.string().required('Please enter address'),
    gender: Yup.string().required('Please select gender'),
    vehicle: Yup.string().required('Please select vehicle'),
    dob: Yup.string().required('Please enter dob'),
  });

  const formik = useFormik({
    initialValues: {
      company:"",
      name: '',
      mobile: '',
      address: '',
      gender: '',
      vehicle: '',
      dob: ''
    },
    validationSchema: DriverSchema,
    onSubmit: async (values, { setSubmitting }) => {
      values['image'] = image
      values['licence'] = licence
      values['driverId'] = driverId
    

      handleToggle()
      try {
        const res = await helper.post(values, apiPath.adminAddDriver, session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg,{variant});
            
          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg,{variant});
            navigate('/dashboard/drivers', { replace: true });
            
            console.log("res.data.results", res.data.results)            
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });

  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }

  const handleLicence = (fileUploaded) => {
    console.log("fileUploadedpdf", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setLicence(fileUploaded)
  }
  const handleDriverId = (fileUploaded) => {
    console.log("fileUploadedpdf", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setDriverId(fileUploaded)
  }

  const getVehicles = async(company) => {
    try {
      const res = await helper.post({company:company},apiPath.adminVehicleList ,session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/subadmins" />)
        }
        else {

          setVehicles(res.data.results)

          console.log("res.data.results", res.data.results)            
        }

        console.log("working")
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }


  const { errors, touched, handleSubmit, isSubmitting, getFieldProps,setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {(errorResponse) && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit} className="edit-form-allinput">
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{ width: '100%', my: 2, py: 2 }}>

              <FileUploader handleFile={handleFile} folder={'drivers'} />
            </FormControl>
          </Stack>
        </Stack>

        <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
                
                <TextField
                  fullWidth
                  label="Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              
                <Autocomplete
                  fullWidth
                  className="render-input profile-input-form"
                  id="combo-box-demo11"
                  options={companies}
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    console.log("newValue",newValue)
                    if(newValue)
                    {
                      setFieldValue('company',newValue['_id'])
                      setSelectedCompany(newValue['_id']);
                      getVehicles(newValue['_id'])
                    }
                    else
                    {
                      setFieldValue('company','')
                      setSelectedCompany('');
                      setVehicles([])
                    }
                    
                    // setSelectedCompany(newValue);
                  }}
                  value={(companies.length > 0) && companies.find(
                    (company) => (selectedCompany === company._id)
                  )}
                  //value={selectedCompany}
                  
                  renderInput={(params) => <TextField {...getFieldProps('company')}
                  error={Boolean(touched.company && errors.company)}
                  helperText={touched.company && errors.company}
                  {...params} label="Company" />}
                />
                
              </Stack>

              <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }}>
                
                  <TextField
                      fullWidth
                      label="Phone"
                      {...getFieldProps('mobile')}
                      error={Boolean(touched.mobile && errors.mobile)}
                      helperText={touched.mobile && errors.mobile}
                    />
                
                
                  <TextField
                    fullWidth
                    label="Address"
                    {...getFieldProps('address')}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                  />
               
               </Stack>
               <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }}>
                <Stack sx={{ width: '50%' }} direction="row" spacing={{ xs: 1, sm: 2, md: 4 }} >

                  <FormControl sx={{ width: '50%' }} >

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        type="text"
                        label="DOB"
                        inputFormat="MM/dd/yyyy"
                        value={dob}
                        onChange={(newValue) => {
                          
                          setDOB(newValue);
                          setFieldValue("dob", moment(newValue).format('MM/DD/YYYY'))
                        }}
                       
                        // {...getFieldProps('dob')}
                        renderInput={(params) => <TextField {...getFieldProps('dob')}
                        error={Boolean(touched.dob && errors.dob)}
                        helperText={touched.dob && errors.dob} {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <Autocomplete
                    id="combo-box-demo"
                    sx={{ width: '50%' }}
                    options={possibleGenders}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setFieldValue("gender", newValue._id)
                        setSelectedGender(newValue._id);
                      }
                      else {
                        setFieldValue("gender", "")
                        setSelectedGender("");
                      }
                    }}
                    className="render-input"
                    value={(possibleGenders.length > 0) && possibleGenders.find(
                      (group) => (selectedGender === group._id)
                    )}

                    //value={{_id:selectedGender,name:selectedGender}}
                    renderInput={(params) => <TextField  {...getFieldProps('gender')}
                      error={Boolean(touched.gender && errors.gender)}
                      helperText={touched.gender && errors.gender}
                      {...params} label="Gender" />}
                  />
                </Stack>
                     
                <Stack sx={{ width: '50%' }} spacing={0} >
                  <Autocomplete
                    id="combo-box-demo"
                    options={vehicles}
                    getOptionLabel={(option) => (option) ? `${option.make} ${option.model} ${option.year} - ${option.number}` : ''}
                    onChange={(event, newValue) => {
                      console.log('newValue', newValue);
                      if (newValue) {
                        setFieldValue('vehicle', newValue._id);
                        setSelectedVehicle(newValue._id);
                      } else {
                        setFieldValue("vehicle", "")
                        setSelectedVehicle("");
                      }
                    }}
                    value={(vehicles.length > 0) && vehicles.find(
                      (group) => (selectedVehicle === group._id)
                    )}
                    sx={{ width: '100%' }}
                    className="render-input"
                    renderInput={(params) => (
                      <TextField
                        {...getFieldProps('vehicle')}
                        error={Boolean(touched.vehicle && errors.vehicle)}
                        helperText={touched.vehicle && errors.vehicle}
                        {...params}
                        label="Vehicle"
                      />
                    )}
                  />
                </Stack>

              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

             
                <Stack sx={{ width: '50%' }} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <FileUploaderAll
                    id="fileupload"
                    fileName={""}
                    label={'Licence'}
                    handleFile={handleLicence}
                    folder={'driverlicence'}
                  />
                  <FileUploaderAll
                    sx={{ width: '25%' }}
                    id="fileuploadID"
                    label="Driver ID"
                    fileName={""}
                    handleFile={handleDriverId}
                    folder={'driverid'}
                  />

                </Stack>

              </Stack>


          <Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
            <LoadingButton

              size="large"
              type="submit"
              variant="contained"
              sx={{
                height: '50px',
                width: '131px',
                borderRadius: '20px',
                boxShadow: 'none',
                fontWeight: 850,
                fontSize: '16px',
                fontFamily: 'Avenir',
                padding: '17px',
                marginRight: '9px'
              }}
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>

            <LoadingButton

              size="large"
              type="button"
              variant="contained"
              sx={{
                height: '50px',
                borderRadius: '20px',
                boxShadow: 'none',
                fontWeight: 850,
                fontSize: '16px',
                fontFamily: 'Avenir',
                padding: '17px',
                background: '#E4E4E4',
                color: '#000',
                width: '131px'
              }}
              loading={isSubmitting}
              href="/dashboard/drivers"
            >
              Cancel
            </LoadingButton></Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
