import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, Checkbox, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, FileUploaderAll, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { useSnackbar } from 'notistack';
import Map from '../map/Map';
// ----------------------------------------------------------------------

export default function EditForm() {
  const _id = useParams().id;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const session = useSession();
  const [isActive, setIsActive] = useState(false);

  const [days, setDays] = useState([
    {
      day: "sunday",
      label: "Sun",
      openingTime: new Date(),
      closingTime: new Date()
    },
    {
      day: "monday",
      label: "Mon",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "tuesday",
      label: "Tue",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "wednesday",
      label: "Wed",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "thursday",
      label: "Thu",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "friday",
      label: "Fri",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "saturday",
      label: "Sat",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    }
  ])

  const [showPassword, setShowPassword] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [address, setAddress] = useState('');
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState("");
  const [licence, setLicence] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [cuisines, setCuisines] = useState([

  ]);
  const [companyData, setCompanyData] = useState({});

  const getcompanyData = async()=>{
    //handleToggle()
    let body={};
    try{
        const res = await helper.get(apiPath.adminCompanyData+ '/' + _id,body, session);
        if(res.status===200) {
          setCompanyData(res.data.results)
          if(res.data.results.location){
            setLng(res.data.results.location[0])
            setLat(res.data.results.location[1])
          }
          setAddress(res.data.results.address)
          if(res.data.results.openingHours.length !== 0)
            setDays(res.data.results.openingHours);
          setIsActive(res.data.results.isActive)
          // setSelectedCuisines(res.data.results.cuisines);
          //handleToggle()
        }
        
    } catch (e) {
        console.log(e);
    }
    
  }


  //const [selectedDate, handleDateChange] = useState(new Date('2018-01-01T00:00:00.000Z')); 

  const [selectedCuisines, setSelectedCuisines] = useState([]);

  const handleDateChange = (time, day, hours) => {

    console.log("time", time, day)


    console.log("eeeee", days.map((item) => {
      if (day === item['day'])
        item[hours] = time;
      return item;
    }))


    setDays(days.map((item) => {
      if (day === item['day'])
        item[hours] = time;
      return item
    }))

  };

  const handleChange = (event) => {

    console.log("event",event.target)

    const {
      target: { value },
    } = event;
    setSelectedCuisines(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Please enter at 2 least characters!')
      .max(50, 'Should not be more than 50 characters!')
      .required('Please enter company name')
      .matches(
        /^[a-zA-Z\s]+$/,
        "Must be alphabets"
      ),
    contactNo: Yup.number().min(1000000000, 'Please enter correct number!').max(9999999999, 'Please enter correct number!').required('Please enter contact number'),
    contactName: Yup.string().required('Please enter contact person name'),
    email: Yup.string().email('Email must be a valid email address').required('Please enter email address'),
    password: Yup.string().min(6, 'Please enter at least characters!').max(30, 'should not be more than 20 characters!'),
    // address: Yup.string().min(3, 'Please enter at 3 least characters!').max(250, 'Should not be more than 250 characters!').required('Please enter address'),
    // minOrderAmount: Yup.number().required('Please minimum order amount'),
    // password: Yup.string().min(6, 'Too short!').max(20, 'Too long!').required('Please enter password'),
    //preparationTime:Yup.string().required('Please enter preparation time')
  });
  


  // const getCuisines = async() => {
  //   try {
  //     const res = await helper.get(apiPath.cuisinesList,{},session);
  //     if (res.status === 200) {
  //       if (!res.data.success) {
  //         return (<Navigate to="/dashboard/companies" />)
  //       }
  //       else {

  //         setCuisines(res.data.results)

  //         console.log("res.data.results", res.data.results)            
  //       }

  //       console.log("working")
  //     }
  //   } catch (e) {
  //     setErrorResponse('')
  //     setTimeout(function () { setErrorResponse('') }, 5000)
  //     console.log(e);
  //   }
  // }


  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }
  const handleLicence = (fileUploaded) => {
    console.log("fileUploadedpdf", fileUploaded)
    setLicence(fileUploaded)
  }


  useEffect(() => {
    getcompanyData()
    // getCuisines()
  },[]);


  const formik = useFormik({
  
    initialValues: {
      name: companyData.name??'',
      contactNo: companyData.contactNo??'',
      contactName: companyData.contactName??'',
      email: companyData.email??'',
      // address: companyData.address??'',
      // cuisines: companyData.cuisines,
      // minOrderAmount: companyData.minOrderAmount,
      //preparationTime:companyData.preparationTime,
      password: ''
    },
    enableReinitialize: true,

    validationSchema: RegisterSchema,
    onSubmit: async (values, { setSubmitting }) => {

      // console.log("selectedCuisines",selectedCuisines)
      // values['cuisines'] = selectedCuisines
      values['openingHours'] = days
      values['image'] = image
      values['licence'] = licence
      values['isActive'] = isActive
      values['address'] = address
      values['lat'] = lat
      values['lng'] = lng

      console.log("values", values)

      handleToggle()
      try {
        const res = await helper.put(values, apiPath.updateCompany+ '/' + _id,session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg,{variant});
            
          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg,{variant});
            navigate('/dashboard/companies', { replace: true });
            
            console.log("res.data.results", res.data.results)            
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });


  const h1tag = {
    color:"red"
  } 

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
    {(companyData) && 
    <FormikProvider value={formik}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {(errorResponse) && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{ width: '100%', my: 2, py: 2 }}>

              {/*<Avatar
              src={imagePreview}
              sx={{
                height: 100,
                width: 100
              }}
            />*/}

              <FileUploader imageName={companyData.image} handleFile={handleFile} folder="company" />


            </FormControl>
          </Stack>
        </Stack>

        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Company name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              label="Phone"
              {...getFieldProps('contactNo')}
              error={Boolean(touched.contactNo && errors.contactNo)}
              helperText={touched.contactNo && errors.contactNo}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="Contact Name"
              type="text"
              label="Contact Name"
              {...getFieldProps('contactName')}
              error={Boolean(touched.contactName && errors.contactName)}
              helperText={touched.contactName && errors.contactName}
            />

          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            {/* <TextField
              fullWidth
              label="Address"
              {...getFieldProps('address')}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            /> */}
                <TextField
                  fullWidth
                  label="Address"
                  onClick={() => { setModal(true) }}
                  value = {address}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />
                <input
                type="hidden"
                  
                  name="lat"
                  value={lat}
                />
                <input
                type="hidden"
                  
                  name="lng"
                  value={lng}
                />
                <Map
                  lat={lat}
                  setLat={setLat}
                  lng={lng}
                  setLng={setLng}
                  address={address}
                  setAddress={setAddress}
                  modal={modal}
                  setModal={setModal}
                />
              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

            {/*<ClockPick name="open" label="Open Time" />
            <ClockPick name="close" label="Close Time" />
  */}

            <FormControl >
              <WorkingHours DAYS={days} handleDateChange={handleDateChange} />
            </FormControl>

            <FileUploaderAll id="fileupload" fileName={companyData.licence} handleFile={handleLicence} folder={'companylicence'} />

          </Stack>

          <LoadingButton
            sx={{ width: '200px' }}
            size="large"
            type="submit"
            variant="contained"
            sx = {{background:'#0C0CFA',width:"20%"}}
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
    }
    </>
  );
}
