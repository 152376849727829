import React, { useEffect } from 'react';
import {
  GoogleMap,
  useJsApiLoader,
  useLoadScript,
  LoadScript,
  Autocomplete,
  Marker,
  InfoWindow
} from '@react-google-maps/api';
import Modal from '@mui/material/Modal';
import mapMarker from '../../marker.svg';
import Geocode from 'react-geocode'; /* 
import { Formik, Form } from "formik";
import FormikControl from "../../components/Formik/FormikControl"; */
import * as Yup from 'yup';
import Label from '../Label';
import { Stack, TextField, FormControl, Fab, IconButton, Box, Button, InputAdornment, Avatar, Card, Table, TableRow, TableBody, TableCell, Container, Typography, TableContainer, TablePagination } from '@material-ui/core';


//import Autocomplete from "react-google-autocomplete";
// import ScaleLoader from 'react-spinners/ScaleLoader';
import { css } from '@emotion/react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
// import { ValidatorForm } from 'react-form-validator-core';

import '../map/map_modal.css';

const containerStyle = {
  
  height: '500px'
};

Geocode.setApiKey('AIzaSyB5EY6eaMSuDV_wREo6bUWKVrczpOmQPw4');

export default function MyComponent(props) {
  const override = css`
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-color: red;
  `;

  const [map, setMap] = React.useState(null);

  const [address, setAddress] = React.useState(
    props.address ? props.address : ''
  );
  const [area, setArea] = React.useState('');
  const [places, setPlaces] = React.useState('');
  const [latitude, setLatitude] = React.useState(26.2343533);
  const [longitude, setLongitude] = React.useState(75.545555);
  const [autocomplete, setAutocomplete] = React.useState('');


  // const { isLoaded } = useLoadScript({
  //   id: 'google-map-script',
  //   libraries: ["places"],
  //   googleMapsApiKey: "AIzaSyB5EY6eaMSuDV_wREo6bUWKVrczpOmQPw4"
  // })

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const autocompleteonLoad = (autocomplete) => {

    setAutocomplete(autocomplete)

  }

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const onMapDragEnd = () => {
    let newLat = map.getCenter().lat();
    let newLng = map.getCenter().lng();

    Geocode.fromLatLng(newLat, newLng).then((response) => {
      //const address = response.results[0].formatted_address,
      const addressArray = response.results[0].address_components,
        area = getArea(addressArray);


      let addressObj = []
      for (let addressLevel of addressArray) {


        console.log("addressLevel", addressLevel)

        if (addressLevel.types.includes("premise") || addressLevel.types.includes("route") || addressLevel.types.includes("street_number") || addressLevel.types.includes("plus_code") || (addressLevel.types.includes("street_number") && !addressLevel.types.includes("premise")) || addressLevel.types.includes("sublocality_level_1") || addressLevel.types.includes("locality") || addressLevel.types.includes("postal_code")) {
          addressObj.push(addressLevel.short_name)
        }
        //console.log(addressLevel.types.includes("sublocality_level_2"))
      }
      let address = addressObj.join(", ")


      setLatitude(newLat);
      setLongitude(newLng);

      props.setLat(newLat);
      props.setLng(newLng);
      props.setArea && props.setArea(area);
      props.setAddress && props.setAddress(address);
      setAddress(address);
      props.setFieldValue('address', address)
      setArea(area);
      setPlaces(response.results[0])
    });
  };

  const success = (pos) => {
    var crd = pos.coords;
    console.log("ninfdhbfb", crd)
    if (!props.lat) {
      console.log("nikita", crd)

      let newLat = crd.latitude;
      let newLng = crd.longitude;
      Geocode.fromLatLng(newLat, newLng).then((response) => {
        //const address = response.results[0].formatted_address,
        const addressArray = response.results[0].address_components,
          area = getArea(addressArray);

        let addressObj = []
        for (let addressLevel of addressArray) {


          console.log("addressLevel", addressLevel)

          if (addressLevel.types.includes("premise") || addressLevel.types.includes("route") || addressLevel.types.includes("street_number") || addressLevel.types.includes("plus_code") || (addressLevel.types.includes("street_number") && !addressLevel.types.includes("premise")) || addressLevel.types.includes("sublocality_level_1") || addressLevel.types.includes("locality") || addressLevel.types.includes("postal_code")) {
            addressObj.push(addressLevel.short_name)
          }
          //console.log(addressLevel.types.includes("sublocality_level_2"))
        }
        let address = addressObj.join(", ")

        // props.setLat(newLat);
        setLatitude(newLat);
        // props.setLng(newLng);
        setLongitude(newLng);
        setArea(area);
        // props.setArea && props.setArea(area);
        // props.setAddress && props.setAddress(address);
        setAddress(address);
        props.setFieldValue('address', address)
        setPlaces(response.results[0])
        // props.setAddress(address);
      });
    }
  };

  const error = (newLat = 26.8634705, newLng = 75.81852) => {
    Geocode.fromLatLng(newLat, newLng).then((response) => {
      // const address = response.results[0].formatted_address,
      const addressArray = response.results[0].address_components,
        area = getArea(addressArray);

      let addressObj = []
      for (let addressLevel of addressArray) {


        console.log("addressLevel", addressLevel)

        if (addressLevel.types.includes("premise") || addressLevel.types.includes("route") || addressLevel.types.includes("street_number") || addressLevel.types.includes("plus_code") || (addressLevel.types.includes("street_number") && !addressLevel.types.includes("premise")) || addressLevel.types.includes("sublocality_level_1") || addressLevel.types.includes("locality") || addressLevel.types.includes("postal_code")) {
          addressObj.push(addressLevel.short_name)
        }
        //console.log(addressLevel.types.includes("sublocality_level_2"))
      }
      let address = addressObj.join(", ")

      props.setLat(newLat);
      setLatitude(newLat);
      props.setLng(newLng);
      setLongitude(newLng);
      setArea(area);
      props.setArea && props.setArea(area);
      props.setAddress && props.setAddress(address);
      setAddress(address);
      props.setFieldValue('address', address)
      setPlaces(response.results[0])
    });
  };
  const getArea = (addressArray) => {
    let area = '';
    for (let index = 0; index < addressArray.length; index++) {
      if (addressArray[index].types[0]) {
        for (let j = 0; j < addressArray.length; j++) {
          if (
            'sublocality_level_1' === addressArray[index].types[j] ||
            'locality' === addressArray[index].types[j]
          ) {
            area = addressArray[index].long_name;
            return area;
          }
        }
      }
    }
  };
  const getTrimedAddress = () => {
    let add = address.includes('+')
      ? address.substring(address.indexOf(',') + 2)
      : address;
    add = add.length > 50 ? add.substring(0, 48).concat(' . .') : add;
    return add.toUpperCase();
  };

  useEffect(() => {
    console.log("first")
    navigator.geolocation.getCurrentPosition(success, error);
  }, []);

  useEffect(() => {
    console.log("rere")
    if (!props.area && props.lat && props.lng) {
      console.log("seconduseEffect")
      Geocode.fromLatLng(props.lat, props.lng).then((response) => {
        console.log("thirdEffect", response)
        //const address = response.results[0].formatted_address,
        const addressArray = response.results[0].address_components,
          area = getArea(addressArray);

        let addressObj = []
        for (let addressLevel of addressArray) {


          console.log("addressLevel", addressLevel)

          if (addressLevel.types.includes("premise") || addressLevel.types.includes("route") || addressLevel.types.includes("street_number") || addressLevel.types.includes("plus_code") || (addressLevel.types.includes("street_number") && !addressLevel.types.includes("premise")) || addressLevel.types.includes("sublocality_level_1") || addressLevel.types.includes("locality") || addressLevel.types.includes("postal_code")) {
            addressObj.push(addressLevel.short_name)
          }
          //console.log(addressLevel.types.includes("sublocality_level_2"))
        }
        let address = addressObj.join(", ")

        setPlaces(response.results[0])

        setAddress(address)
        props.setFieldValue('address', props.address)
        // props.setAddress(props.address)
        setLatitude(props.lat)
        // props.setLat(props.lat)
        setLongitude(props.lng)
        // props.setLng(props.lng)
        // getTrimedAddress()
        setArea(area);
      });
    }


    console.log("{ lat: latitude, lng: longitude }", { lat: latitude, lng: longitude })


  }, [props.lat, props.lng, props.address]);


  const onPlaceChanged = async () => {
    if (autocomplete !== null) {

      let value = autocomplete.getPlace()

      const results = await geocodeByAddress(value.formatted_address);
      const latLng = await getLatLng(results[0]);
      console.log("latLng", (latLng))
      setPlaces(value);
      error(latLng.lat, latLng.lng);

      console.log("onPlaceChanged", autocomplete.getPlace())
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  const handleSelect = async (value) => {

    console.log("value", value)
    return;

    const results = await geocodeByAddress(value.formatted_address);
    const latLng = await getLatLng(results[0]);
    console.log("latLng", (latLng))
    setPlaces(value);
    error(latLng.lat, latLng.lng);
  }; console.log("propsprops", props)

  return (
    <Modal
      sx={{ margin: "010px 100px" }}
      open={props.modal}
      onClose={() => props.setModal(false)}>
      <Box>
        <Card className='px-20'>
          <Stack spacing={3}>
            <Box >
              <Typography sx={{ 'marginTop': '10px' }} id="transition-modal-title" variant="h6" component="h2">
                <div className={'modal-title mx-5'}>Drag the marker to your area</div>
                <div>
                  {/* <i onClick={() => props.setModal(false)} className="fas fa-times" /> */}
                </div>
              </Typography>
            </Box>
            {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
          <FormControl sx={{ width: '100%' }}>
            <Autocomplete
                sx={{ width: '100%' }}
                apiKey="AIzaSyB5EY6eaMSuDV_wREo6bUWKVrczpOmQPw4"
                options={{"types":[]}}
                onPlaceSelected={(place) => handleSelect(place)}
                className='mx-10 MuiOutlinedInput-input MuiInputBase-input css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input'
              /> </FormControl>
            </Stack > */}


            <FormControl >



              {/* {isLoaded ? (<> */}
                <LoadScript
        googleMapsApiKey="AIzaSyB5EY6eaMSuDV_wREo6bUWKVrczpOmQPw4"
        libraries={["places"]}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                  <FormControl sx={{ width: '95%', margin:'0px 0px 20px 0px' }}>
                    <Autocomplete
                      sx={{ width: '90%' }}
                      //className='mx-10 MuiOutlinedInput-input MuiInputBase-input css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input'
                      onLoad={autocompleteonLoad}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <input
                        type="text"
                        className='mx-10 MuiOutlinedInput-input MuiInputBase-input css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input'
                        placeholder="Place"
                        style={{width: '100%'}}
                      />
                    </Autocomplete></FormControl>
                </Stack >

                <GoogleMap
                  mapContainerStyle={containerStyle}
                  zoom={18}
                  center={{ lat: latitude, lng: longitude }}
                  position={{ lat: latitude, lng: longitude }}
                  zoomControl={true}
                  onLoad={onLoad}
                  //onUnmount={onUnmount}
                  onDragEnd={onMapDragEnd}
                  options={{
                    fullscreenControl: false
                  }}>
                  <img alt={''} src="/static/location_pin.png" style={{ width: "30px" }} className="map-marker" />
                </GoogleMap>


                </LoadScript>
              {/* </>) : <></>
              } */}

              {/* {isLoaded ? (<GoogleMap
            mapContainerStyle={containerStyle}
            zoom={18}
            center={{ lat: latitude, lng: longitude }}
            position={{ lat: latitude, lng: longitude }}
            zoomControl={true}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onDragEnd={onMapDragEnd}
            options={{
              fullscreenControl: false
            }}>
            <img alt={''} src="/static/location_pin.png" style={{width:"30px"}} className="map-marker" />
          </GoogleMap>) : <></>
} */}
            </FormControl>
            {/* </ModalBody> */}
            <div className="map_address">
              <img src="/marker.png" height={30} alt={''} /> {getTrimedAddress()}
            </div>

            {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  label="Area"
                  name="area"
                  placeholder="area"
                  value={area}
                />
              </Stack> */}

            {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  label="Latitude"
                  name="latitude"
                  placeholder=""
                  value={latitude}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  label="Longitude"
                  name="longitude"
                  placeholder=""
                  value={longitude}
                />
              </Stack> */}
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

              <Button sx={{ width: '200px' }}
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  margin: '0px auto 20px auto',
                  height: '50px',
                  width: '131px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 850,
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  padding: '17px',
                  marginRight: '9px'
                }} onClick={() => props.setModal(false)}>
                OK
              </Button>
            </Stack>

          </Stack>
        </Card>
      </Box>
    </Modal>
  )
}
