import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import helper from '../constants/helper';
import apiPath from '../constants/apiPath';
import useSession from 'react-session-hook';
import { LoadingButton } from '@material-ui/lab';

import { useFormik, Form, FormikProvider } from 'formik';

export default function FormDialog(props) {
  const session = useSession();
  const OrderCancelSchema = Yup.object().shape({
    reason: Yup.string()
      .min(3, 'Type 3 char atleast')
      .max(255, 'Type 255 max char')
      .required('Please type a enter settlement amount')
  });

  const formik = useFormik({
    initialValues: {
      reason: ''
    },
    validationSchema: OrderCancelSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log('values', values);
      try {
        const res = await helper.put(
          {
            restaurantId: props.restaurantId[0]._id,
            cancellationReason: values.reason,
            status: 'cancelled'
          },
          `${apiPath.adminOrderStatusUpdate}/${props.orderId}`,
          session
        );
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error';
          } else {
            props.closeCancelOrderDialog(false);
            props.orderCancelSuccess(true);
          }
        }
      } catch (e) {
        console.log(e);
      }
      console.log('values', values);
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog
        fullWidth="xl"
        open={props.openCancelOrderDialog}
        onClose={() => props.closeCancelOrderDialog(false)}
      >
        <DialogTitle>Cancel Order</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <DialogContent>
              {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
              <TextField
                margin="dense"
                id="name"
                label="Type a reason for cancellation"
                type="text"
                {...getFieldProps('reason')}
                error={Boolean(touched.reason && errors.reason)}
                helperText={touched.reason && errors.reason}
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => props.closeCancelOrderDialog(false)}>Cancel</Button>
              {/* <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  height: '50px',
                  width: '150px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 850,
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  padding: '17px',
                  marginRight: '9px'
                }}
                loading={isSubmitting}
              >
                Submit
              </LoadingButton> */}
              <Button type="submit" color="error" loading={isSubmitting}>
                Cancel Order
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
