import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// material
import {
  Stack,
  TextField,
  Fab,
  IconButton,
  Box,
  Button,
  InputAdornment,
  Avatar
} from '@material-ui/core';
import {
  FormControl,
  Select,
  Chip,
  OutlinedInput,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from '../../components/ErrorResponse';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

export default function EditForm() {
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const { name, username, phone, email, password } = helper.parseJwt(session.token);

  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [errorResponse, setErrorResponse] = useState('');
  const [userData, setUserData] = useState(helper.parseJwt(session.token));
  console.log('userData', userData);

  const getUserData = async () => {
    //handleToggle()
    let body = {};

    try {
      const res = await helper.get(apiPath.adminUserData + '/', body, session);
      if (res.status === 200) {
        setUserData(res.data.results);
        //handleToggle()
      }
    } catch (e) {
      console.log(e);
    }
  };

  console.log('profile', session.profile);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const UserSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too short!')
      .max(50, 'Too long!')
      .required('Please enter user name')
      .matches(/^[a-zA-Z\s]+$/, 'Must be alphabets'),
    phone: Yup.number()
      .min(1000000000, 'Too short!')
      .max(9999999999, 'Too long!')
      .required('Please enter phone number'),
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Please enter email address')
    // google_id: Yup.number().required('Please enter google ID!!'),
    // password: Yup.string().min(6, 'Too short!').max(20, 'Too long!').required('Please enter password'),
    //:Yup.string().required('Please enter preparation time')
  });

  const handleFile = (fileUploaded) => {
    console.log('fileUploaded', fileUploaded);
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded);
  };

  // useEffect(() => {
  //   getUserData()
  // },[]);

  const formik = useFormik({
    initialValues: {
      name: userData.name ?? '',
      username: userData.username ?? '',
      phone: userData.phone ?? '',
      email: userData.email ?? '',
      password: ''
    },
    enableReinitialize: true,

    validationSchema: UserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      values['image'] = image;

      console.log('values', values);

      handleToggle();
      try {
        const res = await helper.post(values, apiPath.updateProfile, session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error';
            enqueueSnackbar(res.data.msg, { variant });
          } else {
            var variant = 'success';
            enqueueSnackbar(res.data.msg, { variant });
            session.setSession({ token: res.data.results, path: '/dashboard' });

            //navigate('/dashboard/profile', { replace: true });

            // console.log("res.data.results", res.data.results)
          }

          console.log('working');
        }
      } catch (e) {
        setErrorResponse('');
        setTimeout(function () {
          setErrorResponse('');
        }, 5000);
        console.log(e);
      }
      handleClose();
      console.log('values', values);
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });

  const h1tag = {
    color: 'red'
  };

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {errorResponse && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit} className="edit-form-allinput">
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{ width: '100%', my: 2, py: 2 }}>
              {/*<Avatar
              src={imagePreview}
              sx={{
                height: 100,
                width: 100
              }}
            />*/}

              <FileUploader imageName={userData.image} handleFile={handleFile} folder="admin" />
            </FormControl>
          </Stack>
        </Stack>

        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              label="User Name"
              {...getFieldProps('username')}
              disabled
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Phone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />

            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>
          <Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
          <LoadingButton
                sx={{ width: '200px' }}
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  height: '50px',
                  width: '131px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 850,
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  padding: '17px',
                  marginRight: '9px'
                }}
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>

          <LoadingButton
                sx={{ width: '200px' }}
                size="large"
                type="button"
                variant="contained"
                sx={{
                  height: '50px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 850,
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  padding: '17px',
                  background: '#E4E4E4',
                  color: '#000',
                  width: '131px'
                }}
                loading={isSubmitting}
                href="/dashboard"
              >
                Cancel
              </LoadingButton>

          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
