import * as Yup from 'yup';
import { useState,useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Editor } from '@tinymce/tinymce-react';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";

import ReactDOM from "react-dom";
// import { createTheme, ThemeProvider } from '@mui/material/styles'
// import MUIRichTextEditor from 'mui-rte'

// ----------------------------------------------------------------------

export default function AddForm() {
  const navigate = useNavigate();
  const session = useSession();

  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [activeInactive, setActiveInactive] = useState(false);
  const [contentValue, setContentValue] = useState('');
  const [possibleTypes, setPossibleTypes] = useState([
    { name: 'percent'},
    { name: 'fixed' } 
  ]);
  const [selectedType, setSelectedType] = useState({ name: 'percent'});


  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const [days, setDays] = useState([
    {
      day: "sunday",
      label: "Sun",
      openingTime: new Date(),
      closingTime: new Date()
    },
    {
      day: "monday",
      label: "Mon",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "tuesday",
      label: "Tue",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "wednesday",
      label: "Wed",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "thursday",
      label: "Thu",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "friday",
      label: "Fri",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    },
    {
      day: "saturday",
      label: "Sat",
      openingTime: new Date('2018-01-01T00:00:00.000Z'),
      closingTime: new Date('2018-01-01T00:00:00.000Z')
    }
  ])

  //const [selectedDate, handleDateChange] = useState(new Date('2018-01-01T00:00:00.000Z')); 

  const handleDateChange = (time, day, hours) => {

    console.log("time", time, day)


    console.log("eeeee", days.map((item) => {
      if (day === item['day'])
        item[hours] = time;
      return item;
    }))


    setDays(days.map((item) => {
      if (day === item['day'])
        item[hours] = time;
      return item
    }))

  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Please enter at least 2 characters!')
      .max(50, 'Should not be more than 50 characters!')
      .required('Please enter page name'),
    slug: Yup.string().required('Please enter slug'),
    title: Yup.string().required('Please enter title'),
    // content: Yup.string().min(3, 'Please enter at least 3 characters!').max(250, 'Should not be more than 250 characters!').required('Please enter content'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      slug: '',
      title: '',
      // content: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setSubmitting }) => {

      values['image'] = image
      values['isActive'] = activeInactive
      values['content'] = {contentValue}.contentValue

      console.log("values", values)

      handleToggle()
      try {
        const res = await helper.post(values, apiPath.addCMSPage, session);
        if (res.status === 200) {
          if (!res.data.success) {
            navigate('/dashboard/cmspages', { replace: true });
            
          }
          else {
            navigate('/dashboard/cmspages', { replace: true });
            
            console.log("res.data.results", res.data.results)            
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });


  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }




  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {(errorResponse) && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {/* <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{ width: '100%', my: 2, py: 2 }}>

              {/*<Avatar
              src={imagePreview}
              sx={{
                height: 100,
                width: 100
              }}
            />* /}

              <FileUploader handleFile={handleFile}  folder={'cmspages'}/>


            </FormControl>
          </Stack>
        </Stack> */}

        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="CMSPage name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
{/* 
            <TextField
              fullWidth
              label="Slug"
              {...getFieldProps('slug')}
              error={Boolean(touched.slug && errors.slug)}
              helperText={touched.slug && errors.slug}
            />

          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>  */}

            <TextField
              fullWidth
              label="Title"
              {...getFieldProps('title')}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
            {/* <FormControlLabel
            fullWidth
              control={
                <Checkbox checked={activeInactive} onChange={(event, newValue) => {
                  setActiveInactive(newValue);
                }}/>
              }
              label="Active"
            /> */}
            </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> 

            {/* <TextField
                fullWidth
                label="Content"
                {...getFieldProps('content')}
                error={Boolean(touched.content && errors.content)}
                helperText={touched.content && errors.content}
              /> */}

              <>
                <Stack> 
                  Content 
                </Stack>
                <Stack> 
                  <Editor
                    apiKey="3c04ulhfa3529md1hsczqshoiwhfiq0zwgw09e6dyo2uul9t"
                    onInit={(evt, editor) => editorRef.current = editor}
                    value={contentValue}
                    onEditorChange={(newcontentValue, editor) => {setContentValue(newcontentValue);console.log("newcontentValue",newcontentValue)}}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                    />
                </Stack>
              </>

              
            {/* <MUIRichTextEditor label="Start typing..." /> */}
            </Stack>

          <LoadingButton
            sx={{ width: '200px' }}
            size="large"
            type="submit"
            variant="contained"
            sx = {{background:'#0C0CFA',width:"20%"}}
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
