import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Contacts from './pages/Contacts';
import Products from './pages/Products';
import Cuisine from './pages/Cuisine';
import Category from './pages/Category';
import Location from './pages/Location';
import AddServeArea from './pages/AddServeArea';
import EditServeArea from './pages/EditServeArea';
import AddGroup from './pages/AddGroup';
import EditGroup from './pages/EditGroup';
import Menu from './pages/Menu';
import Groups from './pages/Groups';
import Earning from './pages/Earning';
import ViewEarnings from './pages/ViewEarnings';
import ParcelEarning from './pages/ParcelEarning';
import Order from './pages/Order';
import Booking from './pages/Booking';
import ViewOrder from './pages/ViewOrder';
import User from './pages/User';
import EditUser from './pages/EditUser';
import ChangePassword from './pages/ChangePassword';
import Profile from './pages/Profile';
import Notifications from './pages/Notifications';
import Subadmin from './pages/Subadmin';
import AddSubadmin from './pages/AddSubadmin';
import EditSubadmin from './pages/EditSubadmin';
import Restaurant from './pages/Restaurant';
import Branch from './pages/Branch';
import AddRestaurant from './pages/AddRestaurant';
import EditRestaurant from './pages/EditRestaurant';
import AddBranch from './pages/AddBranch';
import EditBranch from './pages/EditBranch';
import Promocode from './pages/Promocode';
import AddPromocode from './pages/AddPromocode';
import EditPromocode from './pages/EditPromocode';
import Review from './pages/Review';
import Logs from './pages/Logs';
import ForgotPassword from './pages/ForgotPassword';
import UpdatePassword from './pages/UpdatePassword';
import EmailAuth from './pages/EmailAuth';
// import AddReview from './pages/AddReview';
// import EditReview from './pages/EditReview';
import CMSPage from './pages/CMSPage';
import AddCMSPage from './pages/AddCMSPage';
import EditCMSPage from './pages/EditCMSPage';
import Driver from './pages/Driver';
import AddDriver from './pages/AddDriver';
import EditDriver from './pages/EditDriver';
import Company from './pages/Company';
import AddCompany from './pages/AddCompany';
import EditCompany from './pages/EditCompany';
import AddCuisine from './pages/AddCuisine';
import EditCuisine from './pages/EditCuisine';
import AddCategory from './pages/AddCategory';
import EditCategory from './pages/EditCategory';
import AddLocation from './pages/AddLocation';
import EditLocation from './pages/EditLocation';
import AddMenu from './pages/AddMenu';
import EditMenu from './pages/EditMenu';
import NotFound from './pages/Page404';
import Faqs from './pages/Faqs';
import AddFaqs from './pages/AddFaqs';
import EditFaqs from './pages/EditFaqs';
import AboutUs from './pages/AboutUs';
import ViewManageSelection from './pages/ViewManageSelection';
import AddSelectionData from './pages/AddSelectionData';
import EditSelectionData from './pages/EditSelectionData';
import CompanyDriver from './pages/CompanyDriver';
import Vehicle from './pages/Vehicle';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'contact_us', element: <Contacts /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'faqs/add', element: <AddFaqs /> },
        { path: 'faqs/edit/:id', element: <EditFaqs /> },
        { path: 'customers', element: <User /> },
        { path: 'groups', element: <Groups /> },
        { path: 'customers/edit/:id', element: <EditUser /> },
        { path: 'change_password', element: <ChangePassword /> },
        { path: 'profile', element: <Profile /> },
        { path: 'cuisines', element: <Cuisine /> },
        { path: 'categories', element: <Category /> },
        { path: 'locations', element: <Location /> },
        { path: 'servearea/:type', element: <AddServeArea /> },
        { path: 'servearea/:type/:objectId', element: <EditServeArea /> },
        { path: 'logs', element: <Logs /> },
        { path: 'earnings', element: <Earning /> },
        { path: 'view-earnings', element: <ViewEarnings /> },
        // { path: 'earnings/parcel', element: <ParcelEarning /> },
        { path: 'orders', element: <Order /> },
        { path: 'bookings', element: <Booking /> },
        { path: 'manage-selection-data', element: <ViewManageSelection /> },
        { path: 'manage-selection-data/add', element: <AddSelectionData /> },
        { path: 'manage-selection-data/edit/:id', element: <EditSelectionData /> },
        { path: 'view_order/:id', element: <ViewOrder /> },
        { path: 'menus', element: <Menu /> },
        { path: 'restaurants', element: <Restaurant /> },
        { path: 'restaurants/add', element: <AddRestaurant /> },
        { path: 'restaurants/edit/:id', element: <EditRestaurant /> },
        { path: 'add_branch/:resid', element: <AddBranch /> },
        { path: 'edit_branch/:resid/:id', element: <EditBranch /> },
        { path: 'promocodes', element: <Promocode /> },
        { path: 'add_promocode', element: <AddPromocode /> },
        { path: 'edit_promocode/:id', element: <EditPromocode /> },
        { path: 'add_group', element: <AddGroup /> },
        { path: 'edit_group/:id', element: <EditGroup /> },
        { path: 'reviews', element: <Review /> },
        { path: 'branches/:id', element: <Branch /> },
        // { path: 'add_review', element: <AddReview /> },
        // { path: 'edit_review/:id', element: <EditReview /> },
        { path: 'cmspages', element: <CMSPage /> },
        { path: 'add_cmspage', element: <AddCMSPage /> },
        { path: 'edit_cmspage/:id', element: <EditCMSPage /> },
        { path: 'companies', element: <Company /> },
        { path: 'add_company', element: <AddCompany /> },
        { path: 'edit_company/:id', element: <EditCompany /> },
        { path: 'drivers', element: <Driver /> },

        { path: 'company/drivers/:companyId', element: <CompanyDriver /> },
        { path: 'company/vehicle/:companyId', element: <Vehicle /> },

        { path: 'drivers/add', element: <AddDriver /> },
        { path: 'drivers/edit/:id', element: <EditDriver /> },
        { path: 'subadmins', element: <Subadmin /> },
        { path: 'subadmins/add', element: <AddSubadmin /> },
        { path: 'subadmins/edit/:id', element: <EditSubadmin /> },
        { path: 'cuisines/add', element: <AddCuisine /> },
        { path: 'cuisines/edit/:id', element: <EditCuisine /> },
        { path: 'categories/add', element: <AddCategory /> },
        { path: 'categories/edit/:id', element: <EditCategory /> },
        { path: 'add_location', element: <AddLocation /> },
        { path: 'edit_location/:id', element: <EditLocation /> },
        { path: 'menus/add', element: <AddMenu /> },
        { path: 'menus/edit/:id', element: <EditMenu /> },
        { path: 'products', element: <Products /> },
        { path: 'notifications', element: <Notifications /> },
        { path: 'about_us', element: <AboutUs /> }
        // { path: 'contact_us', element: <ContactUs /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'forgot_password', element: <ForgotPassword /> },
        { path: 'email_auth/:id', element: <EmailAuth /> },
        { path: 'update_password/:id', element: <UpdatePassword /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
