import React from 'react';
import styled from 'styled-components';
import { Fab, IconButton} from '@material-ui/core';
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";


// Style the Button component
const Button = styled.button`
  /* Insert your favorite CSS code to style a button */
`;
export default function FileUploader(props) {

  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    props.handleFile(fileUploaded);
  };
  return (
    <>
        <Fab component="span" onClick={handleClick}>
            <AddPhotoAlternateIcon />
        </Fab>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{display: 'none'}}
      />
    </>
  )
}