import * as Yup from 'yup';
import { useState,useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Editor } from '@tinymce/tinymce-react';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

export default function EditForm() {
  // let _id = '61600afbc9f28652b4411a4f';
  // let _id = '615d63d3706f5c27846e33fa';
  const _id = useParams().id;
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [cmspageData, setCMSPageData] = useState({});
  const [contentValue, setContentValue] = useState('');
  const [activeInactive, setActiveInactive] = useState(false);
  const [possibleTypes, setPossibleTypes] = useState([
    { name: 'percent'},
    { name: 'fixed' } 
  ]);
  const [selectedType, setSelectedType] = useState({ name: 'percent'});

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      setContentValue(editorRef.current.getContent());
    }
  };

  const getcmspageData = async()=>{
    //handleToggle()
    let body={};
    try{
        const res = await helper.get(apiPath.adminCMSPageData+ '/' + _id,body, session);
        if(res.status===200) {
          setCMSPageData(res.data.results)
          setActiveInactive(res.data.results.isActive)
          setContentValue(res.data.results.content)
          //handleToggle()
        }
        
    } catch (e) {
        console.log(e);
    }
    
  }


  //const [selectedDate, handleDateChange] = useState(new Date('2018-01-01T00:00:00.000Z')); 

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Please enter at least 2 characters!')
      .max(50, 'Should not be more than 50 characters!')
      .required('Please enter page name'),
    slug: Yup.string().required('Please enter slug'),
    title: Yup.string().required('Please enter title'),
    // content: Yup.string().min(3, 'Please enter at least 3 characters!').max(250, 'Should not be more than 250 characters!').required('Please enter content'),
  });

  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }

  const handleContentValueChange = (val) => {console.log("val",val)
    setContentValue(val)
  }

  useEffect(() => {
    getcmspageData()
  },[]);


  const formik = useFormik({
  
    initialValues: {
      name: cmspageData.name??'',
      slug: cmspageData.slug??'',
      title: cmspageData.title??'',
      // content: cmspageData.content,
    },
    enableReinitialize: true,

    validationSchema: RegisterSchema,
    onSubmit: async (values, { setSubmitting }) => {
      // {log}
      values['image'] = image
      values['content'] = {contentValue}.contentValue
      console.log("values", values)

      handleToggle()
      try {
        const res = await helper.put(values, apiPath.updateCMSPage+ '/' + _id,session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg,{variant});
            
          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg,{variant});
            navigate('/dashboard/cmspages', { replace: true });
            
            console.log("res.data.results", res.data.results)            
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });


  const h1tag = {
    color:"red"
  } 

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
    {(cmspageData) && 
    <FormikProvider value={formik}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {(errorResponse) && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} className="edit-form-allinput">
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Stack sx={{width:'50%'}} spacing={2}>
            <TextField
              fullWidth
              label="Title"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
</Stack>
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> 
          <Stack sx={{width:'100%'}} direction={{ xs: 'column', sm: 'row' }} spacing={2}> 
                  Content 
                </Stack>
                </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> 

            {/* <TextField
                fullWidth
                label="Content"
                {...getFieldProps('content')}
                error={Boolean(touched.content && errors.content)}
                helperText={touched.content && errors.content}
              /> */}
              <>
                
                <Stack > 
                  <Editor
                    apiKey="3c04ulhfa3529md1hsczqshoiwhfiq0zwgw09e6dyo2uul9t"
                    onInit={(evt, editor) => editorRef.current = editor}
                    value={contentValue}
                    onEditorChange={(newcontentValue, editor) => {setContentValue(newcontentValue);}}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                    />
                  </Stack>
              </>
            </Stack>
            <Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
            <LoadingButton
              sx={{ width: '200px' }}
              size="large"
              type="submit"
              variant="contained"
              sx={{
                height: '50px',
                width: '131px',
                borderRadius: '20px',
                boxShadow: 'none',
                fontWeight: 850,
                fontSize: '16px',
                fontFamily: 'Avenir',
                padding: '17px',
                marginRight: '9px'
              }}
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>

            <LoadingButton
              sx={{ width: '200px' }}
              size="large"
              type="button"
              variant="contained"
              sx={{
                height: '50px',
                borderRadius: '20px',
                boxShadow: 'none',
                fontWeight: 850,
                fontSize: '16px',
                fontFamily: 'Avenir',
                padding: '17px',
                background: '#E4E4E4',
                color: '#000',
                width: '131px'
              }}
              loading={isSubmitting}
              href="/dashboard/cmspages"
            >
              Cancel
            </LoadingButton>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
    }
    </>
  );
}
