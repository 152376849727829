import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import CustomFields from "../../components/menu/CustomFields"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { useSnackbar } from 'notistack';
import { yearsToMonths } from 'date-fns';
// ----------------------------------------------------------------------

export default function AddForm() {
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [restaurants, setRestaurants] = useState([
  ]);
  const [categories, setCategories] = useState([
  ]);
  const [nonVeg, setNonVeg] = useState(false);
  const [isCustomChange, setIsCustomChange] = useState(false);
  const [cuisines, setCuisines] = useState([
  ]);
  const [branches, setBranches] = useState([
  ]);

  const [foodType, setFoodType] = useState('');

  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
 
  const [foodTypes, setFoodTypes] = useState([{_id:'veg',"name":"Veg"},
  {_id:'nonveg',"name":"Nonveg"},
  {_id:'vegan',"name":"Vegan"}]);

  const [optionListFinal, setOptionListFinal] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const CuisineSchema = Yup.object().shape({
      name: Yup.string()
      .min(2, 'Please enter at least 2 characters!')
      .max(50, 'Should not be more than 50 characters!')
      .required('Please enter menu name'),
      restaurant:Yup.string()
      .required('Please select restaurant'),
      cuisines :Yup.array().min(1, 'Please select cuisine').required('Please select cuisine'),
      branches :Yup.array().min(1, 'Please select branches').required('Please select branches'),
      category:Yup.string()
      .required('Please select category'),
      price: Yup.number().min(1, 'Very less!').max(99999, 'Very high!').required('Please enter price in numbers'),
      ingredients: Yup.string().min(5, 'Please enter at least 5 characters!').max(600, 'Should not be more than 600 characters!').required('Please enter ingredients')
      .matches(
        /^[a-zA-Z\s]+$/,
        "Must be alphabets"
      ),
      foodType:Yup.string()
      .required('Please select food type'),
      preparationTime: Yup.number().min(1, 'Should be at least 1 min!').max(300, 'Should not be longer than 5 hours!').required('Please enter Preparation Time'),
   
  });

  const handleChange = (event) => {
    console.log("event",event.target)
    const {
      target: { value },
    } = event;
    setSelectedCuisines(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    setFieldValue('cuisines',typeof value === 'string' ? value.split(',') : value)

  };

  const handleBranchesChange = (event) => {
    console.log("event",event.target)
    const {
      target: { value },
    } = event;
    setSelectedBranches(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setFieldValue('branches',typeof value === 'string' ? value.split(',') : value)
  };

  const setOptionsFinal = (list) => {

    setOptionListFinal(list)
    console.log(optionListFinal)
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      restaurant:'',
      cuisines:[],
      branches:[],
      category:'',
      price: '',
      preparationTime: '',
      foodType: '',
      ingredients: '',
      customItem: false,
    },
    validationSchema: CuisineSchema,
    onSubmit: async (values, { setSubmitting }) => {

      console.log("selectedCuisines",selectedCuisines)

      //values['cuisines'] = selectedCuisines
      //values['branches'] = selectedBranches
      //values['restaurant'] = selectedRestaurant
      //values['category'] = selectedCategory
      //values['foodType'] = foodType
      values['image'] = image
      values['customFields'] = optionListFinal
      
      // console.log("JSON.stringify(optionList)", (optionList))

      handleToggle()
      try {
        const res = await helper.post(values, apiPath.adminMenuAdd,session);
        if (res.status === 200) {
          if (!res.data.success) {
            console.log("enqueueSnackbar")
            var variant = 'error'
            enqueueSnackbar(res.data.msg,{variant});
            
          }
          else {
            console.log("enqueueSnackbar2")
            var variant = 'success'
            enqueueSnackbar(res.data.msg,{variant});
            navigate('/dashboard/menus', { replace: true });
            
            console.log("res.data.results", res.data.results)            
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });


  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }


  const handleVegNonVeg = (checkboxval) => {
    console.log("checkboxval", checkboxval)
    setNonVeg(checkboxval)
  }


  const getCuisines = async() => {
    try {
      const res = await helper.get(apiPath.cuisinesList,{},session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/restaurants" />)
        }
        else {

          setCuisines(res.data.results)

          console.log("res.data.results", res.data.results)            
        }

        console.log("working")
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }



  const getRestaurants = async() => {
    try {
      const res = await helper.post({},apiPath.adminRestaurantList,session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/restaurants" />)
        }
        else {

          setRestaurants(res.data.results)

          console.log("res.data.results", res.data.results)            
        }

        console.log("working")
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }

  const getCategories = async() => {
    try {
      const res = await helper.post({},apiPath.admincategoryList,session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/restaurants" />)
        }
        else {

          setCategories(res.data.results)

          console.log("res.data.results", res.data.results)            
        }

        console.log("working")
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }

  const rendorBranches = async() => {
    try {
      const res = await helper.post({},apiPath.adminRestaurantBranchList + '/' + selectedRestaurant['_id'],session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/restaurants" />)
        }
        else {

          setBranches(res.data.results)

          console.log("res.data.results", res.data.results)            
        }

        console.log("working")
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }

  useEffect(() => {
    getRestaurants(),
    rendorBranches(),
    getCategories(),
    getCuisines()
  },[]);

  useEffect(() => {
    rendorBranches(selectedRestaurant)
  },[selectedRestaurant]);

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps,setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {(errorResponse) && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl sx={{ width: '40%', my: 2, py: 2 }}>

            {/*<Avatar
            src={imagePreview}
            sx={{
              height: 100,
              width: 100
            }}
          />*/}

            <FileUploader handleFile={handleFile}  folder={'menu_items'} />

          </FormControl>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Stack spacing={3} sx={{ width: '60%' }}>
        
            <Stack spacing={2}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ width: '100%' }}>
                <Autocomplete
                  id="combo-box-demo"
                  options={restaurants}
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    console.log("newValue",newValue)

                    if(newValue)
                    {
                      setSelectedRestaurant(newValue);
                      setFieldValue('restaurant',newValue._id)
                    }
                    else
                    {
                      setSelectedRestaurant({});
                      setFieldValue('restaurant',"")
                    }

                  }}
                  sx={{ width: '100%' }}
                  className="profile-input-form"
                  renderInput={(params) => <TextField {...getFieldProps('restaurant')}
                  error={Boolean(touched.restaurant && errors.restaurant)}
                  helperText={touched.restaurant && errors.restaurant} {...params} label="Restaurant" />}
                />
                <FormControl sx={{ width: '100%' }}>
                  <MultiSelect name='branches' label={'Branches'} selected={selectedBranches} options={branches} setFieldValue={setFieldValue} getFieldProps={getFieldProps} touched={touched} errors={errors} handleChange={handleBranchesChange} />
                </FormControl>
              </Stack>
            </Stack>

            <Stack spacing={2}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Menu name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />

                  <FormControl sx={{ width: '100%' }}>
                    <MultiSelect name='cuisines' label={'Cuisines'} selected={selectedCuisines} options={cuisines} setFieldValue={setFieldValue} getFieldProps={getFieldProps} touched={touched} errors={errors} helperText={touched.cuisines && errors.cuisines} handleChange={handleChange} />
                  </FormControl>
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                <TextField
                  fullWidth
                  label="Price"
                  {...getFieldProps('price')}
                  error={Boolean(touched.price && errors.price)}
                  helperText={touched.price && errors.price}
                />

                <TextField
                  fullWidth
                  label="Ingredients"
                  {...getFieldProps('ingredients')}
                  error={Boolean(touched.ingredients && errors.ingredients)}
                  helperText={touched.ingredients && errors.ingredients}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  autoComplete="Preparation Time"
                  label="Preparation Time"
                  {...getFieldProps('preparationTime')}
                  error={Boolean(touched.preparationTime && errors.preparationTime)}
                  helperText={touched.preparationTime && errors.preparationTime}
                />
                <Autocomplete
                  id="combo-box-demo2"
                  options={categories}
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    console.log("newValue",newValue)
                    if(newValue)
                    {
                      setSelectedCategory(newValue);
                      setFieldValue('category',newValue._id)
                    }
                    else
                    {
                      setSelectedCategory({});
                      setFieldValue('category',"")
                    }
                  }}
                  fullWidth
                  className="profile-input-form"
                  renderInput={(params) => <TextField {...getFieldProps('category')}
                  error={Boolean(touched.category && errors.category)}
                  helperText={touched.category && errors.category} {...params} label="Category" />}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} >
                <Autocomplete
                  id="combo-box-demo2"
                  options={foodTypes}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    console.log('newValue', newValue);
                    if(newValue)
                    {
                      setFoodType(newValue);
                      setFieldValue('foodType',newValue._id)
                    }
                    else
                    {
                      setFoodType({});
                      setFieldValue('foodType',"")
                    }

                  }}
                  sx={{ width: '50%' }}
                  className="profile-input-form"
                  renderInput={(params) => <TextField {...getFieldProps('foodType')}
                  error={Boolean(touched.foodType && errors.foodType)}
                  helperText={touched.foodType && errors.foodType} {...params} label="Food Type" />}
                />
              </Stack>
            </Stack>
            <Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
              <LoadingButton
                sx={{ width: '200px' }}
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  height: '50px',
                  width: '131px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 850,
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  padding: '17px',
                  marginRight: '9px'
                }}
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>

              <LoadingButton
                sx={{ width: '200px' }}
                size="large"
                type="button"
                variant="contained"
                sx={{
                  height: '50px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 850,
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  padding: '17px',
                  background: '#E4E4E4',
                  color: '#000',
                  width: '131px'
                }}
                loading={isSubmitting}
                href="/dashboard/menus"
              >
                Cancel
              </LoadingButton>
            </Stack>
          </Stack>
          <Stack sx={{ width: '40%' }}>
            <CustomFields setOptionsFinal={setOptionsFinal} optionList={optionListFinal}/>
          </Stack>
        </Stack>

      </Form>
    </FormikProvider>
  );
}
