import { LoadingButton as LoadingButtonTemp } from '@material-ui/lab';

import { styled } from '@material-ui/core/styles';
import '../developer.css';

const CustomizedLoadingButton = styled(LoadingButtonTemp)`
  border-radius: 20px;
  height: 50px;
  margin-top: 33px !important;
  font-size: 16px;
  box-shadow: none;
`;

export default function LoadingButton(props) {
  //const { classes, ...rest } = props;

  return <CustomizedLoadingButton {...props} />;
}
