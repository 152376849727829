import axios from 'axios';
// const API_URL = 'https://admin.octallabs.com/adminapi';
const API_URL = process.env.REACT_APP_API_BASE_URL;
//process.env.REACT_APP_API_BASE_URL
const ADMIN_ORDER_FEE = process.env.REACT_ADMIN_ORDERS_COMMISSION_FEE??20
const ADMIN_BOOKING_FEE = process.env.REACT_ADMIN_BOOKINGS_COMMISSION_FEE??20

const helper = {
  getadminorderfee: () => {
    return ADMIN_ORDER_FEE;
  },

  getadminbookingfee: () => {
    return ADMIN_BOOKING_FEE;
  },


  get: async (path = "", jsonObj = {}, session = {}) => {
    const query = await helper.serialize(jsonObj);
    const url = API_URL + path + "?" + query;
    const res = await axios(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json", "x-access-token": session.token || ''
      }
    });
    if (res.status === 401) {
      window.location = '/login';
    }
    return res;
  },
  delete: async (jsonObj = {}, path = "", session = {}) => {

    const url = API_URL + path;
    const res = await axios.delete(url,jsonObj, { headers: {"x-access-token": session.token || 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX3R5cGUiOiJzdXAiLCJwZXJtaXNzaW9ucyI6W10sIl9pZCI6IjYxNGRiMDU0Njc1ZGI0MzYxNDA2OGM0YSIsIm5hbWUiOiJ0cmlzaGEiLCJ1c2VybmFtZSI6ImFkbWluIiwicGhvbmUiOiI5MDI1NDE3ODQ1IiwiZW1haWwiOiJmb3J0QGdtYWlsLmNvbSIsImNyZWF0ZWRfYXQiOiIyMDIxLTA5LTI0VDExOjAyOjQ0Ljg4MVoiLCJ1cGRhdGVkX2F0IjoiMjAyMS0wOS0yNFQxMTowMjo0NC44ODFaIiwiX192IjowLCJpZCI6IjYxNGRiMDU0Njc1ZGI0MzYxNDA2OGM0YSIsImlhdCI6MTYzMzM0NTUzNiwiZXhwIjoxNjMzNzA1NTM2LCJhdWQiOiJtYW5pc2hwcmFqYXBhdGkuaW4iLCJpc3MiOiJNYW5pc2ggUHJhamFwYXRpIiwic3ViIjoibWFuaXNoLnByYWphcGF0aUBvY3RhbGluZm9zb2x1dGlvbi5jb20ifQ.T9O5d1rnyH-kiD83tTt3PwtfaIHiCIK5Y6sjKIQ_rx3cUosGhvX8y6gjL2UV9uUHAERyyR9r1xsLC85yOzj4FLhXSqx9LV3DSwVuEfPw5R_qL9klNNM7YiQFzwuysd3dI4TMvDjYljwwBtejtlzcv9HpcocHkQgSx1qTCfodRrk'} });
    if (res.status === 401) {
      window.location = '/login';
    }
    return res;
  },
  put: async (jsonObj = {}, path = "", session = {}) => {

    const url = API_URL + path;
    const res = await axios.put(url,jsonObj, { headers: {"x-access-token": session.token || 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX3R5cGUiOiJzdXAiLCJwZXJtaXNzaW9ucyI6W10sIl9pZCI6IjYxNGRiMDU0Njc1ZGI0MzYxNDA2OGM0YSIsIm5hbWUiOiJ0cmlzaGEiLCJ1c2VybmFtZSI6ImFkbWluIiwicGhvbmUiOiI5MDI1NDE3ODQ1IiwiZW1haWwiOiJmb3J0QGdtYWlsLmNvbSIsImNyZWF0ZWRfYXQiOiIyMDIxLTA5LTI0VDExOjAyOjQ0Ljg4MVoiLCJ1cGRhdGVkX2F0IjoiMjAyMS0xMC0xM1QxMToyMzozOS44MTRaIiwiX192IjowLCJpZCI6IjYxNGRiMDU0Njc1ZGI0MzYxNDA2OGM0YSIsImlhdCI6MTYzNDIwNDAyMywiZXhwIjoxNjM0NTY0MDIzLCJhdWQiOiJtYW5pc2hwcmFqYXBhdGkuaW4iLCJpc3MiOiJNYW5pc2ggUHJhamFwYXRpIiwic3ViIjoibWFuaXNoLnByYWphcGF0aUBvY3RhbGluZm9zb2x1dGlvbi5jb20ifQ.Ic3bNBeZP6d2HPEs9PXFfUkWS0dqXrqFg9VXg_tZBJrUUE-VjoLh8c3B9JQUit4CmG_tSgp6ZT7bESMW_QwEgN_npcrBi3bTIBvEoo8J5NcSRSiE7T9RjU0SG7u8-Snjd0I4kvEosjMIFYN7KucoYhQNpJ1wzYabZY6HOzxPsvo'} });
    if (res.status === 401) {
      window.location = '/login';
    }
    return res;
  },
  putS3: async (binary = "", path = "", session = {}) => {

    //const url = API_URL + path;
    console.log('----------------------->', path,binary)
    const res = await axios.put(path,binary, { headers: {"Content-Type": "image/jpeg"} });

    return res;
  },
  post: async (jsonObj = {}, path = "", session = {}) => {

    const url = API_URL + path;
    const res = await axios.post(url,jsonObj, { headers: {"x-access-token": session.token || 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX3R5cGUiOiJzdXAiLCJwZXJtaXNzaW9ucyI6W10sIl9pZCI6IjYxNGRiMDU0Njc1ZGI0MzYxNDA2OGM0YSIsIm5hbWUiOiJ0cmlzaGEiLCJ1c2VybmFtZSI6ImFkbWluIiwicGhvbmUiOiI5MDI1NDE3ODQ1IiwiZW1haWwiOiJmb3J0QGdtYWlsLmNvbSIsImNyZWF0ZWRfYXQiOiIyMDIxLTA5LTI0VDExOjAyOjQ0Ljg4MVoiLCJ1cGRhdGVkX2F0IjoiMjAyMS0xMC0xM1QxMToyMzozOS44MTRaIiwiX192IjowLCJpZCI6IjYxNGRiMDU0Njc1ZGI0MzYxNDA2OGM0YSIsImlhdCI6MTYzNDIwNDAyMywiZXhwIjoxNjM0NTY0MDIzLCJhdWQiOiJtYW5pc2hwcmFqYXBhdGkuaW4iLCJpc3MiOiJNYW5pc2ggUHJhamFwYXRpIiwic3ViIjoibWFuaXNoLnByYWphcGF0aUBvY3RhbGluZm9zb2x1dGlvbi5jb20ifQ.Ic3bNBeZP6d2HPEs9PXFfUkWS0dqXrqFg9VXg_tZBJrUUE-VjoLh8c3B9JQUit4CmG_tSgp6ZT7bESMW_QwEgN_npcrBi3bTIBvEoo8J5NcSRSiE7T9RjU0SG7u8-Snjd0I4kvEosjMIFYN7KucoYhQNpJ1wzYabZY6HOzxPsvo'} });
    if (res.status === 401) {
      window.location = '/login';
    }
    return res;
  },
  formPost: async (jsonObj = {}, path = "", session) => {

    const url = API_URL + path;
    const res = await fetch(url, {
      method: "POST",
      body: jsonObj,
      headers: {
        "x-access-token": session.token || ''
      }
    });
    return { response: await res, status: await res.status };
  },
  serialize: function (obj, prefix) {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          this.serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
  },
  parseJwt: (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
}
export default helper;
