import { Icon } from '@iconify/react';
import { useRef, useState,useContext } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import swapFill from '@iconify/icons-eva/swap-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@mui/material/Button';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, Stack, ListItemText } from '@material-ui/core';
import PermissionContext from '../../../contexts/PermissionContext';



// ----------------------------------------------------------------------

export default function UserMoreMenu(props) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [userPermissions, useUserPermissions] = useState('');


  let ChangePermission = (perm) => {
    useUserPermissions(perm)
  }

  return (
    <><PermissionContext.Consumer>
        {permissions => (
        
      <Stack sx={{ flexDirection: 'initial' }}>
        {
          ChangePermission(permissions.find(permission => permission['_id'] === props['pageId']))
        }
       
        {(props.editPath && (!userPermissions)) ? <MenuItem
          component={RouterLink}
          to={props.editPath ? props.editPath : '#'}
          sx={{ color: 'text.secondary', padding: '0px!important' }}
        >
          <ListItemIcon sx={{ minWidth: 'auto!important', padding: '0px!important' }}>
            <img src={'/static/icons/edit-blue.svg'} alt="demo" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> : (((props.editPath) && userPermissions['tasks']['edit']) && <MenuItem
          component={RouterLink}
          to={props.editPath ? props.editPath : '#'}
          sx={{ color: 'text.secondary', padding: '0px!important' }}
        >
          <ListItemIcon sx={{ minWidth: 'auto!important', padding: '0px!important' }}>
            <img src={'/static/icons/edit-blue.svg'} alt="demo" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>)}

        {((props.handleArchive) && (!userPermissions)) ? <MenuItem
          onClick={() => {
            setOpen(true);
          }}
          sx={{ color: 'text.secondary', padding: '0px!important' }}
        >
          <ListItemIcon sx={{ minWidth: 'auto!important' }}>
            <img src={'/static/icons/delete-blue.svg'} alt="demo" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> : 
        (((props.handleArchive) && (userPermissions['tasks']['delete'])) && <MenuItem
        onClick={() => {
          setOpen(true);
        }}
        sx={{ color: 'text.secondary', padding: '0px!important' }}
      >
        <ListItemIcon sx={{ minWidth: 'auto!important' }}>
          <img src={'/static/icons/delete-blue.svg'} alt="demo" />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ variant: 'body2' }} />
      </MenuItem>)}

        {((props.handleStatus) && (!userPermissions)) ? <MenuItem
          onClick={() => {
            props.handleStatus(props.id, props.isActive);
            setIsOpen(false);
          }}
          sx={{ color: 'text.secondary', padding: '0px!important' }}
        >
          <ListItemIcon sx={{ minWidth: 'auto!important' }}>
            <img src={'/static/icons/logout.png'} alt="demo" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem> : 
        (((props.handleStatus) && (userPermissions['tasks']['status'])) && <MenuItem
          onClick={() => {
            props.handleStatus(props.id, props.isActive);
            setIsOpen(false);
          }}
          sx={{ color: 'text.secondary', padding: '0px!important' }}
        >
          <ListItemIcon sx={{ minWidth: 'auto!important' }}>
            <img src={'/static/icons/logout.png'} alt="demo" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>)}
       
      </Stack>
       )}
      </PermissionContext.Consumer>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>
          Delete?
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
            sx={{ float: 'right' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3}>Are You Sure You Want to delete this entry?</Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
              setIsOpen(false);
            }}
            color="info"
          >
            No
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              props.handleArchive(props.id);
              setOpen(false);
              setIsOpen(false);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
