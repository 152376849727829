import React from 'react';

const AboutUs = () => {
  return (
    <Page title="About Us | Piver">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            About Us
          </Typography>
        </Stack>
      </Container>
    </Page>
  );
};

export default AboutUs;
