import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Fab, IconButton, Avatar, Box, Stack, Typography } from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import EditIcon from '@mui/icons-material/Edit';
import helper from '../../constants/helper';
import apiPath from '../../constants/apiPath';
import { display } from '@material-ui/system';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import Typography from 'src/theme/overrides/Typography';

// Style the Button component
const Button = styled.button`
  /* Insert your favorite CSS code to style a button */
`;
export default function FileUploader(props) {

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const hiddenFileInput = React.useRef(null);
  const [imagePreview, setImagePreview] = useState('/static/icons/profile-pic1.png');
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const uploadImageToBucket = async (fileObj) => {
    try {

      handleToggle();

      const res = await helper.get(apiPath.uploadUrl + props.folder);
      // console.log('res', res)
      if (res.status === 200) {
        
        const s3res = await helper.putS3(fileObj, res.data.url);
        console.log('s3res', s3res);
        if (s3res.status === 200) {
          props.handleFile(res.data.filename);
          
        }
      }
    } catch (e) {
      console.log("ERRRRRR", e);
    }
    setTimeout(()=>{  handleClose(); },100)
  };

  useEffect(() => {
    setImagePreview(props.imageName ? props.imageName : '/static/icons/profile-pic1.png');
    console.log('imagePreview', imagePreview);
  }, [props.imageName]);

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];

    // console.log('fileUploaded', fileUploaded);
    // console.log('type', typeof fileUploaded);

    uploadImageToBucket(fileUploaded);
    setImagePreview(URL.createObjectURL(fileUploaded));
  };

  {
    /*<Fab component="span" onClick={handleClick}>
            <AddPhotoAlternateIcon />
        </Fab>*/
  }

  return (
    <>
  <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
     
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'initial',
          alignItems: 'center',
          marginBottom: '20px'
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <Avatar
            onClick={handleClick}
            src={imagePreview}
            sx={{
              height: 139,
              width: 139,
              background: '#E4E4E4'
            }}
          />

          <input
            type="file"
            ref={hiddenFileInput}
            accept="image/*"
            onChange={handleChange}
            style={{ display: 'none' }}
          />

          <Avatar sx={{ background: 'none', position: 'absolute', right: 0, bottom: 0 }}>
            <img src={'/static/icons/profile-plus-icon.svg'} alt="demo" />
          </Avatar>
        </Box>

        <Stack sx={{ width: 'calc(100% - 139px)', paddingLeft: '25px' }}>
          <Typography
            variant="h5"
            sx={{
              fontSize: '22px!important',
              fontWeight: 850,
              fontFamily: 'Avenir',
              color: '#000'
            }}
          >
            {(props.name) && (props.name)}
          </Typography>
          {/*<Typography
            variant="h6"
            sx={{
              fontSize: '14px!important',
              color: '#7E8795',
              fontFamily: 'Avenir',
              fontWeight: 400
            }}
          >
            View Profile
          </Typography>*/}
        </Stack>
      </Stack>
    </>
  );
}
