import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {useParams} from 'react-router-dom';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, FormControlLabel, Checkbox } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function EditForm() {
  // let _id = '6152f05e3fa9c91d60ad9965';
  const _id = useParams().id;
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [userData, setUserData] = useState({});
  const [isActive, setIsActive] = useState(false);
  const getUserData = async()=>{
    //handleToggle()
    let body={};
    try{
        const res = await helper.get(apiPath.adminUserData+ '/' + _id,body, session);
        if(res.status===200) {
          setUserData(res.data.results)
          setIsActive(res.data.results.isActive)
          //handleToggle()
        }
        
    } catch (e) {
        console.log(e);
    }
    
  }

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const UserSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Please enter at least 2 characters!')
      .max(50, 'Should not be more than 50 characters!')
      .required('Please enter user name')
      .matches(
        /^[a-zA-Z\s]+$/,
        "Must be alphabets"
      ),
    mobile: Yup.number().min(1000000000, 'Please enter correct number!').max(9999999999, 'Please enter correct number!'),
    email: Yup.string().email('Email must be a valid email address').required('Please enter email address'),
    // google_id: Yup.number().required('Please enter google ID!!'),
    // password: Yup.string().min(6, 'Please enter at least 6 characters!').max(20, 'Should not be more than 20 characters!').required('Please enter password'),
    //:Yup.string().required('Please enter preparation time')
  });
  


  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }
  const handleActiveInactive = (checkboxval) => {
    console.log("checkboxval", checkboxval)
    setIsActive(checkboxval)
  }

  useEffect(() => {
    getUserData()
  },[]);


  const formik = useFormik({
  
    initialValues: {
      name: userData.name??'',
      mobile: userData.mobile??'',
      email: userData.email??'',
      isActive: userData.isActive??'',
      // device_id: userData.device_id,
      // google_id: userData.google_id,
      //device_id:userData.device_id,
      password: '',
    },
    enableReinitialize: true,

    validationSchema: UserSchema,
    onSubmit: async (values, { setSubmitting }) => {

      values['image'] = image
      values['isActive'] = isActive

      console.log("values", values)

      handleToggle()
      try {
        const res = await helper.put(values, apiPath.adminUserUpdate+ '/' + _id,session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg,{variant});
            
          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg,{variant});
            navigate('/dashboard/customers', { replace: true });
            
            console.log("res.data.results", res.data.results)            
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });


  const h1tag = {
    color:"red"
  } 

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
    {(userData) && 
    <FormikProvider value={formik}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {(errorResponse) && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} className="edit-form-allinput">
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControl sx={{ width: '100%', my: 2, py: 2 }}>

              {/*<Avatar
              src={imagePreview}
              sx={{
                height: 100,
                width: 100
              }}
            />*/}

              <FileUploader imageName={userData.image} handleFile={handleFile} folder={'users'} />


            </FormControl>
          </Stack>
        </Stack>

        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              label="Phone"
              {...getFieldProps('mobile')}
              error={Boolean(touched.mobile && errors.mobile)}
              helperText={touched.mobile && errors.mobile}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
          </Stack>

          {/*Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <FormControlLabel
              control={
                <Checkbox  checked={isActive} onChange={(event, newValue) => {
                setIsActive(newValue);
              }}  />
              }
              onChange={handleActiveInactive}
              label="Active"
            />
            </Stack>
          </Stack>*/}



<Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
              <LoadingButton
                sx={{ width: '200px' }}
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  height: '50px',
                  width: '131px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 850,
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  padding: '17px',
                  marginRight: '9px'
                }}
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>

              <LoadingButton
                sx={{ width: '200px' }}
                size="large"
                type="button"
                variant="contained"
                sx={{
                  height: '50px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 850,
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  padding: '17px',
                  background: '#E4E4E4',
                  color: '#000',
                  width: '131px'
                }}
                loading={isSubmitting}
                href="/dashboard/customers"
              >
                Cancel
              </LoadingButton>
            </Stack>
        </Stack>
      </Form>
    </FormikProvider>
    }
    </>
  );
}
