import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
import smilingFaceFill from '@iconify/icons-eva/smiling-face-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { Card, Typography,Link } from '@material-ui/core';
import { io } from "socket.io-client";
// utils
import { fShortenNumber } from '../../../utils/formatNumber';


// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  color: theme.palette.warning.darker,
  backgroundColor: '#9bbb59',
  display: 'flex',
  alignItems: 'center',
  padding: '20px 15px',
  borderRadius: '22px'
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: '81px',
  height: '81px',
  justifyContent: 'center',
  marginBottom: 0,
  color: theme.palette.warning.dark,
  backgroundColor: '#fff'
}));

const StackStyle = styled('Stack')(({ theme }) => ({
  width: 'calc(100% - 81px)',
  textAlign: 'left',
  paddingLeft: '20px'
}));

  

// ----------------------------------------------------------------------

const TOTAL = 1723315;

export default function AppOnlineDrivers(props) {

  const [totalOnlineDriver, setTotalOnlineDriver] = useState(0);
// console.log("socket")
  // const driverSocket = io('ws://piver.tk:7097', {
  const driverSocket = io('ws://localhost:7091', {
    transports: ['websocket'],
    auth:{
      type:'admin',
      view:'dashboard'
    }
  });
  console.log("socket",driverSocket)
  driverSocket.on('connect', () => {
    //this.setSocketObject(socket);
    console.log('driver socket client demo', driverSocket);
    console.log('driver socket client demo connected', driverSocket.id);
  });

  driverSocket.on('disconnect', () => {
    console.log("socket",driverSocket.connected); // false
  });
  driverSocket.io.on('error', error => {
    console.log('driver socket client demo error')
  });
  driverSocket.io.on('reconnect', attempt => {
    console.log('driver socket client demo reconnect')
  });
  driverSocket.io.on('reconnect_attempt', attempt => {
    console.log('driver socket client demo reconnect_attempt')
  });
  driverSocket.io.on('reconnect_error', error => {
    console.log('driver socket client demo reconnect_error')
  });
  driverSocket.io.on('reconnect_failed', () => {
    console.log('driver socket client demo reconnect_failed')
  });


  
  driverSocket.emit('driverCount', (data) => {

    console.log('Driver socket Count', data)

    setTotalOnlineDriver(data.count)

  });

  driverSocket.on('driverCount', (data) => {

    console.log('Driver socket Count', data)

    setTotalOnlineDriver(data.count)

  });


  return (
    <Link component={RouterLink} to="/dashboard/bookings">
    <RootStyle>
      <IconWrapperStyle>
        <Icon>
          <img src="/static/icons/delivery1.png" alt="demo" width={'30px'} />
        </Icon>
      </IconWrapperStyle>
      <StackStyle className="stackstyleheading">
        <Typography variant="h3" className="stackstyleheading">
          {totalOnlineDriver}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: '#fff',
            fontFamily: 'Avenir',
            fontSize: '14px!important',
            fontWeight: '500',
            color: '#fff'
          }}
        >
          Total Online Riders
        </Typography>
      </StackStyle>
    </RootStyle></Link>
  );
}
