import { Checkbox as CheckboxTemp } from '@material-ui/core';

import { styled } from '@material-ui/core/styles';
import '../developer.css';

const CustomizedCheckbox = styled(CheckboxTemp)`

`;

export default function Checkbox(props) {


  return <CustomizedCheckbox icon={<img src={'/static/icons/unChecked.svg'} alt="check" />} checkedIcon={<img src={'/static/icons/checked.svg'} alt="check" />} {...props} />;
}
