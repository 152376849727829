import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import Select from '@mui/material/Select';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@material-ui/core/styles';
import {
  MenuItem,
  Stack,
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import { FilterDate } from '../../forms/index';
import FormControl from '@mui/material/FormControl';
import Rating from '@mui/material/Rating';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 381,
  height: 45,
  // transition: theme.transitions.create(['box-shadow', 'width'], {
  //   easing: theme.transitions.easing.easeInOut,
  //   duration: theme.transitions.duration.shorter
  // }),
  // '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

OrderListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function OrderListToolbar({
  numSelected,
  filterName,
  onFilterArea,
  onFilterName,
  dateFilter,
  setDateFilter,
  status,
  handleStatusChange,
  handlePaymentModeChange,
  handleRatingChange,
  handleStatusHistoryChange,
  handleOrderTypeChange,
  clearFilter
}) {
  console.log('UserList', dateFilter);
  const handleOpen = () => setOpen(true);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClear = (e) => {
    handleStatusChange(e);
    setDateFilter([null, null]);
    clearFilter();
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
          padding: '0px!important'
        })
      }}
      className="search-list-dash"
    >

<Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ width: '100%', paddingLeft: '0px' }}
          spacing={2}
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ paddingLeft: '0px' }} spacing={2}>

   
          <SearchStyle
                value={filterName}
                onChange={onFilterName}
                className="keywordSearch"
                placeholder="Search"
                endAdornment={
                  <InputAdornment position="start">
                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                }
          />

          
    

</Stack>
          
</Stack>

      <Dialog open={open} onClose={handleClose} maxWidth={'md'}  className="edit-modal-form edit-form-allinput">
        <DialogTitle>Filters
          <IconButton onClick={handleClose} sx={{float:'right'}}>
              <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <FilterDate setDateFilter={setDateFilter} dateFilter={dateFilter} />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

            {(onFilterArea) && <TextField
                fullWidth
                //value={}
                onChange={onFilterArea}
                //className="keywordSearch"
                placeholder="District"
                endAdornment={
                  <InputAdornment position="start">
                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                }
            />}
            </Stack>

            {(handleStatusHistoryChange) && <Stack spacing={3}>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={status}
                  onChange={handleStatusHistoryChange}
                  label="Status"
                  sx={{ minWidth: '100px' }}
                >
                  <MenuItem value={''}>
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value={'delivered'}>Delivered</MenuItem>
                  <MenuItem value={'rejected'}>Rejected</MenuItem>
                  <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                  
                </Select>
              </FormControl>
            </Stack>}

            {(handleStatusChange) && <Stack spacing={3}>
              <FormControl>
                <InputLabel id="demo-simple-select-autowidth-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={status}
                  onChange={handleStatusChange}
                  label="Status"
                  sx={{ minWidth: '100px' }}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value={'pending'}>Pending</MenuItem>
                  <MenuItem value={'rejected'}>Rejected</MenuItem>
                  <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                  <MenuItem value={'preparing'}>Preparing</MenuItem>
                  <MenuItem value={'prepared'}>Prepared</MenuItem>
                  <MenuItem value={'picked'}>Picked</MenuItem>
                  <MenuItem value={'arrived'}>Arrived</MenuItem>
                  <MenuItem value={'delivered'}>Delivered</MenuItem>
                </Select>
              </FormControl>
            </Stack>}


            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              
              {(handlePaymentModeChange) && <FormControl sx={{width:'50%'}}>
                <InputLabel id="demo-simple-select-autowidth-label">Payment Mode</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  //value={status}
                  onChange={handlePaymentModeChange}
                  label="Payment Mode"
                  sx={{ minWidth: '100px' }}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value={'online'}>Online</MenuItem>
                  <MenuItem value={'cod'}>Cod</MenuItem>
                  <MenuItem value={'wallet'}>Wallet</MenuItem>
                </Select>
              </FormControl>}

              {(handleOrderTypeChange) && <FormControl sx={{width:'50%'}}>
                <InputLabel id="demo-simple-select-autowidth-label">Order Type</InputLabel>
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  //value={status}
                  onChange={handleOrderTypeChange}
                  label="Order Type"
                  sx={{ minWidth: '100px' }}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value={'delivery'}>Delivery</MenuItem>
                  <MenuItem value={'pickup'}>Pick up</MenuItem>
                  
                </Select>
              </FormControl>}

            </Stack>

            {(handleRatingChange) && <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Rating
                name="simple-controlled"
                //value={value}
                onChange={handleRatingChange}
              />
            </Stack>}

          </Stack>
        </DialogContent>
        <DialogActions sx={{margin:'0px 20px 20px 0'}}>
          <Button variant="outlined" onClick={handleClear} color="error">
            Clear Filters
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="More Filters" onClick={handleOpen}>
          <IconButton>
            <Icon icon={roundFilterList} />
          </IconButton>
        </Tooltip>
      )}

      <Dialog onClose={handleClose}>
        <DialogTitle>Filters</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                autoFocus
                margin=""
                id="name"
                label="Email Address"
                type="text"
                fullWidth
                variant="standard"
              />

              <TextField
                autoFocus
                margin=""
                id="name2"
                label="Email Address"
                type="text"
                fullWidth
                variant="standard"
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ marginBottom: '10px' }}>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </RootStyle>
  );
}
