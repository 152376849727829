import PropTypes from 'prop-types';
// material
import { visuallyHidden } from '@material-ui/utils';
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
// ----------------------------------------------------------------------

const CustomizedTableHead = styled(TableHead)`
  color: #ffffff;
  background: #555555;

  :hover {
    color: #ffffff;
  }
`;

const CustomizedTableCell = styled(TableCell)`
  color: #ffffff;
  background: #555555;

  :hover {
    color: #ffffff;
  }
`;

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func
};

export default function UserListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick
}) {
  const createSortHandler = (property) => (event) => {
    if (property && property !== 'actions') onRequestSort(event, property);
  };

  return (
    <CustomizedTableHead>
      <TableRow>
        <CustomizedTableCell padding="checkbox">
          {/*<Checkbox
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={onSelectAllClick}
                    />*/}
        </CustomizedTableCell>
        {headLabel.map((headCell) => (
          <CustomizedTableCell
            className="CustomizedTableCell"
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </CustomizedTableCell>
        ))}
      </TableRow>
    </CustomizedTableHead>
  );
}
