import * as React from 'react';
import Modal from '@mui/material/Modal';
import { Icon } from '@iconify/react';
import Select from '@mui/material/Select';
import closeOutline from '@iconify/icons-eva/close-outline';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@material-ui/core/styles';
import {
  MenuItem,
  Stack,
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@material-ui/core';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import { FilterDate} from '../../forms/index';
import FormControl from '@mui/material/FormControl';



const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  minHeight: 350,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));
export default function BasicModal({ numSelected, filterName, onFilterName,dateFilter,setDateFilter,status,handleStatusChange,clearFilter}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>More Filters</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Filters
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <RootStyle
              sx={{
                ...(numSelected > 0 && {
                  color: 'primary.main',
                  bgcolor: 'primary.lighter'
                })
              }}
            >
              <FilterDate setDateFilter={setDateFilter} dateFilter={dateFilter} />
              <FormControl  sx={{ mx: 2 }} >
                <InputLabel id="demo-simple-select-autowidth-label">Status</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={status}
                    onChange={handleStatusChange}
                    label="Status"
                    sx={{ 'minWidth': '200px' }}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value={'active'}>Active</MenuItem>
                    <MenuItem value={'deactive'}>Deactive</MenuItem>
                </Select>
              </FormControl>
            </RootStyle> 
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
