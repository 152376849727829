import * as Yup from 'yup';
import * as React from 'react';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import useSession from 'react-session-hook';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import {
  TextField,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Box,
  Checkbox,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, OrderMoreMenu } from '../components/_dashboard/user';

import { fDateTime } from '../utils/formatTime';
import helper from '../constants/helper';
import apiPath from '../constants/apiPath';
import { LoadingButton } from '@material-ui/lab';

export default function ViewOrder(props) {
  const session = useSession();
  const navigate = useNavigate();
  const earningData = props.earningData;
  const _id = props.earningId;

  const maxAmount = parseFloat(earningData.subTotalAmount) - parseFloat(earningData.amount);

  const CategorySchema = Yup.object().shape({
    amount: Yup.number()
      .min(1, 'Amount should be greater than or equal to 1')
      .max(maxAmount, `Amount should be less than or equal to ${maxAmount}`)
      .required('Please enter settlement amount')
  });

  const formik = useFormik({
    initialValues: {
      amount: ''
    },
    validationSchema: CategorySchema,
    onSubmit: async (values, { setSubmitting }) => {
      // console.log('values', values);
      try {
        const res = await helper.post(
          {
            restaurant: earningData._id,
            amount: values.amount
          },
          apiPath.addSettlement,
          session
        );
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error';
          } else {
            props.handleDrawerClose(true);
          }
        }
      } catch (e) {
        setErrorResponse('');
        setTimeout(function () {
          setErrorResponse('');
        }, 5000);
        console.log(e);
      }
      handleClose();
      console.log('values', values);
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Card sx={{ my: 2, mx: 2 }}>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', px: 4, py: 2 }}>
          <Typography variant="subtitle1" noWrap sx={{ my: 2 }}>
            Add Settlement
          </Typography>

          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Settlement  Amount"
                  {...getFieldProps('amount')}
                  error={Boolean(touched.amount && errors.amount)}
                  helperText={touched.amount && errors.amount}
                />
              </Stack>
              <Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    height: '50px',
                    width: '150px',
                    borderRadius: '20px',
                    boxShadow: 'none',
                    fontWeight: 850,
                    fontSize: '16px',
                    fontFamily: 'Avenir',
                    padding: '17px',
                    marginRight: '9px'
                  }}
                  loading={isSubmitting}
                >
                  Submit
                </LoadingButton>

                {/* <LoadingButton
                        size="large"
                        type="button"
                        variant="contained"
                        sx={{
                          height: '50px',
                          borderRadius: '20px',
                          boxShadow: 'none',
                          fontWeight: 850,
                          fontSize: '16px',
                          fontFamily: 'Avenir',
                          padding: '17px',
                          background: '#E4E4E4',
                          color: '#000',
                          width: '150px'
                        }}
                        loading={isSubmitting}
                        href="/dashboard/categories"
                      >
                        Cancel
                      </LoadingButton> */}
              </Stack>
            </Stack>
          </Form>
        </List>
      </Card>
    </FormikProvider>
  );
}
