import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import Checkbox from '../StyledCheckBox';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export default function AddForm() {
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [groups, setGroups] = useState([
  ]);


  const [selectedPermissions, setSelectedPermissions] = useState([{ "_id": "customer", "name": "Customer Management", tasks:{
    "view":false,"edit":false,"delete":false,"status":false
    } }, { "_id": "order", "name": "Order Management" , tasks:{
    "view":false,"status":false
    }}, { "_id": "restaurant", "name": "Restaurant Management", tasks:{
    "view":false,"add":false,"edit":false,"delete":false,"status":false
    } }, { "_id": "category", "name": "Category", tasks:{
    "view":false,"add":false,"edit":false,"delete":false,"status":false
    } }, { "_id": "company", "name": "Company Management", tasks:{
    "view":false,"delete":false,"status":false
    } }, { "_id": "cuisine", "name": "Cuisines", tasks:{
    "view":false,"add":false,"edit":false,"delete":false,"status":false
    } }, { "_id": "menu", "name": "Menu Items", tasks:{
    "view":false,"delete":false,"status":false
    } }, { "_id": "orders", "name": "Orders", tasks:{
    "view":false,"status":false
    } }, { "_id": "booking", "name": "Bookings", tasks:{
    "view":false,"status":false
    } }, { "_id": "driver", "name": "Driver Management", tasks:{
    "view":false,"add":false,"edit":false,"delete":false,"status":false
    } }, { "_id": "promocode", "name": "Promocodes", tasks:{
    "view":false,"delete":false,"status":false
    } }, { "_id": "reviews", "name": "Reviews", tasks:{
    "view":false
    } }, { "_id": "cms", "name": "CMS Pages", tasks:{
    "view":false,"edit":false
    } }, { "_id": "contacts", "name": "Contacts", tasks:{
    "view":false
    } }]);
  //const [permissions, setPermissions] = useState([{"_id":"customer","name":"Customer"},{"_id":"order","name":"Orders"},{"_id":"restaurant","name":"Restaurants"},{"_id":"category","name":"Category"},{"_id":"company","name":"Company Management"},{"_id":"cuisine","name":"Cuisines"},{"_id":"menu","name":"menu Items"},{"_id":"orders","name":"Orders"},{"_id":"booking","name":"Bookings"},{"_id":"driver","name":"Driver Management"},{"_id":"promocode","name":"Promocodes"},{"_id":"reviews","name":"Reviews"},{"_id":"cms","name":"CMS Pages"},{"_id":"contacts","name":"Contacts"}]);


  const [permissions, setPermissions] = useState([{ "_id": "customer", "name": "Customer Management", tasks:{
    "view":false,"add":false,"edit":false,"delete":false,"status":false
    } }, { "_id": "order", "name": "Order Management" , tasks:{
    "view":false,"add":false,"edit":false,"delete":false,"status":false
    }}, { "_id": "restaurant", "name": "Restaurant Management", tasks:{
    "view":false,"add":false,"edit":false,"delete":false,"status":false
    } }, { "_id": "category", "name": "Category", tasks:{
    "view":false,"add":false,"edit":false,"delete":false,"status":false
    } }, { "_id": "company", "name": "Company Management", tasks:{
    "view":false,"add":false,"edit":false,"delete":false,"status":false
    } }, { "_id": "cuisine", "name": "Cuisines", tasks:{
    "view":false,"add":false,"edit":false,"delete":false,"status":false
    } }, { "_id": "menu", "name": "menu Items", tasks:{
    "view":false,"delete":false,"status":false
    } }, { "_id": "orders", "name": "Orders", tasks:{
    "view":false,"status":false
    } }, { "_id": "booking", "name": "Bookings", tasks:{
    "view":false,"status":false
    } }, { "_id": "driver", "name": "Driver Management", tasks:{
    "view":false,"add":false,"edit":false,"delete":false,"status":false
    } }, { "_id": "promocode", "name": "Promocodes", tasks:{
    "view":false,"add":false,"edit":false,"delete":false,"status":false
    } }, { "_id": "reviews", "name": "Reviews", tasks:{
    "view":false
    } }, { "_id": "cms", "name": "CMS Pages", tasks:{
    "view":false,"edit":false
    } }, { "_id": "contacts", "name": "Contacts", tasks:{
    "view":false
    } }]);


  const [pickUpEnabled, setPickUpEnabled] = useState(false);
  const [deliveryEnabled, setDelivereyEnabled] = useState(false);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const handleChange = (event) => {

    console.log("event", event.target)

    const {
      target: { value },
    } = event;
    setSelectedPermissions(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const GroupSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too short!')
      .max(50, 'Too long!')
      .required('Please enter group name'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: GroupSchema,
    onSubmit: async (values, { setSubmitting }) => {

      console.log("values", values)
      values['permissions'] = selectedPermissions

      handleToggle()
      try {
        const res = await helper.post(values, apiPath.addGroup, session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg, { variant });

          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg, { variant });
            navigate('/dashboard/groups', { replace: true });

            console.log("res.data.results", res.data.results)
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });

  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }



  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {(errorResponse) && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

        <Stack spacing={0} className="edit-form-allinput">
          <Stack spacing={1} sx={{ marginBottom: '10px' }}>
            <TextField
              sx={{ width: '50%' }}
              label="Group name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            {/* </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}
          </Stack>

          {(selectedPermissions) && selectedPermissions.map((item, key) => {

            return (<Stack key={key} direction={{ xs: 'column', sm: 'row' }} spacing={1}>

              <Stack sx={{ width: '200px', marginTop: '8px' }} spacing={1}>
                {item.name}
              </Stack>

              <Stack sx={{ width: '70%' }} direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                {Object.entries(item.tasks).map((role, key2) => { 
                  return (<Stack spacing={2}>
                    <FormControlLabel
                      sx={{ width: '88px' }}
                      control={
                        <Checkbox
                          labelStyle={{ color: 'white' }}
                          iconStyle={{ fill: 'white' }}
                          style={{
                            color: '#f6f4f4'
                          }} checked={role[1]} onChange={(event, newValue) => {

                            console.log("newValue",newValue)

                            item['tasks'][role[0]] = newValue

                            setSelectedPermissions(selectedPermissions.map(obj => (item['_id'] === obj['_id'] ? item : obj)));

                            //setSelectedPermissions([...selectedPermissions,item])
                          }} />
                      }
                      label={role[0]}
                    />
                  </Stack>)
                })}
              </Stack>
            </Stack>)

          })}

          <Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
            <LoadingButton

              size="large"
              type="submit"
              variant="contained"
              sx={{
                height: '50px',
                width: '131px',
                borderRadius: '20px',
                boxShadow: 'none',
                fontWeight: 850,
                fontSize: '16px',
                fontFamily: 'Avenir',
                padding: '17px',
                marginRight: '9px'
              }}
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>

            <LoadingButton

              size="large"
              type="button"
              variant="contained"
              sx={{
                height: '50px',
                borderRadius: '20px',
                boxShadow: 'none',
                fontWeight: 850,
                fontSize: '16px',
                fontFamily: 'Avenir',
                padding: '17px',
                background: '#E4E4E4',
                color: '#000',
                width: '131px'
              }}
              loading={isSubmitting}
              href="/dashboard/groups"
            >
              Cancel
            </LoadingButton></Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
