import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import useSession from 'react-session-hook';

// ----------------------------------------------------------------------
import ErrorResponse from '../../../components/ErrorResponse';
import helper from '../../../constants/helper';
import apiPath from '../../../constants/apiPath';

export default function EmailAuthForm() {
  const navigate = useNavigate();
  const session = useSession();
  const _id = useParams().id;
  const [errorResponse, setErrorResponse] = useState('');

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const LoginSchema = Yup.object().shape({
    otp: Yup.string().required('Please enter OTP sent to your email')
  });

  const formik = useFormik({
    initialValues: {
      otp: '',
      id: _id
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      handleToggle();
      try {
        const res = await helper.post(values, apiPath.emailAuth);
        if (res.status === 200) {
          if (!res.data.success) {
            setErrorResponse(res.data.msg);
            setTimeout(function () {
              setErrorResponse('');
            }, 5000);
          } else {
            navigate('/update_password/' + res.data.results.id, { replace: true });
            console.log('res.data.results', res.data.results);
          }

          console.log('working');
        }
      } catch (e) {
        setErrorResponse('');
        setTimeout(function () {
          setErrorResponse('');
        }, 5000);
        console.log(e);
      }
      handleClose();
      console.log('values', values);
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  if (session.isAuthenticated) {
    console.log('session.isAuthenticated', session.isAuthenticated);
    //return navigate('/dashboard', { replace: true })
    return <Navigate to="/dashboard" />;
  }

  return (
    <FormikProvider value={formik}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {errorResponse && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="otp"
            type="text"
            label="OTP"
            {...getFieldProps('otp')}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
          />
        </Stack>

        <LoadingButton
          fullWidth
          sx={{ background: '#0C0CFA', borderRadius: '20px', marginTop: '30px' }}
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Go
        </LoadingButton>

        <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>
          <Link component={RouterLink} variant="subtitle2" to="/login" className="text-blue-color">
            Login?
          </Link>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
