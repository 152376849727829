import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState,useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useSession from 'react-session-hook';
const querystring = require('querystring');

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, OrderMoreMenu } from '../components/_dashboard/user';

import { fDateTime } from '../utils/formatTime';
import helper from "../constants/helper";
import apiPath from "../constants/apiPath";
//
// import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'orderId', label: 'Order ID', alignRight: false },
  { id: 'restaurant', label: 'Restaurant', alignRight: false },
  { id: 'user', label: 'User', alignRight: false },
  { id: 'items', label: 'Items', alignRight: false },
  { id: 'net_amount ', label: 'Net Amount', alignRight: false },
  { id: 'transaction_id ', label: 'Transaction ID', alignRight: false },
  { id: 'payment_mode ', label: 'Payment Mode', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created', label: 'Created', alignRight: false },
  { id: 'deliverd', label: 'Delivered', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false  }
];

// ----------------------------------------------------------------------

export default function Notifications() {
  const session = useSession();
  const [notifications, setNotifications] = useState({});
  const _id = useParams().id;
  const getNotifications = async()=>{
    let body={};
    try{
        const res = await helper.get(apiPath.Notifications+ '/' + _id,body, session);
        if(res.status===200) {
          console.log("res.data.results",res.data.results)
          setNotifications(res.data.results)
        }        
    } catch (e) {
        console.log(e);
    }    
  }

  useEffect(() => {
    getNotifications()
  },[]);

  return (
    <Page title="Order | Piver">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Order Details
          </Typography>
        </Stack>

        <Card>
          <List className={classes.root} subheader={<li />}>
            {Array(10)
              .fill()
              .map((s, sectionId) => (

            <li key={`section-${sectionId}`} className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                {[0, 1, 2].map(item => (
                  <ListItem key={`item-${sectionId}-${item}`}>
                    <ListItemText primary={`Item ${item}`} />
                  </ListItem>
                ))}
              </ul>
            </li>
            ))}
          </List>

        </Card>
      </Container>
    </Page>
  );
}
