import * as React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@material-ui/core/styles';
import {
  MenuItem,
  Stack,
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import { FilterDate } from '../../forms/index';
import FormControl from '@mui/material/FormControl';
import { Link as RouterLink } from 'react-router-dom';
import plusFill from '@iconify/icons-eva/plus-fill';
import Rating from '@mui/material/Rating';
import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import useSession from 'react-session-hook';

import helper from '../../../constants/helper';
import apiPath from '../../../constants/apiPath';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 0, 0, 0)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 381,
  height: 45,

  //transition: theme.transitions.create(['box-shadow', 'width'], {
  // easing: theme.transitions.easing.easeInOut,
  // duration: theme.transitions.duration.shorter
  //}),
  //'&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  borderRadius: 6,
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};
const style = {
  position: 'absolute',
  top: '40%',
  //left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  minHeight: 350,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

export default function UserListToolbar({
  numSelected,
  onFilterArea,
  handleRatingChange,
  handleCuisineChange,
  cuisines,
  filterName,
  handleVehicleChange,
  onFilterName,
  dateFilter,
  setDateFilter,
  status,
  handleStatusChange,
  clearFilter,
  userOrderCount,
  company,
  orderCount,
  orderCountStatus,
  handleOrderCountChange,
  userPostedOrderCount,
  handleStatusChangeApprove,
  isApprovedStatus,
  addButton,
  driverOrder,
  totalDriverOrder
}) {
  console.log('Hi this is demo', filterName);

  const [open, setOpen] = React.useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getVehicles = async () => {
    try {
      const res = await helper.post({}, apiPath.vehicleList, session);
      if (res.status === 200) {
        if (!res.data.success) {
          return <Navigate to="/dashboard/drivers" />;
        } else {
          setVehicles(res.data.results);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClear = (e) => {
    console.log('ee', e);

    console.log('ee', e);

    if (handleStatusChange) handleStatusChange(e);
    setDateFilter([null, null]);
    clearFilter();
    //setOpen(false);
  };
  console.log('orderCountStatus', orderCountStatus);
  return (
    <>
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter',
            padding: '0px!important'
          })
        }}
        className="search-list-dash"
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ width: '100%', paddingLeft: '0px' }}
          spacing={2}
        >
          <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ paddingLeft: '0px' }} spacing={2}>
            {onFilterName && (
              <SearchStyle
                value={filterName}
                onChange={onFilterName}
                className="keywordSearch"
                placeholder="Search"
                endAdornment={
                  <InputAdornment position="start">
                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                }
              />
            )}
          </Stack>

          {driverOrder && <Button variant="outlined">Total Orders - {totalDriverOrder}</Button>}

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ marginLeft: 'auto!important' }}
            spacing={2}
          >
            {addButton && (
              <Button
                variant="contained"
                component={RouterLink}
                to={`../${addButton}`}
                sx={{
                  height: '50px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 850,
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  padding: '17px'
                }}
                startIcon={<img src={'/static/icons/plus-icon.svg'} alt="demo" />}
              >
                Add New
              </Button>
            )}

            {onFilterName && (
              <Tooltip title="More Filters" onClick={handleOpen}>
                <IconButton>
                  <Icon icon={roundFilterList} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>

        <Dialog
          open={open}
          onClose={handleClose}
          sx={{ padding: '10px' }}
          className="edit-modal-form edit-form-allinput"
        >
          <DialogTitle>
            Filters
            <IconButton onClick={handleClose} sx={{ float: 'right' }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <FilterDate setDateFilter={setDateFilter} dateFilter={dateFilter} />
              </Stack>
              {handleStatusChange ? (
                <Stack spacing={3}>
                  <FormControl sx={{}}>
                    <InputLabel id="demo-simple-select-autowidth-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={status}
                      onChange={handleStatusChange}
                      label="Status"
                      sx={{ minWidth: '100px' }}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value={'active'}>Active</MenuItem>
                      <MenuItem value={'deactive'}>Non-Active</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              ) : (
                ''
              )}
              {handleStatusChangeApprove && (
                <Stack spacing={3}>
                  <FormControl sx={{}}>
                    <InputLabel id="demo-simple-select-autowidth-label">
                      Approve/Disapprove
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={isApprovedStatus}
                      onChange={handleStatusChangeApprove}
                      label="Approve/Disapprove"
                      sx={{ minWidth: '100px' }}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value={'approved'}>Approve</MenuItem>
                      <MenuItem value={'disapproved'}>Disapprove</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              )}
              {userOrderCount && (
                <>
                  <Stack spacing={3}>
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': { m: 1, width: '29ch' }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <FormControl sx={{}}>
                        <InputLabel id="demo-simple-select-autowidth-label">
                          User Order Count
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select-autowidth"
                          value={orderCountStatus}
                          onChange={handleOrderCountChange}
                          label=">User Order Count"
                          sx={{ minWidth: '100px' }}
                        >
                          <MenuItem value="">
                            <em>All</em>
                          </MenuItem>
                          {/* <MenuItem value={'posted'}>Posted orders</MenuItem> */}
                          {company && <MenuItem value="received">Received Orders</MenuItem>}
                          <MenuItem value="accepted">Accepted Orders</MenuItem>
                          {company && <MenuItem value="rejected">Rejected Orders</MenuItem>}
                          <MenuItem value="cancelled">Cancelled order</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        label="Count of orders"
                        value={orderCount}
                        onChange={userPostedOrderCount}
                      />
                    </Box>
                  </Stack>
                </>
              )}

              {onFilterArea && (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    //value={}
                    onChange={onFilterArea}
                    //className="keywordSearch"
                    placeholder="District"
                    endAdornment={
                      <InputAdornment position="start">
                        <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    }
                  />
                </Stack>
              )}
              {handleRatingChange && (
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <Rating
                    name="simple-controlled"
                    //value={value}
                    onChange={handleRatingChange}
                  />
                </Stack>
              )}
              {handleVehicleChange && vehicles.length ? (
                <Stack spacing={3}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={vehicles}
                    getOptionLabel={(option) =>
                      `${option.make} ${option.model} ${option.year} - ${option.number}`
                    }
                    onChange={(event, newValue) => {
                      // console.log('newValue', newValue);
                      if (newValue) {
                        setSelectedVehicle(newValue['_id']);
                        handleVehicleChange(newValue['_id']);
                      } else {
                        setSelectedVehicle('');
                        handleVehicleChange('');
                      }
                    }}
                    value={
                      vehicles.length > 0 && vehicles.find((group) => selectedVehicle === group._id)
                    }
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} label="Vehicle Type" />}
                  />
                </Stack>
              ) : (
                ''
              )}

              {handleCuisineChange && (
                <FormControl sx={{}}>
                  <InputLabel id="demo-simple-select-autowidth-label">Cuisines</InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    //value={status}
                    onChange={handleCuisineChange}
                    label="Cuisines"
                    sx={{ minWidth: '100px' }}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    {cuisines.map((item) => {
                      return <MenuItem value={item['_id']}>{item['name']}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              )}
            </Stack>
          </DialogContent>
          <DialogActions sx={{ margin: '0px 20px 20px 0' }}>
            <Button variant="outlined" onClick={handleClear} color="error">
              Clear Filters
            </Button>
            <Button variant="contained" onClick={handleClose}>
              Apply
            </Button>
          </DialogActions>
        </Dialog>
        {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Filters
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <RootStyle
              sx={{
                ...(numSelected > 0 && {
                  color: 'primary.main',
                  bgcolor: 'primary.lighter'
                })
              }}
            >
              <FilterDate setDateFilter={setDateFilter} dateFilter={dateFilter} />
              <FormControl  sx={{ mx: 2 }} >
                <InputLabel id="demo-simple-select-autowidth-label">Status</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={status}
                    onChange={handleStatusChange}
                    label="Status"
                    sx={{ 'minWidth': '200px' }}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value={'active'}>Active</MenuItem>
                    <MenuItem value={'deactive'}>Deactive</MenuItem>
                </Select>
              </FormControl>
            </RootStyle> 
          </Typography>
        </Box>
      </Modal> */}
      </RootStyle>
    </>
  );
}
