import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import useSession from 'react-session-hook';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import backFill from '@iconify/icons-eva/arrow-back-fill';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Icon,
  Grid,
  Stack,
  CardHeader,
  CardContent,
  Card,
  TextField,
  Container,
  Typography,
  Fab,
  IconButton,
  Box,
  Button,
  InputAdornment
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { Link as RouterLink, Route, BrowserRouter, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
// material
import { styled } from '@material-ui/core/styles';
import { useFormik, Form, FormikProvider } from 'formik';

// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import helper from '../constants/helper';
import apiPath from '../constants/apiPath';
import ErrorResponse from '../components/ErrorResponse';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));
// ----------------------------------------------------------------------

export default function ChangePassword({ match }) {
  const [errorResponse, setErrorResponse] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const session = useSession();

  const changePasswordSchema = Yup.object().shape({
    current_password: Yup.string()
      .min(6, 'Too Short!')
      .max(20, 'Too Long!')
      .required('Please enter current password'),
    password: Yup.string()
      .min(6, 'Too Short!')
      .max(20, 'Too Long!')
      .required('Please enter new password'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Please enter confirm password')
  });
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const formik = useFormik({
    initialValues: {
      current_password: '',
      password: '',
      confirm_password: ''
    },
    validationSchema: changePasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log('values', values);

      handleToggle();
      try {
        const res = await helper.post(values, apiPath.changePassword, session);
        if (res.status === 200) {
          if (!res.data.success) {
            navigate('/dashboard/profile', { replace: true });
          } else {
            navigate('/dashboard/profile', { replace: true });
          }

          console.log('working');
        }
      } catch (e) {
        setErrorResponse('');
        setTimeout(function () {
          setErrorResponse('');
        }, 5000);
        console.log(e);
      }
      handleClose();
      console.log('values', values);
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  console.log('match', match);

  return (
    <RootStyle title="Change Password | Piver">
      <Container fixed="false" maxWidth="xl">
        <Grid id="top-row" container spacing={24}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={4}>
              {/* <Typography variant="h4" gutterBottom>
                Change Password
              </Typography>
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/profile"
                sx={{ background: '#0C0CFA' }}
                startIcon={<Icon icon={backFill} />}
              >
                <ArrowBackIcon>arrow-back</ArrowBackIcon>Back
              </Button> */}
            </Stack>

            <Card sx={{ borderRadius: '0px', boxShadow: 'none', maxWidth: '40%' }}>
              <CardContent sx={{ padding: '0px' }}>
                <FormikProvider value={formik}>
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>

                  {errorResponse && <ErrorResponse msg={errorResponse} />}

                  <Form
                    autoComplete="off"
                    noValidate
                    onSubmit={handleSubmit}
                    className="edit-form-allinput"
                  >
                    <Stack spacing={3}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          fullWidth
                          autoComplete="current-password"
                          type={showPassword ? 'text' : 'password'}
                          label="Current Password"
                          {...getFieldProps('current_password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  onClick={() => setShowPassword((prev) => !prev)}
                                >
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          error={Boolean(touched.current_password && errors.current_password)}
                          helperText={touched.current_password && errors.current_password}
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          fullWidth
                          autoComplete="New Password"
                          type={showPassword ? 'text' : 'password'}
                          label="New Password"
                          {...getFieldProps('password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  onClick={() => setShowPassword((prev) => !prev)}
                                >
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          fullWidth
                          autoComplete="Confirm Password"
                          type={showPassword ? 'text' : 'password'}
                          label="Confirm Password"
                          {...getFieldProps('confirm_password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  onClick={() => setShowPassword((prev) => !prev)}
                                >
                                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          error={Boolean(touched.confirm_password && errors.confirm_password)}
                          helperText={touched.confirm_password && errors.confirm_password}
                        />
                      </Stack>

                      <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{
                          background: '#0C0CFA',
                          width: '150px',
                          borderRadius: '20px',
                          marginLeft: 'auto!important',
                          boxShadow: 'none'
                        }}
                        loading={isSubmitting}
                      >
                        Submit
                      </LoadingButton>
                    </Stack>
                  </Form>
                </FormikProvider>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
