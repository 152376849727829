import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useSession from 'react-session-hook';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// material
import { Stack, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import { FileUploader, MultiSelect, WorkingHours, ClockPick, TimePick } from '../forms/index';
import ErrorResponse from "../../components/ErrorResponse"
import CustomFields from "../../components/menu/CustomFields"
import helper from "../../constants/helper";
import apiPath from "../../constants/apiPath";
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

export default function EditForm() {
  // let _id = '61658ac9510f1e46fc3dcc30';
  const _id = useParams().id;
  const navigate = useNavigate();
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [errorResponse, setErrorResponse] = useState('');
  const [restaurants, setRestaurants] = useState([
  ]);
  const [menuData, setMenuData] = useState({});
  const [cuisines, setCuisines] = useState([
  ]);
  const [branches, setBranches] = useState([
  ]);
  const [nonVeg, setNonVeg] = useState(false);

  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState({_id: "614dcbd701781533787fd274", name: "FORT Restro", id: "614dcbd701781533787fd274"});

  const [categories, setCategories] = useState([
  ]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [optionListFinal, setOptionListFinal] = useState([]);

  const [foodTypes, setFoodTypes] = useState([{_id:'veg',"name":"Veg"},
  {_id:'nonveg',"name":"Nonveg"},
  {_id:'vegan',"name":"Vegan"}]);

  const [foodTypesObj, setFoodTypesObj] = useState('');

  const getMenuData = async()=>{
    let body={};
    try{
        const res = await helper.get(apiPath.adminMenuData+ '/' + _id,body, session);
        if(res.status===200) {
          setMenuData(res.data.results)
          setNonVeg(res.data.results.nonVeg)
          setOptionListFinal(res.data.results.customFields)
          setSelectedCuisines(res.data.results.cuisines);

          setFoodTypesObj([{_id:'veg',"name":"Veg"},
        {_id:'nonveg',"name":"Nonveg"},
        {_id:'vegan',"name":"Vegan"}].find((item)=>{ return item._id === res.data.results.foodType  }))

          if(res.data.results.branches)
            setSelectedBranches(res.data.results.branches);
        }
        
    } catch (e) {
        console.log(e);
    }
  }


  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCuisines(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    setFieldValue('cuisines',typeof value === 'string' ? value.split(',') : value)
  };
  const setOptionsFinal = (list) => {

    setOptionListFinal(list)
    console.log(optionListFinal)
  };

  const handleBranchesChange = (event) => {
    console.log("event",event.target)
    const {
      target: { value },
    } = event;
    setSelectedBranches(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    setFieldValue('cuisines',typeof value === 'string' ? value.split(',') : value)

  };

  const CuisineSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Please enter at least 2 characters!')
      .max(50, 'Should not be more than 50 characters!')
      .required('Please enter menu name'),
      restaurant:Yup.string()
      .required('Please select restaurant'),
      cuisines :Yup.array().min(1, 'Please select cuisine').required('Please select cuisine'),
      category:Yup.string()
      .required('Please select category'),
      price: Yup.number().min(1, 'Very less!').max(99999, 'Very high!').required('Please enter price in numbers'),
      ingredients: Yup.string().min(5, 'Please enter at least 5 characters!').max(600, 'Should not be more than 600 characters!').required('Please enter ingredients')
      .matches(
        /^[a-zA-Z\s]+$/,
        "Must be alphabets"
      ),
      preparationTime: Yup.number().min(1, 'Should be at least 1 min!').max(300, 'Should not be longer than 5 hours!').required('Please enter Preparation Time'),  
  });


  const handleFile = (fileUploaded) => {
    console.log("fileUploaded", fileUploaded)
    //setImagePreview(URL.createObjectURL(fileUploaded));
    setImage(fileUploaded)
  }

  const handleVegNonVeg = (checkboxval) => {
    console.log("checkboxval", checkboxval)
    setNonVeg(checkboxval)
  }


  const getCuisines = async() => {
    try {
      const res = await helper.get(apiPath.cuisinesList,{},session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/restaurants" />)
        }
        else {

          setCuisines(res.data.results)

          console.log("res.data.results", res.data.results)            
        }

        console.log("working")
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }

  const getRestaurants = async() => {
    try {
      const res = await helper.post({},apiPath.adminRestaurantList,session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/restaurants" />)
        }
        else {

          //console.log("NIkita",res.data.results.find((item)=> { if(item._id === '614dcbd701781533787fd274') { console.log(item._id); return item }  } ))
          console.log("menuData.restaurant",menuData)
          setRestaurants(res.data.results)
          if(menuData.restaurant){
            console.log("menuData.restaurant",menuData.restaurant)
            setSelectedRestaurant(res.data.results.find((item)=> item._id === menuData.restaurant.toString()))
            console.log("selectedRestaurant",selectedRestaurant)
          }
          
            
               
        }
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }

  const getCategories = async() => {
    console.log("menuData.category",menuData.category)
    try {
      const res = await helper.post({},apiPath.admincategoryList,session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/restaurants" />)
        }
        else {

          setCategories(res.data.results)
          if(menuData.category){
            setSelectedCategory(res.data.results.find((categoryItem)=> categoryItem._id == menuData.category.toString()))
            console.log("selectedCategory",selectedCategory)
          }

          console.log("res.data.results", res.data.results)            
        }

        console.log("working")
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }

  const rendorBranches = async() => {
    try {
      const res = await helper.post({},apiPath.adminRestaurantBranchList + '/' + selectedRestaurant['_id'],session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard/restaurants" />)
        }
        else {

          setBranches(res.data.results)

          console.log("res.data.results", res.data.results)            
        }

        console.log("working")
      }
    } catch (e) {
      setErrorResponse('')
      setTimeout(function () { setErrorResponse('') }, 5000)
      console.log(e);
    }
  }

  useEffect(() => {
    getMenuData()
    getCuisines()
    rendorBranches()
  },[]);
  
  useEffect(() => {
    getRestaurants()
    getCategories()
  },[menuData]);

  useEffect(() => {
    rendorBranches(selectedRestaurant['_id'])
  },[selectedRestaurant]);

  const formik = useFormik({
  
    initialValues: {
      name: menuData.name??'',
      restaurant:menuData.restaurant??'',
      cuisines:selectedCuisines,
      category:menuData.category??'',
      price: menuData.price??'',
      preparationTime: menuData.preparationTime??'',
      nonVeg: menuData.nonVeg??'',
      ingredients: menuData.ingredients??'',
    },
    enableReinitialize: true,

    validationSchema: CuisineSchema,
    onSubmit: async (values, { setSubmitting }) => {

      console.log("selectedCuisines",selectedCuisines)
      console.log("values", values)
      values['cuisines'] = selectedCuisines
      values['branches'] = selectedBranches
      values['restaurant'] = selectedRestaurant['_id']
      values['category'] = selectedCategory
      values['image'] = image
      values['nonVeg'] = nonVeg
      values['customFields'] = optionListFinal

      console.log("values", values)

      handleToggle()
      try {
        const res = await helper.put(values, apiPath.adminMenuUpdate+ '/' + _id,session);
        if (res.status === 200) {
          if (!res.data.success) {
            var variant = 'error'
            enqueueSnackbar(res.data.msg,{variant});
            
          }
          else {
            var variant = 'success'
            enqueueSnackbar(res.data.msg,{variant});
            navigate('/dashboard/menus', { replace: true });
            
            console.log("res.data.results", res.data.results)            
          }

          console.log("working")
        }
      } catch (e) {
        setErrorResponse('')
        setTimeout(function () { setErrorResponse('') }, 5000)
        console.log(e);
      }
      handleClose()
      console.log("values", values)
      //setSubmitting(false);
      //navigate('/dashboard', { replace: true });
    }
  });


  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue} = formik;

  return (
    <>
    {(menuData) && 
    <FormikProvider value={formik}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {(errorResponse) && <ErrorResponse msg={errorResponse} />}

      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <FormControl sx={{ width: '100%', my: 2, py: 2 }}>

            {/*<Avatar
            src={imagePreview}
            sx={{
              height: 100,
              width: 100
            }}
          />*/}

            <FileUploader imageName={menuData.image} handleFile={handleFile} folder={'menu_items'} />


          </FormControl>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Stack spacing={3} sx={{ width: '60%' }}>
            <Stack spacing={2}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ width: '100%' }}>
                <Autocomplete
                  id="combo-box-demo"
                  options={restaurants}
                  getOptionLabel={option => option.name}
                  onChange={(event, newValue) => {
                    console.log("newValue",newValue)
                    setFieldValue('restaurant',newValue._id)
                    setSelectedRestaurant(newValue);
                  }}
                  value={selectedRestaurant}
                  className="profile-input-form"
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextField {...getFieldProps('restaurant')}
                  error={Boolean(touched.restaurant && errors.restaurant)}
                  helperText={touched.restaurant && errors.restaurant} {...params} label="Restaurant" />}
                />
                <FormControl sx={{ width: '100%' }}>
                  <MultiSelect name='branches' label={'Branches'} selected={selectedBranches} options={branches} setFieldValue={setFieldValue} getFieldProps={getFieldProps} touched={touched} errors={errors} handleChange={handleBranchesChange} />
                </FormControl>
              </Stack>
            </Stack>
            <Stack spacing={2}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Menu name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />

                <FormControl sx={{ width: '100%' }}>
                  <MultiSelect name='cuisines' label={'Cuisines'} selected={selectedCuisines} options={cuisines} setFieldValue={setFieldValue} getFieldProps={getFieldProps} touched={touched} errors={errors} handleChange={handleChange} />
                </FormControl>

              </Stack>
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="Price"
                {...getFieldProps('price')}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
              />
              <TextField
                fullWidth
                label="Ingredients"
                {...getFieldProps('ingredients')}
                error={Boolean(touched.ingredients && errors.ingredients)}
                helperText={touched.ingredients && errors.ingredients}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                sx={{ width: '70%' }}
                autoComplete="Preparation Time"
                label="Preparation Time"
                {...getFieldProps('preparationTime')}
                error={Boolean(touched.preparationTime && errors.preparationTime)}
                helperText={touched.preparationTime && errors.preparationTime}
              />

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ width: '100%' }}>
                <Autocomplete
                  id="combo-box-demo2"
                  options={categories}
                  value={selectedCategory}
                  getOptionLabel={option => option.name}
                  className="profile-input-form"
                  onChange={(event, newValue) => {
                    console.log("newValue",newValue)
                    setFieldValue('category',newValue._id)
                    setSelectedCategory(newValue);
                  }}
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextField {...getFieldProps('category')}
                  error={Boolean(touched.category && errors.category)}
                  helperText={touched.category && errors.category} {...params} label="Category" />}
                />
              </Stack>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} >
            <Autocomplete
                  id="combo-box-demo2"
                  options={foodTypes}
                  value={foodTypesObj}
                  getOptionLabel={(option) =>(option.name?option.name:'')}
                  //isOptionEqualToValue={(option, value) => option._id === foodType}
                  onChange={(event, newValue) => {
                    //console.log('newValue', newValue);
                    if(newValue) {
                      //setFoodType(newValue._id);
                      setFieldValue('foodType',newValue._id)
                    }
                    else
                    {
                      //setFoodType('');
                      setFieldValue('foodType','')
                    }
                  }}
                  sx={{ width: '100%' }}
                  className="profile-input-form"
                  renderInput={(params) => <TextField {...getFieldProps('foodType')}
                  error={Boolean(touched.foodType && errors.foodType)}
                  helperText={touched.foodType && errors.foodType} {...params} label="Food Type" />}
                />
            </Stack>
            <Stack sx={{ flexDirection: 'initial', marginLeft: 'auto!important' }}>
              <LoadingButton
                sx={{ width: '200px' }}
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  height: '50px',
                  width: '131px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 850,
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  padding: '17px',
                  marginRight: '9px'
                }}
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>

              <LoadingButton
                sx={{ width: '200px' }}
                size="large"
                type="button"
                variant="contained"
                sx={{
                  height: '50px',
                  borderRadius: '20px',
                  boxShadow: 'none',
                  fontWeight: 850,
                  fontSize: '16px',
                  fontFamily: 'Avenir',
                  padding: '17px',
                  background: '#E4E4E4',
                  color: '#000',
                  width: '131px'
                }}
                loading={isSubmitting}
                href="/dashboard/menus"
              >
                Cancel
              </LoadingButton>
            </Stack>
          </Stack>
          <Stack sx={{ width: '40%' }}>
            <CustomFields setOptionsFinal={setOptionsFinal} optionList={optionListFinal}/>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
    }
    </>
  );
}
