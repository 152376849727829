import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker,
  DirectionsRenderer
} from "react-google-maps";
import { withProps, compose, lifecycle } from "recompose";
import socketIOClient from "socket.io-client";
const { io } = require("socket.io-client");

export default function TrackDriver(props) {
  const _id = props.driverTrackData._id;

  console.log("props.directions",props.driverTrackData['location'])
  

  const image =
    "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";

  const [isOpen, setIsOpen] = useState(false);

  const [driverLocation, setDriverLocation] = useState(props.driverTrackData['location']);

  const [driverTrackData, setDriverTrackData] = useState(props.driverTrackData);
  const [sourcePoint, setSourcePoint] = useState(new google.maps.LatLng(props.driverTrackData['location'][0], props.driverTrackData['location'][1]));
  const [destinationPoint, setDestinationPoint] = useState(new google.maps.LatLng(26.8770635,75.815497));
const MapWithADirectionsRenderer = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBeaczttyEMOaYJ4M6EVyqpAcuDZazbyjg&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `500px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      const DirectionsService = new google.maps.DirectionsService();
      console.log("sourcePoint",isOpen)

      setIsOpen(true)

      DirectionsService.route(
        {
          origin: sourcePoint,
          destination: destinationPoint,
          travelMode: google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result
            });
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  })
)(prop => (
    <>{(isOpen) && <GoogleMap
      defaultZoom={15}
      defaultCenter={sourcePoint}
    >
    <Marker position={{ lat: driverLocation[0], lng: driverLocation[1] }} icon={'/static/icons/driver.png'}  />
    {/* {props.directions && <DirectionsRenderer directions={props.directions} />} */}
  </GoogleMap>}</>
));
  const setCurrentLocation = (data) => {
    if(data.driver == _id){

      setDriverLocation(data)

      // setLatitude(data.lat);
      // setLongitude(data.lng);
      //setDestinationPoint(new google.maps.LatLng(data.order.deliveryLocation[1],data.order.deliveryLocation[0]));
      //setSourcePoint(new google.maps.LatLng(data.lat,data.lng));
    }
  };
  useEffect(() => {
    
    const socketServer = io("http://3.110.191.95:7097",{
      transports: ["websocket"],
      // extraHeaders: {
      //   "type": "admin",//query
      //   "driverid":_id
      //     },
      auth:{
        type:'company',
        driverid : _id
      }
    });
    console.log("socketServer",socketServer)
    socketServer.on("connect", () => {
      //console.log("socket",socket)
      console.log("connected",socketServer.id);
    });
    //let socket = socketIOClient(process.env.REACT_APP_API_BASE_URL);
    socketServer.on("trackOrder", (data) => setCurrentLocation(data));
    return () => {
      socketServer.removeListener("trackOrder");
    };
  }, []);

  useEffect(() => {

    console.log("isOpen",isOpen)

    //setDriverTrackData(props.driverTrackData)
    console.log("new google.maps.LatLng(props.driverTrackData.location[1],props.driverTrackData.location[0])11",new google.maps.LatLng(driverLocation[0],driverLocation[1]))
    setSourcePoint(new google.maps.LatLng(driverLocation[0],driverLocation[1]));
  },[driverLocation,isOpen]);


// class Map extends React.Component {
//   render() {
    return (
      <React.Fragment>
        <MapWithADirectionsRenderer />
      </React.Fragment>
    );
//   }
// }
    }
// export default Map;
