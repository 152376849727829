import React, { useState, useRef, useCallback } from "react";
import { useEffect, Component } from 'react';
import ReactDOM from "react-dom";
import { LoadScript, GoogleMap, Polygon } from "@react-google-maps/api";
import { Autocomplete } from '@react-google-maps/api';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import "./styles.css";
import Map from "./Map"
// This example presents a way to handle editing a Polygon
// The objective is to get the new path on every editing event :
// - on dragging the whole Polygon
// - on moving one of the existing points (vertex)
// - on adding a new point by dragging an edge point (midway between two vertices)

// We achieve it by defining refs for the google maps API Polygon instances and listeners with `useRef`
// Then we bind those refs to the currents instances with the help of `onLoad`
// Then we get the new path value with the `onEdit` `useCallback` and pass it to `setPath`
// Finally we clean up the refs with `onUnmount`

export default function App(props) {
  // Store Polygon path in state
  const [fence, setFence] = useState({})
  const [autocomplete, setAutocomplete] = useState({})
  const [lat, setLat] = useState(52.52047739093263)
  const [long, setLong] = useState(13.36653284549709)
  const [path, setPath] = useState([
    { lat: 52.52549080781086, lng: 13.398118538856465 },
    { lat: 52.48578559055679, lng: 13.36653284549709 },
    { lat: 52.48871246221608, lng: 13.44618372440334 }
  ]);

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  // Call setPath with new edited path
  const handleClick = () => {
    setFence(path);
  }

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
      props.setPoints(nextPath);
      console.log("The path state is 2", path);
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);


  // const handleSelect = async (value) => {
  //   console.log("value",value)
  // }

  const onLoadInput = (autocomplete) => {
    console.log('autocomplete: ', autocomplete)

    setAutocomplete(autocomplete);
  }
  const handleSelect = async () => {
    if (autocomplete !== null) {
      console.log((autocomplete.getPlace()))
      // const results = await geocodeByAddress(autocomplete.getPlace().formatted_address);
      // const latLng = await getLatLng(results[0]);
      geocodeByAddress(autocomplete.getPlace().formatted_address)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) =>
        // console.log('Successfully got latitude and longitude', { lat, lng })
       { 
          setLat(lat)
          setLong(lng)
          setPath([
            { lat: lat, lng: lng },
            { lat: (lat+0.02880), lng: (lng+0.01230) },
            { lat: (lat+0.03220), lng: (lng+0.07800) }
          ])
          props.setPoints([
            { lat: lat, lng: lng },
            { lat: (lat+0.02880), lng: (lng+0.01230) },
            { lat: (lat+0.03220), lng: (lng+0.07800) }
          ])
        }
      );
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }

  console.log("The path state is", path);

  useEffect(() => {
    setPath(props.points)
    if(props.points[0]){
      setLat(props.points[0].lat)
      setLong(props.points[0].lng)
    }
    console.log("props._",props)
    console.log("branches._",props.points[0])
  }, [props]);
  

  return (
    <div className="App">
    <LoadScript
          id="script-loader"
          googleMapsApiKey="AIzaSyB5EY6eaMSuDV_wREo6bUWKVrczpOmQPw4"
          language="en"
          libraries={["places"]}
        >

        <Autocomplete
        bounds="LatLngBounds"
          onLoad={onLoadInput}
          onPlaceChanged={handleSelect}
        >
          <input
            type="text"
            placeholder="Customized your placeholder"
            style={{
              zIndex: 999,
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              marginLeft: "-120px"
            }}
          />
        </Autocomplete>

        <GoogleMap
          mapContainerClassName="App-map"
          center={{ lat: lat, lng: long }}
          zoom={12}
          version="weekly"
          on
        >
          <Polygon
            // Make the Polygon editable / draggable
            editable
            draggable
            path={path}
            // Event used when manipulating and adding points
            onMouseUp={onEdit}
            // Event used when dragging the whole Polygon
            onDragEnd={onEdit}
            onLoad={onLoad}
            onUnmount={onUnmount}
          />
        </GoogleMap>
      </LoadScript>
      {/* <Button onClick = {() => { handleClick }}>OK</Button> */}
    </div>
  );
}

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
