import * as Yup from 'yup';
import { useState,useEffect } from 'react';
import { Stack, Card, TextField, Fab, IconButton, Box, Button, InputAdornment, Avatar } from '@material-ui/core';
import { FormControl, Select, Chip, OutlinedInput, MenuItem, InputLabel, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import { LoadingButton } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

export default function CustomFields(props) {
  const [optionObj, setOptionObj] = useState({id:"", type:"",values:[]});
  const [valueObj, setValueObj] = useState({ id:"", label: "", price: "" });
  const [optionList, setOptionList] = useState([]);

  // handle input change
  const handlePriceValidation = e => {
    if(isNaN(+e.key)){
        event.preventDefault();
        console.log('e.key',e.key)
        return false;
    }
  } 
  const handleInputChange = e => {
      
    const { name, value } = e.target;
    let updatedList = optionList.map((item)=>{
        if(e.target.dataset.itemid == item.id){
            let updatedVal = item['values'].map((eachValue)=>{
                if(e.target.dataset.valueid == eachValue.id){
                    console.log("eachValue",eachValue)
                    eachValue[name] = value;
                    console.log("eachValue",eachValue)
                }
                return eachValue;
            })  
            item['values'] = updatedVal;
        }
        return item
    })    
    console.log("updatedListBy INPUT",updatedList)
    setOptionList(updatedList)
    props.setOptionsFinal(updatedList)
  };



  // handle click event of the Remove button
  const handleTypeChange = event => {

      let updatedList = optionList.map((item)=>{
          if(event.target.id == item.id)
            item['type'] = event.target.value
          return item
      })    
      setOptionList(updatedList)
      props.setOptionsFinal(updatedList)
  }

  const handleRemoveOption = e => {
    console.log("e.target.dataset",e.target.id)
    let updatedList = optionList.filter((item)=>
        (e.target.id != item.id)
    )    
    setOptionList(updatedList)    
    props.setOptionsFinal(updatedList)    
  };
  const handleRemoveClick = e => {
    // const list = [...optionList];
    // list.splice(index, 1);
    // setOptionList(list);
    console.log("e.target.dataset",e.target.dataset)
    let updatedList = optionList.map((item)=>{
        if(e.target.dataset.itemid == item.id){
            let updatedVal = item['values'].filter((value)=>
                (e.target.dataset.valueid != value.id)
            )    
            item['values'] = updatedVal;
        }
        return item
    })
    setOptionList(updatedList)    
    props.setOptionsFinal(updatedList)    
  };


  // handle click event of the Add button
  const handleAddClick = event => {
    // let oldList = optionList //copy the array
    
    // oldList[typeIndex]['values'].push(valueObj)

    // let newList = oldList

    console.log("optionList",event)

    // setOptionList(newList);
    let updatedList = optionList.map((item)=>{
        if(event.target.id == item.id){
            let id = new Date().getTime()
          item['values'].push({ id: id, label: "", price: "" })
        }
        return item
    })    
    console.log("updatedListafter",updatedList)
    setOptionList(updatedList)
    props.setOptionsFinal(updatedList)
  };

  // handle click event of the Add Option button
  const handleAddOption = () => {

    let id = new Date().getTime()

    console.log("optionObj",optionObj)

    const firstObj = {id:id, type:"",values:[]}
    setOptionList([...optionList, firstObj]);
    props.setOptionsFinal([...optionList, firstObj]);
  };


  
  useEffect(() => {
    setOptionList(props.optionList)
    props.setOptionsFinal(props.optionList);
    console.log('NIkita props',props.optionList)
    console.log('optionList',optionList)
  },[props.optionList]);

  return (
      <Card>
          <Box sx={{padding:"0 0 1.25rem 1.25rem"}}>
            <Stack spacing={2}>
                <Button sx={{margin:'1.25rem 0',width:'fit-content'}} variant="contained" onClick={handleAddOption}>Add Modifications +</Button>
            </Stack>
            <Stack spacing={2}>
                {(optionList.length > 0) && optionList.map((item,j) => {
                    console.log("nikita",item.values);
                    return (
                        <Stack key={j} spacing={2} >
                            <Stack direction='row' spacing={2}>
                                <TextField
                                label="Type"
                                name="type"
                                id={item.id}
                                defaultValue={item.type}
                                onChange= {handleTypeChange}
                                />
                                <Stack direction='row' >
                                    <Button sx={{margin:'0.5rem'}} id={item.id} disabled = { (item.type).trim()=='' ? true : false } variant="contained" onClick={ handleAddClick }>ADD</Button>
                                        
                                    <Box className="btn-box" >
                                        <IconButton
                                        sx={{margin:'0.5rem'}}
                                        variant="outlined"
                                        color="error">
                                            <CloseIcon 
                                            id={item.id}
                                            onClick={handleRemoveOption}/>
                                        </IconButton>
                                    </Box>
                                </Stack>
                            </Stack>
                                {item.values.map((value, key) => {

                                    console.log("HHHHHHAAAAAA",key)

                                    return (
                                    <Stack key={key} direction="row" spacing={2}>
                                        <Stack spacing={2} sx={{margin:'0 15px'}}>
                                            <TextField
                                                name="label"
                                                label="Label"
                                                inputProps={{'data-itemid':item.id,'data-valueid':value.id}}
                                                value={value.label}
                                                onChange={handleInputChange}
                                            />
                                        </Stack>
                                        <Stack spacing={2} sx={{margin:'0 15px'}}>
                                            <TextField                            
                                                name="price" 
                                                sx={{width:'50%'}}
                                                label="Price"
                                                inputProps={{'data-itemid':item.id,'data-valueid':value.id}}
                                                value={value.price}
                                                onChange={handleInputChange}
                                                onKeyPress={handlePriceValidation}
                                            />
                                        </Stack>
                            
                                        <Box className="btn-box" >
                                        {item.values.length !== 1 && 
                                            <IconButton
                                            sx={{margin:'0.5rem'}}
                                            variant="outlined"
                                            color="error">
                                                <CloseIcon 
                                                data-itemid={item.id}
                                                data-valueid={value.id}
                                                onClick={handleRemoveClick}/>
                                            </IconButton>}
                                        {/* {item.values.length - 1 === i && <Button variant="contained" onClick={handleAddClick(j)}>Add More</Button>} */}
                                        </Box>
                                    </Stack>
                                    );
                                })} 
                        </Stack>
                    );
                })}
            </Stack>
          {/* } */}
          </Box>
        </Card>
  );
}

