import { useState,useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import useSession from 'react-session-hook';
// material
import { styled } from '@material-ui/core/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import helper from "../../constants/helper";
import apiPath from '../../constants/apiPath';
import React from 'react';

import PermissionContext from '../../contexts/PermissionContext';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const session = useSession();
  const [open, setOpen] = useState(false);
  const [permissions, setPermissions] = useState([])

  const getToken = async () => {

    console.log("getToken")

    try {
      const res = await helper.get(apiPath.getPermissions,{}, session);
      if (res.status === 200) {
        if (res.data.success) {
        
          if ((res.data.results.isArchive) || (!res.data.results.isActive)) {
            session.removeSession();
            return <Navigate to="/login" />;
          }
  
          setPermissions([...res.data.results.group.permissions])
  
          console.log("res.data.results.group.permissions",res.data.results.group.permissions)
  
          //session.setSession({ token: res.data.results });
          //session.setSession({ permissions: [...res.data.results.group.permissions] });
          //localStorage.setItem('permissions', res.data.results.group.permissions);
        } 
  
      }
  
      if (res.status === 401) {
          session.removeSession();
          return <Navigate to="/login" />;
      }
    } catch (e) {
  
      let resss = JSON.parse(JSON.stringify(e))
  
       if (resss.status === 401) {
  
          session.removeSession();
          return <Navigate to="/login" />;
      }
     // console.log("e.status", e.status,JSON.parse(JSON.stringify(e)));
    }
  }
  
  
  useEffect(() => {
    getToken()
  }, []);

  if (session.isAuthenticated === false) {
    session.removeSession();
    return (<Navigate to="/login" />)
  }

  return (
    <RootStyle>
        <PermissionContext.Provider value={permissions}>
        <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
        
          <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      
        <MainStyle>
          
          <Outlet />
        </MainStyle>
      </PermissionContext.Provider>
    </RootStyle>
  );
}
