import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import useSession from 'react-session-hook';
const querystring = require('querystring');
import { useSnackbar } from 'notistack';
import Badge from '@mui/material/Badge';
import { styled } from '@material-ui/core/styles';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
//
import { fDateTime } from '../utils/formatTime';
import helper from "../constants/helper";
import apiPath from "../constants/apiPath";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'restaurantPublicId', label: 'Restaurant Id', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'contactNo', label: 'Contact No', alignRight: false },
  // { id: 'cuisineType', label: 'Cuisine Type', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'address', label: 'Address', alignRight: false },
  // { id: 'preparationTime', label: 'Preparation Time', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'created_at', label: 'Created', alignRight: false },
  { id: '', label: 'Approval', alignRight: false },
  { id: '', label: 'Actions', alignRight: false },
  
];
// ----------------------------------------------------------------------


export default function Restaurant() {
  const session = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [orderBy, setOrderBy] = useState('_id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(10);

  const [filterBody, setFilterBody] = useState({});
  const [dateFilter, setDateFilters] = useState([null, null]);
  const [status, setStatus] = useState('');

  const [cuisines, setCuisines] = useState([]);
  

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
    setFilterBody({ ...filterBody, status: event.target.value })
    getData(page, { 'orderBy': orderBy, "order": order }, { ...filterBody, status: event.target.value })

  };

  const setDateFilter = (value) => {
    setDateFilters(value)
    setFilterBody({ ...filterBody, startDate: value[0], endDate: value[1] })
    getData(page, { 'orderBy': orderBy, "order": order }, { ...filterBody, startDate: value[0], endDate: value[1] })

  };

  const clearFilter = () => {
    setFilterBody({})
    getData(page, { 'orderBy': orderBy, "order": order }, {})
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = restaurants.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleStatus = (id, isActive) => {
    updateStatus(id, isActive);
  };

  const ApproveRestaurant = (id, approval) => {
    approveStatus(id, approval);
  };

  const handleArchive = (id) => {
    archive(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);

    if (event.target.value.length >= 3) {
      setFilterBody({ ...filterBody, keyword: event.target.value })
      getData(0, { 'orderBy': orderBy, "order": order }, { ...filterBody, keyword: event.target.value })
    }
    else {
      if (event.target.value.length == 0) {
        setFilterBody({ ...filterBody, keyword: event.target.value })
        getData(0, { 'orderBy': orderBy, "order": order }, { ...filterBody, keyword: event.target.value })
      }
    }
  };

  const handleFilterArea = (event) => {
    //setFilterName(event.target.value);
    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      setFilterBody({ ...filterBody, area: event.target.value });
      getData(
        0,
        { orderBy: orderBy, order: order },
        { ...filterBody, area: event.target.value }
      );
    }
  };

  const handleRatingChange = (event) => {

    setFilterBody({ ...filterBody, rating: event.target.value });
    getData(
      0,
      { orderBy: orderBy, order: order },
      { ...filterBody, rating: event.target.value }
    );
  };

  const handleCuisineChange = (event) => {

    setFilterBody({ ...filterBody, cuisines: event.target.value });
    getData(
      0,
      { orderBy: orderBy, order: order },
      { ...filterBody, cuisines: event.target.value }
    );
  };

  //const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;
  const emptyRows = 0;

  const isUserNotFound = restaurants.length === 0;

  const getData = async (page = 0, query = { 'orderBy': '_id', "order": 'desc' }, body = filterBody) => {
    setPage(page)
    console.log("filterBody", filterBody)
    query['page'] = page
    query['limit'] = rowsPerPage
    try {
      const res = await helper.post(body, apiPath.adminRestaurantsPage + '?' + querystring.stringify(query), session);
      if (res.status === 200) {
        setRestaurants(res.data.results.docs)
        setTotalRecord(res.data.results.totalDocs)
      }
    } catch (e) {
      console.log(e);
    }
  }
  const updateStatus = async (id, isActive, body = {}) => {

    body.status = (!isActive)
    try {
      const res = await helper.put(body, apiPath.adminRestaurantStatusUpdate + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error'
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success'
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { 'orderBy': orderBy, "order": order })
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  const approveStatus = async (id, approval, body = {}) => {

    body.approval = approval
    try {
      const res = await helper.put(body, apiPath.adminRestaurantApprove + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error'
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success'
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { 'orderBy': orderBy, "order": order })
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const archive = async (id, body = {}) => {

    body.status = 1
    try {
      const res = await helper.put(body, apiPath.adminRestaurantArchive + '/' + id, session);
      if (res.status === 200) {
        if (!res.data.success) {
          var variant = 'error'
          enqueueSnackbar(res.data.msg, { variant });
        } else {
          var variant = 'success'
          enqueueSnackbar(res.data.msg, { variant });
          getData(page, { 'orderBy': orderBy, "order": order })
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  const getCuisines = async() => {
    try {
      const res = await helper.get(apiPath.cuisinesList,{},session);
      if (res.status === 200) {
        if (!res.data.success) {
          return (<Navigate to="/dashboard" />)
        }
        else {

          setCuisines(res.data.results)      
        }

      }
    } catch (e) {

    }
  }

  useEffect(() => {
    console.log('test')
    getData(page, { 'orderBy': orderBy, "order": order })
    getCuisines()
  }, [orderBy, order, page, rowsPerPage]);

  const CardStyle = styled(Card)(({ theme }) => ({
    borderRadius: '3px',
    boxShadow: 'none',
    border: '1px solid #E1E1E1'
  }));

  let activeStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#0C0CFA',
    color: '#fff'
  };

  let deactiveStyle1 = {
    height: '40px',
    width: '100px',
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 850,
    fontSize: '16px',
    fontFamily: 'Avenir',
    padding: '17px',
    marginRight: '9px',
    backgroundColor: '#EE1C73',
    color: '#fff'
  };

  return (
    <Page title="Restaurants | Piver">
      <Container maxWidth="xl">

        <Stack sx={{ p: 0 }}>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            onFilterArea={handleFilterArea}
            handleRatingChange={handleRatingChange}
            handleCuisineChange={handleCuisineChange}
            cuisines={cuisines}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            handleStatusChange={handleStatusChange}
            status={status}
            clearFilter={clearFilter}
            addButton={'restaurants/add'}
          /></Stack>

        <Card sx={{ borderRadius: '3px', boxShadow: '0', border: '1px solid #E1E1E1' }}>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={restaurants.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {restaurants
                    .map((row) => {
                      const { _id, name, email, address, isActive, contactNo, image, restaurantPublicId,created_at, branch, isApproved, approval  } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;
                      console.log("name", name)
                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox
                                                         checked={isItemSelected}
                                                         onChange={(event) => handleClick(event, name)}
                                                       />*/}
                          </TableCell>
                          <TableCell padding="checkbox">
                          {restaurantPublicId}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar alt={name} src={branch.image} />
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{branch.phone}</TableCell>
                          {/* <TableCell align="left">
                          {cuisines ? cuisines
                            .map((eachCuisine) => {
                              const { id, name, image } = eachCuisine;
                              return (
                                <p>{name}, </p>
                              );
                            })
                          :''}
                          </TableCell> */}
                          <TableCell align="left">{branch.email}</TableCell>
                          <TableCell align="left">{branch.address}</TableCell>
                          {/* <TableCell align="left">{preparationTime}</TableCell> */}

                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={(isActive === false && 'error') || 'success'}
                              sx={(isActive === true && activeStyle1) || deactiveStyle1}
                            >
                              {(isActive === true && 'Enabled') || 'Disabled'}
                            </Label>
                          </TableCell>

                          <TableCell align="left">{fDateTime(created_at)}</TableCell>

                          {/* <TableCell align="left"><Button component={RouterLink}
                            to={`../branches/${_id}`}
                          >View</Button></TableCell> */}



                          <TableCell align="left">
                            {(approval !== 'pending') ? (<Label
                              variant="ghost"
                              sx={(approval === 'approved') ? {height: '40px',width: '100px',borderRadius: '20px',boxShadow: 'none', fontWeight: 850,
                                fontSize: '16px', fontFamily: 'Avenir',padding: '17px', marginRight: '9px', backgroundColor: '#359538', color: '#fff', textTransform : 'capitalize'
                              } : {height: '40px',  width: '100px', borderRadius: '20px', boxShadow: 'none', fontWeight: 850, fontSize: '16px',
                              fontFamily: 'Avenir', padding: '17px', marginRight: '9px', backgroundColor: '#ff0000', color: '#fff', textTransform : 'capitalize'}}
                             >
                              {approval}
                            </Label>)
                            : 
                            (<Stack direction="row" alignItems="center" spacing={2}><Button
                              variant="ghost"
                              color={'success'}
                              sx={activeStyle1}
                              onClick={() => { ApproveRestaurant(_id,'approved') }} >
                              Approve
                            </Button>

                            <Button
                              variant="ghost"
                              color={'error'}
                              sx={deactiveStyle1}
                              onClick={() => {  ApproveRestaurant(_id,'rejected') }} >
                                Reject
                            </Button></Stack>)
                            }

                          </TableCell>

                          <TableCell align="right">
                            <UserMoreMenu key={_id} isActive={isActive} id={_id} handleStatus={handleStatus} handleArchive={handleArchive} pageId={'restaurant'} editPath={`../restaurants/edit/${_id}`} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={12} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalRecord}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
