import { Link as RouterLink, Route, BrowserRouter } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import {
  Box,
  Stack,
  Icon,
  Grid,
  Button,
  CardHeader,
  CardContent,
  Card,
  Link,
  Container,
  Typography
} from '@material-ui/core';
import backFill from '@iconify/icons-eva/arrow-back-fill';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { EditForm } from '../components/profile';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Profile({ match }) {
  console.log('match', match);

  return (
    <RootStyle title="Edit profile | Piver">
      <Container fixed="false" maxWidth="xl">
        <Grid id="top-row" container spacing={24}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              {/* <Typography variant="h4" gutterBottom>
                Edit profile
              </Typography> */}
              {/* <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/menus"
                sx={{ background: '#0C0CFA' }}
                startIcon={<Icon icon={backFill} />}
              >
                <ArrowBackIcon>arrow-back</ArrowBackIcon>Back
              </Button> */}
            </Stack>

            <Card sx={{ borderRadius: '0px', boxShadow: 'none' }}>
              <CardContent>
                <EditForm />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
